function IconStar({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.8649 2.99628C12.4796 2.33213 11.5204 2.33213 11.1351 2.99628L8.42101 7.67359C8.23064 8.00168 7.90159 8.22557 7.52653 8.28222L2.44021 9.05044C1.58593 9.17947 1.28627 10.2581 1.95158 10.8093L5.74067 13.9485C6.09141 14.2391 6.25633 14.6975 6.17113 15.1449L5.17996 20.35C5.02327 21.1729 5.88706 21.8122 6.62821 21.4219L11.4176 18.9001C11.7821 18.7082 12.2178 18.7082 12.5824 18.9001L17.3718 21.4219C18.1129 21.8122 18.9767 21.1729 18.82 20.35L17.8289 15.1449C17.7437 14.6975 17.9086 14.2391 18.2593 13.9485L22.0484 10.8093C22.7137 10.2581 22.4141 9.17947 21.5598 9.05044L16.4735 8.28222C16.0984 8.22557 15.7694 8.00168 15.579 7.67359L12.8649 2.99628Z"
        fill="currentColor"
      />
    </svg>
  );
}

const Rating = ({ value, max = 5 }) => {
  /* Calculate how much of the stars should be "filled" */
  const percentage = Math.round((value / max) * 100);

  return (
    <div
      className="relative inline-flex items-center"
      // onMouseOver={(e) => {
      //   console.log(e);
      // }}
    >
      {/* Create an array based on the max rating, render a star for each */}
      {Array.from(Array(max).keys()).map((_, i) => (
        <IconStar
          key={String(i)}
          className="flex w-8 h-auto mr-1 text-[#e38356] fill-[#e38356]"
        />
      ))}
      {/* Render a div overlayed on top of the stars that are not filled */}
      <div
        // className={styles.overlay}
        className="absolute top-0 right-0 bottom-0 z-[1] bg-gray-100 [@supports(mix-blend-mode:color)]:opacity-[unset] [@supports(mix-blend-mode:color)]:mix-blend-color dark:[@supports(mix-blend-mode:color)]:opacity-[unset] dark:[@supports(mix-blend-mode:color)]:mix-blend-color"
        style={{ width: `${100 - percentage}%` }}
      />
    </div>
  );
};

const RatingStars = ({ rating, max, handleRatingChange, handleMaxChange }) => {
  return (
    <>
      <div
      // className={styles.playground}
      // className="flex flex-col items-center bg-[#282826] rounded-xl"
      >
        <Rating value={rating} max={max} />
        <div
          // className={styles.controls}
          className="flex pt-2 gap-3"
        >
          <label className="flex gap-2">
            Value:
            <input
              type="number"
              // value={value}
              value={rating}
              step={0.1}
              min={0.1}
              max={max}
              //   onChange={(e) =>
              //     setCreateCourseData({
              //       ...createCourseData,
              //       rating: +e.target.value,
              //     })
              //   }
              // onChange={(e) => setValue(+e.target.value)}
              className="bg-gray-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => handleRatingChange(e)}
            />
          </label>
          <label className="flex gap-2">
            Max:
            <input
              type="number"
              value={max}
              onChange={(e) => handleMaxChange(e)}
              //   onChange={(e) => setMax(+e.target.value)}
              min={1}
              max={10}
              className="bg-gray-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default RatingStars;
