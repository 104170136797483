import clsx from "clsx";
import { NavLink } from "react-router-dom";
import {
  BookmarkAltIcon,
  // ClockIcon,
  TemplateIcon,
  AcademicCapIcon,
  CashIcon,
  UsersIcon,
  InformationCircleIcon,
  GiftIcon,
  CurrencyDollarIcon,
  PhotographIcon,
  FlagIcon,
  // IdentificationIcon,
  // ClipboardCheckIcon,
  // ClipboardListIcon,
  BeakerIcon,
  CogIcon,
  ChevronDownIcon,
  BriefcaseIcon,
} from "@heroicons/react/outline";
import { useAuth } from "context/AuthProvider";
import { Disclosure, Transition } from "@headlessui/react";

export default function NavBar({ show, setShow }) {
  const { roles } = useAuth();

  const navList = [
    roles[0] === "ADMIN"
      ? {
          name: "Dashboard",
          icon: TemplateIcon,
          current: true,
          to: "/dashboard",
        }
      : null,
    roles[0] === "SUPPORT" || roles[0] === "ADMIN" || roles[0] === "MANAGER"
      ? { name: "Users", icon: UsersIcon, current: true, to: "/user" }
      : null,
    roles[0] === "ADMIN" ||
    roles[0] === "MANAGER" ||
    roles[0] === "CONTENT_MANAGER"
      ? {
          name: "Platforms",
          icon: AcademicCapIcon,
          current: false,
          children: [
            { name: "Board", to: "/board" },
            { name: "University", to: "/university" },
            { name: "Entrance", to: "/entrance" },
            { name: "Govt Exam", to: "/govt" },
          ],
        }
      : null,
    roles[0] === "ADMIN" ||
    roles[0] === "MANAGER" ||
    roles[0] === "CONTENT_MANAGER"
      ? {
          name: "Banners",
          icon: BookmarkAltIcon,
          current: false,
          children: [
            { name: "Home Banner", to: "/homeBanner" },
            { name: "Time Banner", to: "/timeBanner" },
          ],
        }
      : null,
    roles[0] === "ADMIN" || roles[0] === "MANAGER"
      ? {
          name: "Payment Plans",
          icon: CurrencyDollarIcon,
          current: true,
          to: "/paymentPlans",
        }
      : null,
    // {
    //   name: "Home Banner",
    //   icon: BookmarkAltIcon,
    //   current: true,
    //   to: "/homeBanner",
    // },
    // {
    //   name: "Time Banner",
    //   icon: ClockIcon,
    //   current: true,
    //   to: "/timeBanner",
    // },
    roles[0] === "ADMIN" || roles[0] === "MANAGER" || roles[0] === "SUPPORT"
      ? {
          name: "Transactions",
          icon: CashIcon,
          current: true,
          to: "/transactions",
        }
      : null,
    roles[0] === "ADMIN" || roles[0] === "MANAGER" || roles[0] === "SUPPORT"
      ? {
          name: "Coupons",
          icon: GiftIcon,
          current: true,
          to: "/Coupons",
        }
      : null,
    roles[0] === "ADMIN" ||
    roles[0] === "MANAGER" ||
    roles[0] === "CONTENT_MANAGER"
      ? {
          name: "Assets",
          icon: PhotographIcon,
          current: true,
          to: "/assets",
        }
      : null,
    roles[0] === "ADMIN"
      ? {
          name: "Jobs",
          icon: BriefcaseIcon,
          current: true,
          to: "/jobs",
        }
      : null,
    roles[0] !== "CONTENT_MANAGER"
      ? {
          name: "Query",
          icon: InformationCircleIcon,
          current: true,
          to: "/query",
        }
      : null,
    roles[0] === "ADMIN"
      ? {
          name: "Flags",
          icon: FlagIcon,
          current: true,
          to: "/flag",
        }
      : null,
    roles[0] === "ADMIN" || roles[0] === "MANAGER"
      ? {
          name: "Roles",
          icon: BeakerIcon,
          current: true,
          to: "/roles",
        }
      : null,
    roles[0] === "ADMIN"
      ? {
          name: "Configs",
          icon: CogIcon,
          current: true,
          to: "/configs",
        }
      : null,
    roles[0] === "ADMIN" ||
    roles[0] === "MANAGER" ||
    roles[0] === "CONTENT_MANAGER"
      ? {
          name: "Blogs",
          icon: TemplateIcon,
          current: true,
          to: "/blogs",
        }
      : null,
  ].filter(Boolean);

  return (
    <nav
      id="primary-navigation"
      data-visible={show ? "true" : "false"}
      className="text-base lg:text-sm bg-white dark:bg-slate-900/95 dark:backdrop-blur shadow-sm dark:[@supports(backdrop-filter:blur(0))]:bg-slate-900/75 h-screen fixed top-20 left-0 border-r border-slate-100/40 dark:border-white/10 primary-navigation z-20 overflow-y-auto"
    >
      <ul className="mx-8 space-y-9 mt-6 border-slate-100 dark:border-slate-800 lg:border-slate-200 mb-32">
        {navList.map((item) =>
          !item.children ? (
            <li key={item.name} className="relative whitespace-nowrap">
              <NavLink
                to={item.to}
                onClick={() => setShow(!show)}
                className={({ isActive }) =>
                  clsx(
                    "block w-full px-3.5 py-2 rounded-md before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full transition-all duration-300",
                    {
                      "bg-slate-500/10 dark:bg-slate-700/50": isActive,
                      "hover:bg-white/5": !isActive,
                    }
                  )
                }
              >
                <div className="flex items-center gap-4 md:flex-col lg:flex-row">
                  <span className="text-blue-500">
                    <item.icon className="w-6 h-6" aria-hidden="true" />
                  </span>
                  <span className="text-xl md:text-sm lg:text-xl lg:block">
                    {item.name}
                  </span>
                </div>
              </NavLink>
            </li>
          ) : (
            <Disclosure
              as="li"
              key={item.name}
              className="relative whitespace-nowrap"
              defaultOpen={
                window.matchMedia("(min-width: 768px)").matches ? true : false
              }
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={clsx(
                      "block w-full px-3.5 py-2 rounded-md before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full transition-all duration-300"
                      // {
                      //   "bg-slate-500/10 dark:bg-slate-700/50": open,
                      //   "hover:bg-white/5": !open,
                      // }
                    )}
                  >
                    <div className="flex items-center gap-4 md:flex-col lg:flex-row">
                      <span className="text-blue-500">
                        <item.icon className="w-6 h-6" aria-hidden="true" />
                      </span>
                      <span className="text-xl md:text-sm lg:text-xl lg:block">
                        {item.name}
                      </span>
                      <ChevronDownIcon
                        className={clsx(
                          open ? "-rotate-180" : "text-opacity-70 rotate-0",
                          "ml-auto h-4 w-4 flex-shrink-0 text-white-300 transition duration-300 ease-in-out group-hover:text-opacity-100 group-hover:translate-x-1"
                        )}
                        aria-hidden="true"
                      />
                    </div>
                  </Disclosure.Button>
                  <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Disclosure.Panel className="space-y-1 border-l dark:border-slate-700 ml-6">
                      {item.children.map((subItem) => (
                        <NavLink
                          key={subItem.name}
                          to={subItem.to}
                          onClick={() => setShow(!show)}
                          className={({ isActive }) =>
                            clsx(
                              "block w-full px-3.5 py-2 rounded-md before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full transition-all duration-300 ml-5 mt-5",
                              {
                                "bg-slate-500/10 dark:bg-slate-700/50":
                                  isActive,
                                "hover:bg-white/5": !isActive,
                              }
                            )
                          }
                        >
                          {subItem.name}
                        </NavLink>
                      ))}
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          )
        )}
      </ul>
    </nav>
  );
}
