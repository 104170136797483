import { Menu, Transition } from "@headlessui/react";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import { Fragment } from "react";

export default function FilterDropDown({ children }) {
  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 ring-1 ring-black dark:ring-slate-600 ring-opacity-40 dark:ring-opacity-100">
            <AdjustmentsIcon
              className="mr-2 -ml-1 h-5 w-5"
              aria-hidden="true"
            />
            <span>Filter</span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black dark:ring-slate-600 ring-opacity-5 dark:ring-opacity-100 focus:outline-none z-50 bg-white dark:bg-slate-900 text-slate-900 dark:text-white">
            <div className="p-1">{children}</div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
