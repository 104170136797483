import axios from "axios";
import {
  SelectField,
  ImageField,
  CheckBoxField,
  SubmitButton,
} from "components/Fields";
import usePathNames from "hooks/usePathnames";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const Layout1 = () => {
  const navigate = useNavigate();
  const { uniId, courseId, moduleId, layout } = useParams();
  const pathnames = usePathNames();
  const [semesters, setSemesters] = useState(null);
  const [subjects, setSubjects] = useState(null);

  const [formData, setFormData] = useState({
    semesterId: "",
    subjectId: "",
    pdf: null,
    paid: false,
    viewAfterAd: false,
    active: false,
    showOnWeb: false,
  });

  const { semesterId, subjectId, pdf, paid, viewAfterAd, active, showOnWeb } =
    formData;

  useEffect(() => {
    Promise.all([
      client(`module/getSemester/${moduleId}`),
      client(`subject/allSubject/${courseId}`),
    ]).then(
      (res) => {
        setSemesters(res[0].semesterDoc);
        setSubjects(res[1].subjectDoc);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [courseId, moduleId]);

  function handleSubmit(e) {
    e.preventDefault();

    client(`layout/LAYOUT_1/create`, {
      body: {
        semester: semesterId,
        subject: subjectId,
        module: [moduleId],
        pdf: pdf,
        paid: paid,
        showAd: viewAfterAd,
        active: active,
        showOnWeb: showOnWeb,
      },
    })
      .then((res) => {
        console.log(res);
        navigate(`/${pathnames}/${uniId}/${courseId}/${moduleId}/${layout}`, {
          replace: true,
        });
      })
      .catch((err) => {
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
      });

    console.log(formData);
  }

  function validateImage(e) {
    let file = e.target.files[0];

    if (file.type !== "application/pdf") {
      e.target.value = null;
      return alert("this file is not a pdf");
    } else {
      // check img size is less than 10mb
      if (file.size >= 1048576 * 10) {
        return alert("Max file size is 1MB");
      } else {
        const data = new FormData();
        data.append("pdf", file);
        try {
          axios.post(`upload/pdf`, data, {}).then((res) => {
            setFormData({
              ...formData,
              pdf: res.data.id,
            });
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <h1 className="text-4xl">Create Content</h1>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <div>
            <SelectField
              label="Semsters"
              name="semesterId"
              onChange={handleChange}
            >
              <option value="">------Select Semester------</option>
              {semesters?.map((sem) => (
                <option key={sem._id} value={sem._id}>
                  {sem.name}
                </option>
              ))}
            </SelectField>
            <Link
              to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/semesters`}
              className="text-blue-300"
            >
              Manage Semesters
            </Link>
          </div>
          <div>
            <SelectField
              label="Subjects"
              name="subjectId"
              onChange={handleChange}
            >
              <option value="">------Select Subject------</option>
              {subjects?.map((sub) => (
                <option key={sub._id} value={sub._id}>
                  {sub.name}
                </option>
              ))}
            </SelectField>
            <Link
              to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/subjects`}
              className="text-blue-300"
            >
              Manage Subjects
            </Link>
          </div>

          <ImageField
            label="Upload File (pdf only)"
            id="file-upload"
            name="pdf"
            accept="application/pdf"
            onChange={validateImage}
          />

          <CheckBoxField
            label="paid"
            name="paid"
            checked={paid}
            onChange={handleChange}
          />
          <CheckBoxField
            label="view content after ad"
            name="viewAfterAd"
            checked={viewAfterAd}
            onChange={handleChange}
          />
          <CheckBoxField
            label="Active On Mobile"
            name="active"
            checked={active}
            onChange={handleChange}
          />
          <CheckBoxField
            label="Active On Web"
            name="showOnWeb"
            checked={showOnWeb}
            onChange={handleChange}
          />
        </div>
        <SubmitButton>Submit</SubmitButton>
      </form>
    </div>
  );
};

export default Layout1;
