import PageLayout from "components/PageLayout";
import DataTable from "components/dataTable/DataTable";
import client from "utils/api-client";
import { useState } from "react";
import SearchBar from "components/SearchBar";
import { useQuery } from "@tanstack/react-query";
import useDebounceValue from "hooks/useDebounceValue";

const Transactions = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [query, setQuery] = useState("");
  const debounceValue = useDebounceValue(query);

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["transactions", { page, size, debounceValue }],
    queryFn: () =>
      client(
        `transaction/allTransaction?page=${page}&size=${size}&search=${debounceValue}`
      ),
    keepPreviousData: true,
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPage = (e) => {
    setSize(+e.target.value);
  };

  function searchSubmit(e) {
    e.preventDefault();
  }

  return (
    <>
      <PageLayout
        title={"All Transctions"}
        data={`total transections: ${data && data.total}`}
      >
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}

        <SearchBar
          query={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          onFormSubmit={searchSubmit}
        />

        <DataTable
          columns={columns}
          rows={data?.transactionDoc}
          paging={true}
          pageCount={data?.pages}
          theCurrentPage={page}
          onPageChange={handlePageChange}
          usersPerPage={size}
          handleChangeRowsPerPage={handleRowsPerPage}
          rowsPerpage={data?.size}
          deleteUrl={`transaction/delete`}
          queryKey={["transactions", { page, size, debounceValue }]}
          transactionStatusUrl={`transaction/updateStatus`}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default Transactions;

const columns = [
  {
    label: "Id",
    field: "_id",
  },
  {
    label: "Created At",
    field: "created",
  },
  {
    label: "Transaction Status",
    field: "status",
  },
  {
    label: "Uni",
    field: "uni",
  },
  {
    label: "Course Name",
    field: "course.name",
  },
  {
    label: "User Name",
    field: "user.name",
  },
  {
    label: "User Email",
    field: "user.email",
  },
  {
    label: "Duration",
    field: "duration",
  },
  {
    label: "Amount",
    field: "amount",
  },
  {
    label: "Promo Code",
    field: "promo_code",
  },
  {
    label: "Payment ID",
    field: "payment_id",
  },

  {
    label: "Expire",
    field: "expire",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
