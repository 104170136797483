import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import { useState } from "react";
import client from "utils/api-client";

const Query = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["query", { page, size }],
    queryFn: () => client(`query/allQuery?page=${page}&size=${size}`),
    keepPreviousData: true,
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPage = (e) => {
    setSize(+e.target.value);
  };

  return (
    <>
      <PageLayout title={"Flag"} data={`Total Queries: ${data && data.size}`}>
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}

        <DataTable
          columns={columns}
          rows={data?.queryDocs}
          paging={true}
          pageCount={data?.pages}
          theCurrentPage={page}
          onPageChange={handlePageChange}
          usersPerPage={size}
          handleChangeRowsPerPage={handleRowsPerPage}
          loading={isLoading}
          deleteUrl={`query/delete`}
          queryKey={["query", { page, size }]}
        />
      </PageLayout>
    </>
  );
};

export default Query;

const columns = [
  {
    label: "ID",
    field: "_id",
  },
  {
    label: "User Name",
    field: "user.name",
  },
  {
    label: "User Email",
    field: "user.email",
  },
  {
    label: "User Id",
    field: "user._id",
  },
  { label: "Mobile", field: "user.mobile" },
  {
    label: "Created At",
    field: "created",
  },
  {
    label: "Query",
    field: "query",
  },
  { label: "Attachment", field: "image" },

  { label: "Reply", field: "reply" },
  {
    label: "Delete",
    field: "_id",
  },
];
