import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import Loading from "components/dataTable/Loading";
import NoDataFound from "components/dataTable/NoDataFound";
import PageLayout from "components/PageLayout";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import client from "utils/api-client";
import { useAsync } from "utils/hooks";
import format from "date-fns/format";
import { toast } from "react-toastify";

const TimeBanner = () => {
  const { data, error, run, isLoading, isError } = useAsync();

  useEffect(() => {
    run(client(`timer/getAllTimer`));
  }, [run]);

  function deleteTimeBanner(_id) {
    client(`timer/delete`, {
      body: {
        _id,
      },
    })
      .then(() => {
        run(client(`timer/getAllTimer`));
        toast("successfully deleted");
      })
      .catch((err) => {
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <PageLayout
      title="All Time Banners"
      buttonTitle="Add Banner Time +"
      linkTo="/timeBanner/createTimeBanner"
    >
      {isError ? (
        <div className="text-red-400">
          <p>There was an error:</p>
          <pre>{error.message}</pre>
        </div>
      ) : null}

      <Loading loading={isLoading} />
      <NoDataFound length={data?.timerDoc.length} />

      <div className="flex flex-wrap gap-6">
        {data?.timerDoc.map((timer) => (
          <div
            key={timer._id}
            className="border dark:border-slate-700 bottom-2 p-4 pr-7 relative w-fit dark:bg-slate-800 bg-white rounded-xl shadow-lg"
          >
            <h1 className="font-bold mb-3">{timer.text}</h1>
            <div className="grid grid-cols-2 gap-3">
              <div>
                <h3 className="uppercase font-semibold text-sm text-gray-400">
                  End Date
                </h3>
                <p>
                  {timer.end_date
                    ? format(new Date(timer.end_date), "dd MMM yyyy hh:mm aaa")
                    : "not present"}
                </p>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm text-gray-400">
                  Show on Mobile
                </h3>
                <p>{`${timer.show}`}</p>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm text-gray-400">
                  Show on Web
                </h3>
                <p>{`${timer.showOnWeb}`}</p>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm text-gray-400">
                  Timer Type
                </h3>
                <p>{timer.timer_type}</p>
              </div>
            </div>
            <div className="absolute -top-4 -right-4">
              <button onClick={() => deleteTimeBanner(timer._id)}>
                <div className="text-slate-700 dark:text-slate-200 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow border dark:border-slate-600">
                  <TrashIcon className="w-4 h-4 text-red-400" />
                </div>
              </button>
            </div>
            <Link
              className="absolute top-6 -right-4"
              to={`/timeBanner/${timer._id}`}
            >
              <div className="text-blue-400 dark:text-blue-300 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow border dark:border-slate-600">
                <PencilAltIcon className="w-4 h-4" />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </PageLayout>
  );
};

export default TimeBanner;
