import Layout from "components/layout/Layout";
import { useAuth } from "context/AuthProvider";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PrivateRoute = ({ allowedRoles }) => {
  const { user, roles } = useAuth();
  const location = useLocation();

  return roles?.find((role) => allowedRoles?.includes(role)) ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
