import { useParams } from "react-router-dom";
import Layout1 from "./editContentFormLayouts/Layout1";
import Layout2 from "./editContentFormLayouts/Layout2";
import Layout3 from "./editContentFormLayouts/Layout3";
import NotFound from "pages/404";

const EditContent = () => {
  const { layout } = useParams();

  return (
    <>
      {layout === "LAYOUT_1" ? (
        <Layout1 />
      ) : layout === "LAYOUT_2" ? (
        <Layout2 />
      ) : layout === "LAYOUT_3" ? (
        <Layout3 />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default EditContent;
