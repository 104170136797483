import { useNavigate, useParams } from "react-router-dom";
import { CheckBoxField, SubmitButton, TextField } from "components/Fields";
import { SelectField } from "components/Fields";
import PageLayout from "components/PageLayout";
import { useState } from "react";
import { useAsync } from "utils/hooks";
import client from "utils/api-client";
import FileDialog from "components/FileDialog";
import { toast } from "react-toastify";
import usePathNames from "hooks/usePathnames";

function CreateModule() {
  const { courseId, uniId } = useParams();
  const { setError, isError, isLoading, error } = useAsync();
  let navigate = useNavigate();
  const pathname = usePathNames();

  const [formData, setFormData] = useState({
    name: "",
    type: "LAYOUT_1",
    image: "",
    active: false,
  });

  const { name, type, active, image } = formData;

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    client(`module/create`, {
      body: {
        module: formData,
        _id: courseId,
      },
    }).then(
      (data) => {
        console.log(data);
        navigate(`/${pathname}/${uniId}/${courseId}`);
      },
      (error) => {
        console.log(error);
        setError(error);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  };

  return (
    <>
      <PageLayout title="Create New Module">
        {isError && <p className="text-red-500 mt-6">{error}</p>}
        <form
          onSubmit={handleSubmit}
          // autoComplete="off"
          // encType="multipart/form-data"
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
            <TextField
              label="Name"
              id="Name"
              name="name"
              type="text"
              value={name}
              onChange={handleChange}
              required
            />
            <SelectField
              className="col-span-1"
              label="Layout"
              id="layout"
              name="type"
              value={type}
              onChange={handleChange}
            >
              <option value="LAYOUT_1">Layout 1</option>
              <option value="LAYOUT_2">Layout 2</option>
              <option value="LAYOUT_3">Layout 3</option>
            </SelectField>

            <CheckBoxField
              label="Active On Mobile"
              name="active"
              id="isActive"
              checked={active}
              onChange={handleChange}
            />

            {/* <ImageField
              label="Module Image"
              id="file-upload"
              name="image"
              accept="image/png, image/jpeg"
              onChange={validateImage}
            /> */}

            <div className="flex">
              <FileDialog
                handleChange={(selected) => {
                  setFormData({ ...formData, image: selected });
                }}
              />
              <img src={image} alt="" className="max-w-[100px]" />
              {/* {loading && "loading..."}
            {error && `${error}`} */}
            </div>
          </div>
          <SubmitButton type="submit" disabled={isLoading}>
            Submit
          </SubmitButton>
        </form>
        {/* {isSuccess && <p className="text-green-500 mt-6">success!</p>} */}
      </PageLayout>
    </>
  );
}

export default CreateModule;
