import { SearchIcon } from "@heroicons/react/outline";
import { memo } from "react";

const SearchBar = ({ query, onChange }) => {
  return (
    <>
      <form className="mt-4 sm:max-w-sm ml-auto" autoComplete="off">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <SearchIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="search"
            id="search"
            name="search"
            className="bg-gray-50 border-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full pl-10 p-2 focus-visible:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search User"
            value={query}
            onChange={onChange}
          />
        </div>
      </form>
    </>
  );
};

export default memo(SearchBar);
