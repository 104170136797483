import { XIcon } from "@heroicons/react/solid";
import { TextField, SubmitButton } from "components/Fields";
import PageLayout from "components/PageLayout";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MAX_COUNT = 10;

const CreateAsset = () => {
  let navigate = useNavigate();
  const reducer = (state, action) => ({
    ...state,
    ...action,
  });

  const [fileData, setFileData] = useReducer(reducer, {
    uploadedFiles: [],
    fileLimit: false,
    label: "",
    error: "",
  });

  const { uploadedFiles, fileLimit, label, error } = fileData;

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    // eslint-disable-next-line
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        if (file.size >= 1048576) {
          // throw new Error("Max File size is 1MB");
          console.log("Max File size is 1MB", file.name);
          setFileData({
            error: `Max File size is 1MB File-Name: ${file.name}`,
          });
        } else {
          setFileData({ error: "" });
          uploaded.push(file);
          if (uploaded.length === MAX_COUNT) setFileData({ fileLimit: true });
          if (uploaded.length > MAX_COUNT) {
            // alert(`You can only add a maximum of ${MAX_COUNT} files`);
            toast.warning(`You can only add a maximum of ${MAX_COUNT} files`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setFileData({ fileLimit: false });
            limitExceeded = true;
            return true;
          }
        }
      }
    });
    if (!limitExceeded) setFileData({ uploadedFiles: uploaded });
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  async function UploadAssets(e) {
    e.preventDefault();

    const data = new FormData();
    data.append("label", label);
    for (let i = 0; i < uploadedFiles.length; i++) {
      data.append("image[]", uploadedFiles[i]);
    }

    await fetch(`${process.env.REACT_APP_API_URL}/upload/multipleImages`, {
      method: "POST",
      headers: {
        "x-access-token": JSON.parse(window.localStorage.getItem("token")),
      },
      body: data,
    })
      .then((response) => {
        console.log(response);
        if (response.ok) {
          navigate("/assets");
        }
        return response.json();
      })
      .then((data) => console.log(data))
      .catch((err) => {
        console.log(err);
        setFileData({
          error: err,
        });
      });
  }

  return (
    <PageLayout
      title="Upload New Assets"
      description={`Max Files: ${MAX_COUNT}`}
    >
      {error && (
        <div className="text-red-400 mb-4">
          <p>There was an error:</p>
          <pre>{error}</pre>
        </div>
      )}
      <div className="relative mt-1 flex justify-center items-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 h-72 w-full">
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500"
            >
              <span>Upload a file</span>
              <input
                id="file-upload"
                name="image"
                type="file"
                multiple
                accept="image/png, image/jpeg"
                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                onChange={handleFileEvent}
                disabled={() => {
                  toast.warning(
                    `You can only add a maximum of ${MAX_COUNT} files`,
                    {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                  fileLimit();
                }}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG up to 1MB</p>
        </div>
      </div>
      <form onSubmit={UploadAssets}>
        <div className="my-4">
          {uploadedFiles.length !== 0 && (
            <TextField
              label="Label (Optional)"
              id="label"
              name="label"
              value={label}
              onChange={(e) => setFileData({ label: e.target.value })}
            />
          )}
          <div className="grid grid-cols-1 gap-4 mt-4">
            {uploadedFiles.map((file, idx) => (
              <div
                key={file.name}
                className="flex justify-between dark:bg-slate-800 bg-white shadow-lg p-2 rounded-lg"
              >
                <div className="flex gap-3 items-center">
                  <img
                    src={URL.createObjectURL(file)}
                    alt=""
                    className="w-28 h-auto m-1 rounded-md"
                  />
                  <p className="text-sm w-2/3">{file.name}</p>
                </div>
                <button
                  className="mr-3"
                  onClick={() => {
                    let newUploadedFiles = [...uploadedFiles];
                    newUploadedFiles.splice(idx, 1);
                    setFileData({
                      uploadedFiles: newUploadedFiles,
                      fileLimit: false,
                    });
                  }}
                >
                  <XIcon className="w-5 h-5" />
                </button>
              </div>
            ))}
          </div>
          <SubmitButton
            className="my-4 disabled:opacity-50"
            disabled={uploadedFiles.length === 0}
          >
            Submit
          </SubmitButton>
        </div>
      </form>
    </PageLayout>
  );
};

export default CreateAsset;
