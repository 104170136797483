import { SubmitButton, TextField, CheckBoxField } from "components/Fields";
import { useState } from "react";
import client from "utils/api-client";
import { useNavigate, useParams } from "react-router-dom";
import usePathNames from "hooks/usePathnames";

const CreateSemesters = () => {
  const { courseId, moduleId, uniId } = useParams();
  const navigate = useNavigate();
  const pathname = usePathNames();

  const [semesters, setSemesters] = useState([
    {
      name: "",
      active: false,
      moduleId: moduleId,
      course: courseId,
    },
  ]);

  let handleChange = (i, e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let newFormValues = [...semesters];
    newFormValues[i][name] = value;
    setSemesters(newFormValues);
  };

  let addFormFields = () => {
    setSemesters([
      ...semesters,
      {
        name: "",
        active: false,
        moduleId: moduleId,
        course: courseId,
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...semesters];
    newFormValues.splice(i, 1);
    setSemesters(newFormValues);
  };

  function handleSubmit(e) {
    e.preventDefault();

    client(`module/createSemester`, { body: { semester: semesters } }).then(
      (data) => {
        console.log(data);
        navigate(
          `/${pathname}/${uniId}/${courseId}/${moduleId}/createContent/semesters`,
          {
            replace: true,
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  return (
    <div>
      <h1 className="text-4xl">Create Semester</h1>

      <form className="mt-5" onSubmit={handleSubmit}>
        <div className="space-y-5">
          {semesters.map((input, idx) => (
            <div
              key={idx}
              className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6 border-b pb-4 border-blue-400"
            >
              <TextField
                label="Semester Name"
                name="name"
                id="SemesterName"
                value={input.name}
                onChange={(e) => handleChange(idx, e)}
              />
              <CheckBoxField
                label="Active On Mobile"
                name="active"
                id={idx}
                checked={input.active}
                onChange={(e) => handleChange(idx, e)}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="flex gap-3">
            <div className="button-section">
              <button type="button" onClick={() => addFormFields()}>
                Add
              </button>
            </div>
            <div className="button-section">
              <button type="button" onClick={() => removeFormFields()}>
                Remove
              </button>
            </div>
          </div>
          {/* <SubmitButton disabled={loading}>
              {loading ? <span> Submitting...</span> : <span>Submit</span>}
            </SubmitButton> */}
          <SubmitButton>Submit</SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default CreateSemesters;
