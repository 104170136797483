import PageLayout from "components/PageLayout";
import client from "utils/api-client";
import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import {
  CheckBoxField,
  FormGrid,
  SelectField,
  SubmitButton,
  TextField,
} from "components/Fields";
import {
  PaperAirplaneIcon,
  PencilAltIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import FileDialog from "components/FileDialog";
import Spinner from "components/Spinner";
import { toast } from "react-toastify";
import usePathNames from "hooks/usePathnames";

const EditUniversity = () => {
  let navigate = useNavigate();
  const { uniId } = useParams();
  const [uniData, setUniData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [FormError, setFormError] = useState(null);
  const [timerBannerData, setTimerBannerData] = useState();
  const pathname = usePathNames();

  useEffect(() => {
    Promise.all([
      client(`uni/getUniversitydata/${uniId}`),
      client(`timer/getAllTimer`),
    ]).then(
      (data) => {
        setUniData(data[0]?.data);
        setTimerBannerData(data[1]?.timerDoc);
      },
      (err) => {
        console.log(err);
        setFormError(err);
      }
    );
  }, [uniId]);

  function UpdateUni(e) {
    e.preventDefault();
    setLoading(true);

    delete uniData.__v;
    delete uniData.course_type;

    // if (typeof data.pricing === "object") {
    //   data.pricing = data.pricing._id;
    // }

    // uniData.id = uniData._id;
    // delete uniData._id;

    client(`uni/edit`, {
      body: uniData,
    })
      .then(() => {
        setLoading(false);
        return navigate(`/${pathname}`);
      })
      .catch((err) => {
        setFormError(err);
        console.log(err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  }

  const handleChange = (e) => {
    console.log(e);
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(uniData);
    setUniData({ ...uniData, [name]: value });
  };
  const handleNestedChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setUniData({
      ...uniData,
      active_feilds: {
        ...uniData.active_feilds,
        [name]: value,
      },
    });
  };
  return (
    <>
      <PageLayout title={"Edit University Detail"}>
        {loading ? <Spinner /> : null}

        <div className="text-red-400">
          <pre>{FormError}</pre>
        </div>

        <>
          <form onSubmit={UpdateUni}>
            <FormGrid>
              <TextField
                label="University Name"
                id="name"
                name="name"
                value={uniData?.name}
                onChange={handleChange}
              />
              <TextField
                label="Sub Title"
                id="SubTitle"
                name="subTitle"
                value={uniData?.subTitle}
                onChange={handleChange}
              />
              <div className="flex">
                <FileDialog
                  handleChange={(selected) => {
                    setUniData({ ...uniData, image: selected });
                  }}
                />
                <img src={uniData?.image} alt="" className="max-w-[100px]" />
              </div>

              <SelectField
                label="Time Banner"
                id="time_banner"
                name="time_banner"
                value={uniData?.time_banner}
                onChange={handleChange}
              >
                <option value="">---Select Time Banner---</option>
                {timerBannerData?.map((banner) => (
                  <option key={banner._id} value={banner._id}>
                    {banner.text}
                  </option>
                ))}
              </SelectField>

              <TextField
                label="Unique Name"
                id="name_id"
                name="name_id"
                value={uniData?.name_id}
                onChange={handleChange}
              />
            </FormGrid>

            <div className="flex mb-6 gap-4">
              <CheckBoxField
                id="status"
                label="Active Status"
                name="active"
                checked={uniData?.active}
                onChange={handleChange}
              />
              <CheckBoxField
                id="showBanner"
                label="Show Banner"
                name="show_banner"
                checked={uniData?.show_banner}
                onChange={handleChange}
              />
              <CheckBoxField
                id="ShowTimer"
                label="Show Timer"
                name="show_timer"
                checked={uniData?.show_timer}
                onChange={handleChange}
              />
              <CheckBoxField
                id="showOnWeb"
                label="Active On Web"
                name="showOnWeb"
                checked={uniData?.showOnWeb}
                onChange={handleChange}
              />
            </div>
            <div className="mb-6 bg-slate-50 dark:bg-slate-500 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
              <div className="text-slate-900 dark:text-white mb-5 text-base font-bold tracking-tight">
                Active tabs on web
              </div>
              <div className="flex gap-4">
                <CheckBoxField
                  id="info"
                  label="University info"
                  name="info"
                  checked={uniData?.active_feilds?.info}
                  onChange={handleNestedChange}
                />
                <CheckBoxField
                  id="courses"
                  label="Course Tab"
                  name="courses"
                  checked={uniData?.active_feilds?.courses}
                  onChange={handleNestedChange}
                />
                <CheckBoxField
                  id="admission"
                  label="Admission info"
                  name="admission"
                  checked={uniData?.active_feilds?.admission}
                  onChange={handleNestedChange}
                />
                <CheckBoxField
                  id="college"
                  label="Affilated College"
                  name="college"
                  checked={uniData?.active_feilds?.college}
                  onChange={handleNestedChange}
                />
                <CheckBoxField
                  id="review"
                  label="Show Review Tab"
                  name="review"
                  checked={uniData?.active_feilds?.review}
                  onChange={handleNestedChange}
                />
                <CheckBoxField
                  id="blog"
                  label="Show News & Update"
                  name="blog"
                  checked={uniData?.active_feilds?.blog}
                  onChange={handleNestedChange}
                />
              </div>
            </div>

            <div className="mb-6 bg-slate-50 dark:bg-slate-500 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
              <div className="text-slate-900 dark:text-white mb-5 text-base font-bold tracking-tight">
                Additional Info
              </div>
              <div className="flex gap-4">
                <Link
                  to={`/${pathname}/editUni/${uniId}/info`}
                  className="block text-sm font-semibold hover:text-blue-400 sm:block"
                >
                  University Info<span aria-hidden="true"> &rarr;</span>
                </Link>

                <Link
                  to={`/${pathname}/editUni/${uniId}/admissionInfo`}
                  className="block text-sm font-semibold hover:text-blue-400 sm:block"
                >
                  Admission Info<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
            <SubmitButton type="submit" disabled={loading}>
              {loading ? <>Submitting...</> : <>Submit</>}
            </SubmitButton>
            <Banners
              uniId={uniId}
              bannerData={uniData && uniData}
              updateBannerData={setUniData}
            />

            {FormError && FormError}
          </form>
        </>

        <NewCourseType uniId={uniId} />
      </PageLayout>
    </>
  );
};

export default EditUniversity;

function Banners({ uniId, bannerData, updateBannerData }) {
  // console.log(bannerData);

  function deleteBanner(id) {
    const prevData = { ...bannerData };

    var items = [...prevData.banner];

    items = items.filter((item) => item._id !== id);

    updateBannerData({
      ...bannerData,
      banner: items,
    });
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="text-4xl mt-8">Banners</h1>
        <Link
          to={`/university/editUni/${uniId}/addBanner`}
          className="block text-sm font-semibold hover:text-blue-400 sm:block"
        >
          Manage Banners<span aria-hidden="true"> &rarr;</span>
        </Link>
      </div>

      <div className="my-8 flex flex-wrap gap-6 justify-start relative">
        {bannerData &&
          bannerData.banner.map((banner) => (
            <div
              key={banner._id}
              className="p-4 bg-white shadow-md dark:bg-slate-800 rounded-2xl relative border dark:border-slate-700"
            >
              <h1 className="mb-3 text-xl font-semibold">{banner.title}</h1>
              <img
                src={banner.image_url}
                alt=""
                className="rounded-2xl h-48 w-96 object-cover"
              />
              <button
                className="absolute -top-3 -right-3"
                onClick={() => deleteBanner(banner._id)}
              >
                <div className="text-slate-700 dark:text-slate-200 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow">
                  <TrashIcon className="w-4 h-4 text-red-400" />
                </div>
              </button>
            </div>
          ))}
      </div>
    </>
  );
}

function NewCourseType({ uniId }) {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState([
    {
      coure_type: "",
      active: false,
      index: "",
      university: uniId,
      showOnWeb: false,
    },
  ]);

  useEffect(() => {
    client(`uni/courseType?id=${uniId}`)
      .then((res) => {
        setFormValues(res.couresType);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [uniId]);

  let handleChange = (i, e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let newFormValues = [...formValues];
    newFormValues[i][name] = value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        coure_type: "",
        active: false,
        index: "",
        university: uniId,
        showOnWeb: false,
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  async function handleSendNew() {
    let courseTypeArray = formValues.filter((p) => {
      return Object.keys(p).includes("_id") === false;
    });

    if (courseTypeArray.length === 0) {
      // alert("no new Data");
      toast.error("no new Data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    await client(`uni/createCourseType`, {
      body: {
        CourseType: courseTypeArray,
      },
    })
      .then((res) => {
        console.log(res);
        console.log("success");
        toast.success("created", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  async function handleEditType(rowindex, index) {
    let newValue = formValues[index];

    if (!rowindex) return;
    setLoading(true);

    client(`uni/edit/courseType`, {
      body: {
        _id: rowindex,
        coure_type: newValue.coure_type,
        active: newValue.active,
        index: newValue.index,
        showOnWeb: newValue.showOnWeb,
      },
    })
      .then((res) => {
        console.log(res);
        console.log("success");
        toast.success("editted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  async function DeleteType(id, index) {
    setLoading(true);
    if (id === undefined) {
      removeFormFields(index);
    } else {
      client(`uni/delete/courseType`, {
        body: { _id: id },
      })
        .then(() => {
          console.log("success");
          removeFormFields(index);
          setLoading(false);
        })
        .catch((err) => {
          console.log("somethin went wrong", err);
          setLoading(false);
        });
    }
  }

  return (
    <>
      <h1 className="text-4xl mt-8">Courses Type</h1>
      <div className="flex flex-col gap-6 my-4">
        {formValues?.map((element, index) => (
          <div key={index}>
            <div className="flex gap-5 w-full items-center">
              <div className="space-y-4">
                <div className="flex gap-5">
                  <TextField
                    label="Index"
                    id="index"
                    name="index"
                    type="number"
                    min="0"
                    value={element?.index || ""}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />

                  <TextField
                    label="Type Name"
                    id="Name"
                    name="coure_type"
                    type="text"
                    value={element?.coure_type}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                </div>

                <CheckBoxField
                  id={index}
                  label="Active On Mobile"
                  name="active"
                  checked={element?.active}
                  onChange={(e) => handleChange(index, e)}
                />

                <CheckBoxField
                  id="showOnWeb"
                  label="Active On Web"
                  name="showOnWeb"
                  checked={element?.showOnWeb}
                  onChange={(e) => handleChange(index, e)}
                />
              </div>
              <div className="space-x-4 w-full">
                <button
                  type="button"
                  onClick={() => DeleteType(element._id, index)}
                  className="text-red-600 p-2 bg-red-200 rounded-full active:brightness-110 hover:brightness-105"
                >
                  <TrashIcon className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  onClick={() => handleEditType(element?._id, index)}
                  className="text-blue-600 p-2 bg-blue-200 rounded-full active:brightness-110 hover:brightness-105 w-fit"
                >
                  <PencilAltIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={handleSendNew}
          disabled={loading}
          className="text-green-600 p-2 bg-green-200 rounded-full active:brightness-110 hover:brightness-105 w-fit"
        >
          <div className="flex gap-4 p-1.5">
            {loading ? "Submitting" : "Submit"}
            <PaperAirplaneIcon className="w-5 h-5" />
          </div>
        </button>
      </div>
      <div className="mt-5">
        <button
          className="text-gray-600 px-2 py-1 bg-gray-200 rounded-full active:brightness-110 hover:brightness-105"
          type="button"
          onClick={() => addFormFields()}
        >
          <PlusIcon className="w-5 h-5 rotate-90" />
        </button>
      </div>
    </>
  );
}
