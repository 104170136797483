import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import client from "utils/api-client";
import {
  CheckBoxField,
  ColorField,
  SelectField,
  SubmitButton,
  TextField,
} from "components/Fields";
import { TrashIcon } from "@heroicons/react/outline";
import FileDialog from "components/FileDialog";
import RatingStars from "components/RatingStars";
import { toast } from "react-toastify";
import usePathNames from "hooks/usePathnames";

const EditCourse = () => {
  const { courseId, uniId } = useParams();
  const navigate = useNavigate();
  const pathname = usePathNames();
  const [data, setData] = useState(null);
  const [CourseType, setCourseType] = useState(null);
  const [paymentPlans, setpaymentPlans] = useState(null);
  const [timerBannerData, setTimerBannerData] = useState();

  useEffect(() => {
    Promise.all([
      client(`course/getCourseDetails/${courseId}`),
      client(`uni/courseType?id=${uniId}`),
      client(`course/paymentPlans`),
      client(`timer/getAllTimer`),
    ]).then(
      (res) => {
        setData(res[0]?.coursesDoc);
        setCourseType(res[1].couresType);
        setpaymentPlans(res[2].pricing);
        setTimerBannerData(res[3]?.timerDoc);
      },
      (error) => {
        console.log(error);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }, [courseId, uniId]);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };

  function handleCreateCourse(e) {
    e.preventDefault();

    if (typeof data.pricing === "object") {
      data.pricing = data.pricing?._id;
    }

    Object.assign(data, { _id: courseId });

    client(`course/edit`, {
      body: data,
    })
      .then((res) => {
        console.log(res);
        navigate(`/${pathname}/${uniId}`);
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div>
      <h1 className="text-4xl">Edit Course</h1>
      <form onSubmit={handleCreateCourse}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <TextField
            label="Course Name"
            id="Name"
            name="name"
            value={data?.name}
            onChange={handleChange}
            required
          />

          <div className="flex">
            <FileDialog
              handleChange={(selected) => {
                setData({ ...data, image: selected });
              }}
            />
            <img src={data && data.image} alt="" className="max-w-[100px]" />
          </div>

          <SelectField
            label="Course Type"
            id="Course Type Selected"
            value={data?.course_type}
            onChange={handleChange}
            name="course_type"
            required
          >
            <option value="">Select Course Type</option>
            {CourseType?.map((res) => (
              <option key={res._id} value={res._id}>
                {res.coure_type}
              </option>
            ))}
          </SelectField>

          <SelectField
            label="Payment Plan Type"
            id="PaymentPlanSelected"
            name="pricing"
            onChange={handleChange}
            value={data?.pricing !== null ? data?.pricing?._id : ""}
          >
            <option value="">Select Payment Plan Type</option>
            {paymentPlans?.map((plan) => (
              <option key={plan._id} value={plan._id}>
                {plan.planName}
              </option>
            ))}
          </SelectField>

          <SelectField
            label="Timer Banner"
            id="timerBannerSelected"
            name="time_banner"
            onChange={handleChange}
            value={data?.time_banner}
          >
            <option value="">---Select Time Banner---</option>
            {timerBannerData?.map((banner) => (
              <option key={banner._id} value={banner._id}>
                {banner.text}
              </option>
            ))}
          </SelectField>

          <RatingStars
            max={5}
            rating={data?.rating}
            handleRatingChange={(e) => {
              setData({ ...data, rating: +e.target.value });
            }}
          />

          <TextField
            label="Unique Name"
            id="name_id"
            name="name_id"
            value={data?.name_id}
            onChange={handleChange}
          />

          <CheckBoxField
            label="Active On Mobile"
            id="Active"
            name="active"
            onChange={handleChange}
            checked={data?.active}
          />

          <CheckBoxField
            id="showOnWeb"
            label="Active On Web"
            name="showOnWeb"
            checked={data?.showOnWeb}
            onChange={handleChange}
          />

          <CheckBoxField
            label="Paid"
            id="Name"
            name="paid"
            onChange={handleChange}
            checked={data?.paid}
          />

          <CheckBoxField
            label="Top"
            id="Top"
            name="isTop"
            onChange={handleChange}
            checked={data?.isTop}
          />

          <CheckBoxField
            label="Show Banner"
            id="ShowBanner"
            name="show_banner"
            onChange={handleChange}
            checked={data?.show_banner}
          />

          <CheckBoxField
            label="Show Timer"
            id="showTimer"
            name="show_timer"
            onChange={handleChange}
            checked={data?.show_timer}
          />

          <ColorField
            label="Pick Color"
            id="color"
            name="color"
            value={data?.color}
            onChange={handleChange}
          />
        </div>

        <Banners
          uniId={uniId}
          courseId={courseId}
          bannerData={data}
          updateBannerData={setData}
        />

        <SubmitButton>Submit</SubmitButton>
      </form>
    </div>
  );
};

export default EditCourse;

function Banners({ uniId, courseId, bannerData, updateBannerData }) {
  function deleteBanner(id) {
    const prevData = { ...bannerData };

    var items = [...prevData.banner];

    items = items.filter((item) => item._id !== id);

    updateBannerData({
      ...bannerData,
      banner: items,
    });
  }

  return (
    <>
      <div className="flex justify-between items-center my-8">
        <h1 className="text-4xl mt-8">Banners</h1>
        <Link
          to={`/university/${uniId}/editCourse/${courseId}/addBanner`}
          className="block text-sm font-semibold hover:text-blue-400 sm:block"
        >
          Manage Banners<span aria-hidden="true"> &rarr;</span>
        </Link>
      </div>

      <div className="my-12 flex flex-wrap gap-6 justify-start relative">
        {bannerData &&
          bannerData?.banner.map((banner) => (
            <div
              key={banner._id}
              className="border dark:border-slate-700 bottom-2 p-4 relative w-fit dark:bg-slate-800 bg-white rounded-xl shadow-lg h-fit max-w-lg"
            >
              <h1 className="mb-3 text-xl font-semibold">{banner.title}</h1>
              <img
                src={banner.image_url}
                alt=""
                className="rounded-2xl h-48 w-96 object-cover"
              />
              <button
                className="absolute -top-3 -right-3 text-red-400"
                onClick={() => deleteBanner(banner._id)}
              >
                <div className="text-slate-700 dark:text-slate-200 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow">
                  <TrashIcon className="w-4 h-4" />
                </div>
              </button>
            </div>
          ))}
      </div>
    </>
  );
}
