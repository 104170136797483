import { memo } from "react";
import { NavLink, useLocation } from "react-router-dom";
// import clsx from "clsx";
import { HomeIcon } from "@heroicons/react/solid";

const Breadcrumbs = () => {
  const { pathname, state } = useLocation();
  const pathnames = pathname.split("/").filter(Boolean);
  const states = state?.name?.split("/").filter(Boolean);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol
        className="flex items-center space-x-2 flex-wrap"
        // className="inline-flex items-center space-x-1 md:space-x-3 space-y-1 sm:space-y-0 space flex-wrap"
      >
        {!state?.name
          ? pathnames.map((paths, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;
              const isFirst = paths === pathnames[0];
              return isFirst ? (
                // <li key={index} className="inline-flex items-center">
                //   <NavLink
                //     to={routeTo}
                //     className={({ isActive }) =>
                //       clsx(
                //         "inline-flex items-center text-sm font-medium text-gray-700 ",
                //         {
                //           "dark:text-blue-400": isActive,
                //           "hover:text-gray-900 dark:text-gray-400 dark:hover:text-white":
                //             !isActive,
                //         }
                //       )
                //     }
                //   >
                //     <HomeIcon className="w-4 h-4 mr-2" />
                //   </NavLink>
                // </li>
                <li key={index}>
                  <div>
                    <NavLink
                      key={index}
                      to={routeTo}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <HomeIcon
                        className="h-4 w-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </NavLink>
                  </div>
                </li>
              ) : isLast ? (
                // <li aria-current="page" key={index}>
                //   <div className="flex items-center">
                //     <svg
                //       className="w-6 h-6 text-gray-400"
                //       fill="currentColor"
                //       viewBox="0 0 20 20"
                //       xmlns="http://www.w3.org/2000/svg"
                //     >
                //       <path
                //         fillRule="evenodd"
                //         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                //         clipRule="evenodd"
                //       ></path>
                //     </svg>
                //     <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                //       {paths}
                //     </span>
                //   </div>
                // </li>
                <li key={index}>
                  <div className="flex items-center">
                    <svg
                      className="h-5 w-5 flex-shrink-0 text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <NavLink
                      to={routeTo}
                      className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {paths}
                    </NavLink>
                  </div>
                </li>
              ) : (
                // <li key={index}>
                //   <div className="flex items-center">
                //     <svg
                //       className="w-6 h-6 text-gray-400"
                //       fill="currentColor"
                //       viewBox="0 0 20 20"
                //       xmlns="http://www.w3.org/2000/svg"
                //     >
                //       <path
                //         fillRule="evenodd"
                //         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                //         clipRule="evenodd"
                //       ></path>
                //     </svg>
                //     <NavLink to={routeTo} className="ml-1 text-blue-400">
                //       {paths}
                //     </NavLink>
                //   </div>
                // </li>
                <li key={index}>
                  <div className="flex items-center">
                    <svg
                      className="h-5 w-5 flex-shrink-0 text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <NavLink
                      to={routeTo}
                      className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {paths}
                    </NavLink>
                  </div>
                </li>
              );
            })
          : states?.map((stat, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;
              const isFirst = stat === pathnames[0];
              return isFirst ? (
                // <li key={index} className="inline-flex items-center">
                //   <NavLink
                //     to={routeTo}
                //     className={({ isActive }) =>
                //       clsx(
                //         "inline-flex items-center text-sm font-medium text-gray-700 ",
                //         {
                //           "dark:text-blue-400": isActive,
                //           "hover:text-gray-900 dark:text-gray-400 dark:hover:text-white":
                //             !isActive,
                //         }
                //       )
                //     }
                //   >
                //     <HomeIcon className="w-4 h-4" />
                //   </NavLink>
                // </li>
                <li key={index}>
                  <div>
                    <NavLink
                      key={index}
                      to={routeTo}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <HomeIcon
                        className="h-4 w-4 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </NavLink>
                  </div>
                </li>
              ) : isLast ? (
                // <li aria-current="page" key={index}>
                //   <div className="flex items-center">
                //     <svg
                //       className="w-6 h-6 text-gray-400"
                //       fill="currentColor"
                //       viewBox="0 0 20 20"
                //       xmlns="http://www.w3.org/2000/svg"
                //     >
                //       <path
                //         fillRule="evenodd"
                //         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                //         clipRule="evenodd"
                //       ></path>
                //     </svg>
                //     <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                //       {stat}
                //     </span>
                //   </div>
                // </li>
                <li key={index}>
                  <div className="flex items-center">
                    <svg
                      className="h-5 w-5 flex-shrink-0 text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <NavLink
                      to={routeTo}
                      className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {stat}
                    </NavLink>
                  </div>
                </li>
              ) : (
                // <li key={index}>
                //   <div className="flex items-center">
                //     <svg
                //       className="w-6 h-6 text-gray-400"
                //       fill="currentColor"
                //       viewBox="0 0 20 20"
                //       xmlns="http://www.w3.org/2000/svg"
                //     >
                //       <path
                //         fillRule="evenodd"
                //         d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                //         clipRule="evenodd"
                //       ></path>
                //     </svg>
                //     <button
                //       // to={routeTo}
                //       // state={{ name: state }}
                //       className="ml-1 text-blue-400"
                //     >
                //       {stat}
                //     </button>
                //   </div>
                // </li>
                <li key={index}>
                  <div className="flex items-center">
                    <svg
                      className="h-5 w-5 flex-shrink-0 text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    {/* <NavLink
                      to={routeTo}
                      className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {paths}
                    </NavLink> */}
                    <button className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700">
                      {stat}
                    </button>
                  </div>
                </li>
              );
            })}
      </ol>
    </nav>
  );
};

export default memo(Breadcrumbs);
