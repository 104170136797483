import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  FormGrid,
  TextField,
  CheckBoxField,
  SubmitButton,
} from "components/Fields";
import PageLayout from "components/PageLayout";
import { useState } from "react";
import client from "utils/api-client";
import Spinner from "components/Spinner";
import { toast } from "react-toastify";

const Configs = () => {
  const [configData, setConfigData] = useState({
    android_version: "",
    ios_verson: "",
    ios_mock_version: "",
    force_update_android: false,
    force_update_ios: false,
    show_ios_mock_data: false,
  });

  const {
    android_version,
    force_update_android,
    force_update_ios,
    ios_mock_version,
    ios_verson,
    show_ios_mock_data,
  } = configData;

  const queryClient = useQueryClient();
  const { isError, error, isLoading } = useQuery({
    queryKey: ["config"],
    queryFn: () => client(`config/get`),
    onSettled: (data) => {
      setConfigData(data?.configDoc[0]);
    },
  });

  async function submitConfig(e) {
    e.preventDefault();

    let url = "";

    if (configData._id) {
      url = `config/edit`;
    } else if (!configData._id) {
      url = `config/create`;
    }

    await client(url, { body: configData })
      .then(() => {
        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: "config" });
        }, 100);
        toast.success("Config updated successfully");
      })
      .catch((error) => {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setConfigData({ ...configData, [name]: value });
  };

  return (
    <PageLayout title="Configs">
      {isError ? (
        <p>
          <pre>{error}</pre>
        </p>
      ) : null}
      {isLoading ? (
        <Spinner />
      ) : (
        <form onSubmit={submitConfig}>
          <FormGrid>
            <TextField
              label="Android Version"
              id="androidVersion"
              name="android_version"
              value={android_version}
              onChange={handleChange}
            />
            <TextField
              label="Ios Version"
              id="iosVersion"
              name="ios_verson"
              value={ios_verson}
              onChange={handleChange}
            />
            <TextField
              label="Ios Mock Version"
              id="iosMockVersion"
              name="ios_mock_version"
              value={ios_mock_version}
              onChange={handleChange}
            />
            <CheckBoxField
              label="Force Update Android"
              id="forceUpdateAndroid"
              name="force_update_android"
              onChange={handleChange}
              checked={force_update_android}
            />
            <CheckBoxField
              label="Force Update Ios"
              id="forceUpdateIos"
              name="force_update_ios"
              onChange={handleChange}
              checked={force_update_ios}
            />
            <CheckBoxField
              label="Show Ios Mock Data"
              id="showIosMockData"
              name="show_ios_mock_data"
              checked={show_ios_mock_data}
              onChange={handleChange}
            />
          </FormGrid>
          <SubmitButton className="mt-3">
            {isLoading ? "Loading..." : "Submit"}
          </SubmitButton>
        </form>
      )}
    </PageLayout>
  );
};

export default Configs;
