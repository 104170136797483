import PageLayout from "components/PageLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "components/Spinner";
import { FormGrid, SubmitButton, TextField } from "components/Fields";
import client from "utils/api-client";
import FileDialog from "components/FileDialog";
import { toast } from "react-toastify";
import usePathNames from "hooks/usePathnames";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

const CreateUniversity = () => {
  let navigate = useNavigate();
  const pathnames = usePathNames();

  const [formData, setFormData] = useState({
    name: "",
    subTitle: "",
    image: "",
    name_id: "",
    label:
      pathnames === "university"
        ? "UNI"
        : pathnames === "board"
        ? "BOARD"
        : pathnames === "govt"
        ? "GOVT"
        : pathnames === "entrance"
        ? "ENTRANCE"
        : "",
  });
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const { name, subTitle } = formData;

  function submitForm(e) {
    e.preventDefault();

    setCreateLoading(true);

    if (formData.image === "")
      return toast.error(`select image`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    client(`uni/create`, { body: formData }).then(
      (data) => {
        setCreateLoading(false);
        navigate(`/${pathnames}`);
        console.log(data);
      },
      (err) => {
        setCreateError(err);
        setCreateLoading(false);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });
  };

  const [uniquestatus, setUniqueStatus] = useState("idle");

  async function checkUnique(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setUniqueStatus("loading");

    if (value.length > 0) {
      await client(`uni/check/id/${value}`)
        .then((res) => {
          console.log(res);
          if (res.message === "unique name") {
            setUniqueStatus("unique");
            setFormData({ ...formData, [name]: value });
          } else if (res.message === "id name already in use") {
            setUniqueStatus("error");
            setFormData({ ...formData, [name]: "" });
          }
        })
        .catch(() => {
          setUniqueStatus("error");
          setFormData({ ...formData, [name]: "" });
        });
    } else {
      setUniqueStatus("idle");
      setFormData({ ...formData, [name]: "" });
    }
  }

  console.log(formData.name_id);

  return (
    <>
      <PageLayout title="Create New University">
        {createError && createError}
        {/* {error && `${error}`} */}
        <form onSubmit={submitForm}>
          <FormGrid>
            <TextField
              label="University Name"
              id="universityName"
              name="name"
              value={name}
              onChange={handleChange}
            />
            <TextField
              label="Sub Title"
              id="subTitle"
              name="subTitle"
              value={subTitle}
              onChange={handleChange}
            />

            <div>
              <label
                htmlFor="name_id"
                className="mb-2 text-gray-900 dark:text-gray-300 flex items-center gap-3"
              >
                Unique Name
                {uniquestatus === "loading" ? (
                  <>
                    <Spinner className="scale-75 -my-4" />
                  </>
                ) : uniquestatus === "unique" ? (
                  <CheckIcon className="w-5 h-5 text-green-600" />
                ) : uniquestatus === "error" ? (
                  <XIcon className="w-5 h-5 text-red-600" />
                ) : null}
              </label>
              <input
                id="name_id"
                type="text"
                name="name_id"
                className="bg-gray-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={checkUnique}
              />
            </div>

            <div className="flex justify-between">
              <FileDialog
                handleChange={(selected) => {
                  setFormData({ ...formData, image: selected });
                }}
              />
              <img src={formData?.image} alt="" className="max-w-[100px]" />
            </div>
          </FormGrid>
          <SubmitButton
            disabled={
              createLoading ||
              uniquestatus === "loading" ||
              uniquestatus === "error"
            }
          >
            {createLoading ? <Spinner /> : "Submit"}
          </SubmitButton>
        </form>
      </PageLayout>
    </>
  );
};

export default CreateUniversity;
