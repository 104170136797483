import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import client from "utils/api-client";
import { useQuery } from "@tanstack/react-query";

const EntranceExam = () => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["entranceUniData"],
    queryFn: () => client(`uni/getAllUniversity?label=ENTRANCE`),
  });

  return (
    <>
      <PageLayout
        title="Entrance Exam"
        data={`Entrance Exams Count: ${data?.data.length}`}
        buttonTitle="Create +"
        linkTo="/entrance/createUniversity"
        breadcrumbs={true}
      >
        {isError && (
          <div className="text-red-400">
            <span>There was an error: {error.message}</span>
            <pre></pre>
          </div>
        )}

        <DataTable
          rows={data?.data}
          columns={columns}
          deleteUrl={`uni/delete`}
          queryKey={["entranceUniData"]}
          editTo={`editUni`}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default EntranceExam;

const columns = [
  {
    label: "",
    field: "image",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "SubTitle",
    field: "subTitle",
  },
  {
    label: "Courses",
    field: "name",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
