import PageLayout from "components/PageLayout";
import {
  ColorField,
  SelectField,
  SubmitButton,
  TextField,
} from "components/Fields";
import { useEffect, useState } from "react";
import client from "utils/api-client";
import { useNavigate, useParams } from "react-router-dom";
import FileDialog from "components/FileDialog";
import RatingStars from "components/RatingStars";
import { toast } from "react-toastify";
import usePathNames from "hooks/usePathnames";
import Spinner from "components/Spinner";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

const CreateCourse = () => {
  const navigate = useNavigate();
  const pathname = usePathNames();
  const { uniId } = useParams();
  const [CourseType, setCourseType] = useState(null);
  const [createCourseData, setCreateCourseData] = useState({
    university: uniId,
    name: "",
    image: "",
    color: "#0ea5e9",
    course_type: "",
    rating: "4.1",
    name_id: "",
  });

  const { name, color, course_type, image, rating } = createCourseData;

  useEffect(() => {
    client(`uni/courseType?id=${uniId}`).then(
      (res) => {
        setCourseType(res.couresType);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [uniId]);

  async function handleCreateCourse(e) {
    e.preventDefault();

    client(`course/create`, { body: createCourseData }).then(
      (data) => {
        console.log(data);
        navigate(`/${pathname}/${uniId}`);
      },
      (error) => {
        console.log(error);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setCreateCourseData({
      ...createCourseData,
      [name]: value,
    });
  };

  const [uniquestatus, setUniqueStatus] = useState("idle");

  async function checkUnique(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setUniqueStatus("loading");

    if (value.length > 0) {
      await client(`course/check/id/${value}`)
        .then((res) => {
          console.log(res);
          if (res.message === "unique name") {
            setUniqueStatus("unique");
            setCreateCourseData({ ...createCourseData, [name]: value });
          } else if (res.message === "id name already in use") {
            setUniqueStatus("error");
            setCreateCourseData({ ...createCourseData, [name]: "" });
          }
        })
        .catch(() => {
          setUniqueStatus("error");
          setCreateCourseData({ ...createCourseData, [name]: "" });
        });
    } else {
      setUniqueStatus("idle");
      setCreateCourseData({ ...createCourseData, [name]: "" });
    }
  }

  return (
    <PageLayout title="Create Course">
      <form onSubmit={handleCreateCourse}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <TextField
            label="Course Name"
            id="Name"
            name="name"
            value={name}
            onChange={handleChange}
            required
          />

          <div className="flex">
            <FileDialog
              handleChange={(selected) => {
                setCreateCourseData({
                  ...createCourseData,
                  image: selected,
                });
              }}
            />
            <img src={image} alt="" className="max-w-[100px]" />
          </div>

          <SelectField
            label="Course Type"
            id="Course Type Selected"
            name="course_type"
            onChange={handleChange}
            required
          >
            <option value={course_type}>---Select Course Type---</option>
            {CourseType?.map((res) => (
              <option key={res._id} value={res._id}>
                {res.coure_type}
              </option>
            ))}
          </SelectField>

          <div>
            <label
              htmlFor="name_id"
              className="mb-2 text-gray-900 dark:text-gray-300 flex items-center gap-3"
            >
              Unique Name
              {uniquestatus === "loading" ? (
                <>
                  <Spinner className="scale-75 -my-4" />
                </>
              ) : uniquestatus === "unique" ? (
                <CheckIcon className="w-5 h-5 text-green-600" />
              ) : uniquestatus === "error" ? (
                <XIcon className="w-5 h-5 text-red-600" />
              ) : null}
            </label>
            <input
              id="name_id"
              type="text"
              name="name_id"
              className="bg-gray-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={checkUnique}
            />
          </div>

          <RatingStars
            max={5}
            rating={rating}
            handleRatingChange={(e) => {
              setCreateCourseData({
                ...createCourseData,
                rating: +e.target.value,
              });
            }}
          />

          <ColorField
            label="Pick Color"
            value={color}
            id="Pick Color"
            name="color"
            onChange={handleChange}
          />
        </div>
        <SubmitButton
          disabled={uniquestatus === "loading" || uniquestatus === "error"}
        >
          Submit
        </SubmitButton>
      </form>
    </PageLayout>
  );
};

export default CreateCourse;
