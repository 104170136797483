import client from "utils/api-client";
import { useEffect, useState } from "react";
import PageLayout from "components/PageLayout";
import {
  CheckBoxField,
  SelectField,
  SubmitButton,
  TextField,
} from "components/Fields";
import { useNavigate, useParams } from "react-router-dom";
import FileDialog from "components/FileDialog";
import { toast } from "react-toastify";

const EditHomeBanner = () => {
  const { bannerId } = useParams();
  const [allUni, setAllUni] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allCourses, setAllCourses] = useState(null);
  let navigate = useNavigate();

  const [bannerData, setBannerData] = useState({
    image_url: "",
    url: "",
    title: "",
    label: "UNI_HOME",
    uni_id: "",
    course_id: "",
    urlTitle: "",
    banner_type: "No Click",
    active: false,
    showOnWeb: false,
  });

  const {
    url,
    title,
    uni_id,
    course_id,
    urlTitle,
    banner_type,
    label,
    image_url,
    active,
    showOnWeb,
  } = bannerData;

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setBannerData({ ...bannerData, [name]: value });
  };

  async function submitBannerForm(e) {
    e.preventDefault();

    setLoading(true);

    let data = {};

    if (bannerData?.banner_type === "Inside Course") {
      data = {
        image_url,
        banner_type,
        title,
        label,
        uni_id,
        course_id,
        active,
        showOnWeb,
      };
    } else if (bannerData?.banner_type === "Inside University") {
      data = {
        image_url,
        banner_type,
        title,
        label,
        uni_id,
        active,
        showOnWeb,
      };
    } else if (bannerData?.banner_type === "URL") {
      data = {
        image_url,
        banner_type,
        title,
        label,
        urlTitle,
        url,
        active,
        showOnWeb,
      };
    } else {
      data = {
        image_url,
        banner_type,
        title,
        label,
        active,
        showOnWeb,
      };
    }

    client(`banner/edit/${bannerId}`, {
      body: data,
    })
      .then(() => {
        setLoading(false);
        navigate("/homeBanner");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  useEffect(() => {
    Promise.all([
      client(`banner/banner/${bannerId}`),
      client(`uni/getAllUniversity?label=ALL`),
      bannerData?.uni_id && client(`course/allCourses/${bannerData?.uni_id}`),
    ]).then(
      (res) => {
        setBannerData(res[0].bannerDoc);
        setAllUni(res[1].university);
        bannerData?.uni_id && setAllCourses(res[2]);
        console.log(res);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [bannerData?.uni_id, bannerId]);

  return (
    <>
      <PageLayout title="Edit Banner">
        <form
          onSubmit={submitBannerForm}
          autoComplete="off"
          encType="multipart/form-data"
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
            <TextField
              id="title"
              label="Title"
              name="title"
              value={bannerData?.title}
              onChange={handleChange}
            />

            <div className="flex">
              <FileDialog
                handleChange={(selected) => {
                  setBannerData({
                    ...bannerData,
                    image_url: selected,
                  });
                }}
              />
              <img
                src={bannerData?.image_url}
                alt=""
                className="max-w-[100px]"
              />
            </div>

            <SelectField
              label="Label"
              id="label"
              name="label"
              value={bannerData?.label}
              onChange={handleChange}
            >
              <option value="">-----Select Label-----</option>
              <option value="UNI_HOME">University Home</option>
              <option value="CBSE_HOME">CBSE Home</option>
              <option value="GOVT_HOME">Govt. Home</option>
              <option value="ENTRANCE_HOME">Entrance Home</option>
            </SelectField>

            <SelectField
              label="Banner Type"
              id="bannerType"
              name="banner_type"
              value={bannerData?.banner_type}
              onChange={handleChange}
            >
              <option value="No Click">No Click</option>
              <option value="URL">URL</option>
              <option value="Inside University">Inside University</option>
              <option value="Inside Course">Inside Course</option>
            </SelectField>

            {bannerData?.banner_type === "URL" && (
              <TextField
                id="urlTitle"
                label="Url Title"
                name="urlTitle"
                value={bannerData?.urlTitle}
                onChange={handleChange}
              />
            )}
            {bannerData?.banner_type === "URL" && (
              <TextField
                label="Url"
                name="url"
                id="url"
                value={bannerData?.url}
                onChange={handleChange}
              />
            )}

            {/* {(banner_type === "Inside University" ||
              banner_type === "Inside Course") && (
              <SelectField
                label="University Label"
                id="uniLabel"
                name="uniLabel"
                onChange={handleChange}
              >
                <option value="UNI">University</option>
                <option value="BOARD">Boards</option>
                <option value="GOVT">Government Exam</option>
                <option value="ENTRANCE">Entrance</option>
              </SelectField>
            )} */}

            {(bannerData?.banner_type === "Inside University" ||
              bannerData?.banner_type === "Inside Course") && (
              <SelectField
                label="Select University"
                id="uni"
                name="uni_id"
                value={bannerData?.uni_id}
                onChange={handleChange}
              >
                <option value="">-----Select University-----</option>
                {allUni?.map((uni) => (
                  <option key={uni._id} value={uni._id}>
                    {uni.name}
                  </option>
                ))}
              </SelectField>
            )}

            {bannerData?.banner_type === "Inside Course" && (
              <>
                <SelectField
                  label="Select Course"
                  id="course"
                  name="course_id"
                  value={bannerData?.course_id}
                  onChange={handleChange}
                >
                  <option value="">-----Select Course-----</option>
                  {allCourses?.map((course) => (
                    <option key={course._id} value={course._id}>
                      {course.course_name}
                    </option>
                  ))}
                </SelectField>
              </>
            )}

            <CheckBoxField
              id="active"
              label="Active On Mobile"
              name="active"
              checked={bannerData?.active}
              onChange={handleChange}
            />

            <CheckBoxField
              id="showOnWeb"
              label="Active On Web"
              name="showOnWeb"
              checked={bannerData?.showOnWeb}
              onChange={handleChange}
            />
          </div>
          <SubmitButton
            type="submit"
            disabled={loading}
            className="disabled:opacity-25"
          >
            Submit
          </SubmitButton>
        </form>
      </PageLayout>
    </>
  );
};

export default EditHomeBanner;
