import { createContext, useReducer, useContext, useEffect } from "react";

const AuthContext = createContext();

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`must be used withing the AuthContext`);
  }
  return context;
}

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload, roles: action.roles };
    case "LOGOUT":
      return { user: null, role: null };
    default:
      return state;
  }
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, {
    user: JSON.parse(localStorage.getItem("token")) || null,
    roles: JSON.parse(localStorage.getItem("roles")) || null,
  });

  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem("roles"));
    const token = JSON.parse(localStorage.getItem("token"));

    if (token) {
      dispatch({ type: "LOGIN", payload: token, roles: roles });
    }
  }, []);

  const value = {
    ...state,
    dispatch,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
