import Spinner from "components/Spinner";

function Loading({ loading }) {
  return (
    <>
      {loading ? (
        <div>
          <div className="absolute inset-0 bg-black opacity-5 transition duration-150"></div>
          <Spinner className={"absolute top-1/2 left-1/2 z-10"} />
        </div>
      ) : null}
    </>
  );
}

export default Loading;
