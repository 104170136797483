import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import usePagination from "@mui/material/usePagination/usePagination";
import clsx from "clsx";

export default function NewPaging({ count, currentPage, HandleChange }) {
  const { items } = usePagination({
    count: count,
    page: currentPage,
    onChange: HandleChange,
  });

  return (
    <nav>
      <ul className="flex space-x-2 justify-center items-center">
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "...";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                className={clsx(
                  "border rounded-xl min-w-[32px] max-w-fit p-1 h-8",
                  {
                    "text-[#3b82f6] border-[#3b82f6]": selected,
                    "text-black dark:text-white": !selected,
                  }
                )}
                {...item}
              >
                {page}
              </button>
            );
          } else if (type === "next") {
            children = (
              <button
                type="button"
                {...item}
                className="w-8 h-8 rounded-xl text-center flex justify-center items-center group hover:border-blue-400 transition duration-200 border border-slate-200 dark:border-slate-700 disabled:opacity-40"
              >
                <ChevronRightIcon className="w-5 h-5 group-hover:text-blue-400" />
              </button>
            );
          } else if (type === "previous") {
            children = (
              <button
                type="button"
                {...item}
                className="w-8 h-8 rounded-xl flex justify-center items-center group hover:border-blue-400 transition duration-200 border border-slate-200 dark:border-slate-700 disabled:opacity-40"
              >
                <ChevronLeftIcon className="w-5 h-5 group-hover:text-blue-400" />
              </button>
            );
          }
          return (
            <li
              key={index}
              className="flex justify-center items-center gap-1 text-sm"
            >
              {children}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
