import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import client from "utils/api-client";

const Jobs = () => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["jobs"],
    queryFn: () => client(`job/all`),
  });

  return (
    <PageLayout title="All Jobs" buttonTitle="Add Job +" linkTo="/jobs/create">
      {isError && (
        <div className="text-red-400">
          <p>There was an error:</p>
          <pre>{error.message}</pre>
        </div>
      )}

      <DataTable
        rows={data?.data}
        columns={columns}
        deleteUrl={`job/delete`}
        queryKey={["jobs"]}
        editTo={`edit`}
        loading={isLoading}
      />
    </PageLayout>
  );
};

export default Jobs;

const columns = [
  {
    label: "Title",
    field: "job_title",
  },
  {
    label: "Location",
    field: "job_location",
  },
  {
    label: "Opening",
    field: "position",
  },
  {
    label: "Job Type",
    field: "job_type",
  },
  {
    label: "Active",
    field: "active",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
