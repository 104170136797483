import {
  AcademicCapIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
// import { toast } from "react-toastify";

// import "react-toastify/dist/ReactToastify.css";

const cards = [
  {
    name: "Earnings",
    href: "#",
    icon: CurrencyDollarIcon,
    amount: "$30,659.4",
  },
  { name: "Users", href: "#", icon: UserGroupIcon, amount: "3,30,659" },
  {
    name: "Universities",
    href: "#",
    icon: AcademicCapIcon,
    amount: "32,124",
  },
  // More items...
];

const Dashboard = () => {
  // const notify = () => toast("Wow so easy !");

  return (
    <>
      {/* <h1 className="text-4xl font-bold">Welcome to Dashboard</h1>
      <p className="text-xl my-3 opacity-60">
        Welcome to the admin panel. This is where you will be able to manage all
        aspects of the website, from content to design. Make sure to take some
        time to familiarize yourself with all the features and options available
        to you. If you have any questions, feel free to contact me. Thank you
        for choosing me as your web developer!
      </p>
      <button
        onClick={notify}
        className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 mt-2"
      >
        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
          Press me to see the Toast!
        </span>
      </button> */}
      <h2 className="text-2xl leading-6 font-semibold mb-6">Overview</h2>
      <div className="max-w-6xl px-4">
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {cards.map((card) => (
            <div
              key={card.name}
              className="overflow-hidden shadow-md rounded-3xl border border-slate-200 dark:border-slate-800"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <card.icon
                      className="h-10 w-10 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-lg font-medium text-slate-700 dark:text-slate-400 truncate">
                        {card.name}
                      </dt>
                      <dd>
                        <div className="text-2xl">{card.amount}</div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Dashboard;

// function Example() {
//   const notify = () => toast("Wow so easy !");
//   return (
//     <div>
//       <p className="text-3xl mb-6 text-gray-700 dark:text-gray-300">
//         Welcome to the admin panel. This is where you will be able to manage all
//         aspects of the website, from content to design. Make sure to take some
//         time to familiarize yourself with all the features and options available
//         to you. If you have any questions, feel free to contact me. Thank you
//         for choosing me as your web developer!
//       </p>

//       <button
//         onClick={notify}
//         className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800"
//       >
//         <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
//           Press me to see the Toast!
//         </span>
//       </button>

//       <div className="my-12 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
//         <div className="group relative border border-slate-200 dark:border-slate-800 p-6 shadow-md rounded-3xl gap-10 min-w-min max-w-fit">
//           <div className="flex justify-between group-hover:brightness-125 gap-5">
//             <div>
//               <h3 className="text-slate-700 dark:text-slate-400 text-lg">
//                 Earnings
//               </h3>
//               <h1 className="text-3xl">$124323</h1>
//             </div>
//             <CurrencyDollarIcon className="w-10 h-10 text-green-400" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
