import { Link } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";

const PageLayout = ({
  data,
  title,
  description,
  children,
  buttonTitle,
  linkTo,
  buttonTwo,
  breadcrumbs,
}) => {
  return (
    <>
      {breadcrumbs ? (
        <div className="mb-10">
          <Breadcrumbs />
        </div>
      ) : null}
      <div className="mb-6 flex flex-col gap-4 md:gap-0 sm:flex-row sm:justify-between sm:items-center">
        <div>
          <h1 className="text-3xl md:text-4xl">{title}</h1>
          {description ? (
            <p className="text-slate-400 mt-2">{description}</p>
          ) : null}
          {data ? <p className="text-slate-500 mt-4">{data}</p> : null}
        </div>
        <div className="flex gap-5">
          {buttonTitle ? (
            <Link
              to={linkTo}
              className="inline-flex items-center rounded-lg border px-3 py-2.5 text-base font-medium focus-within:outline-none"
            >
              {buttonTitle}
            </Link>
          ) : null}
          {buttonTwo}
        </div>
      </div>

      <div className="my-10">{children}</div>
    </>
  );
};

export default PageLayout;
