import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import React from "react";
import client from "utils/api-client";

const Roles = () => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["roles"],
    queryFn: () => client(`admin/allUser`),
  });

  return (
    <>
      <PageLayout
        title="Roles"
        buttonTitle="Create Role +"
        linkTo="/roles/createRole"
      >
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}

        <DataTable
          rows={data?.adminDoc}
          columns={columns}
          deleteUrl={`admin/delete`}
          queryKey={["roles"]}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default Roles;

const columns = [
  {
    label: "Email",
    field: "email",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Role",
    field: "role",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
