import clsx from "clsx";
import { ChevronDownIcon } from "@heroicons/react/outline";

const formClasses =
  "bg-gray-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";

export function Label({ id, children }) {
  return (
    <label htmlFor={id} className="block mb-2 text-gray-900 dark:text-gray-300">
      {children}
    </label>
  );
}

export function FormGrid({ classNames, children }) {
  return (
    <div
      className={clsx(classNames, "grid grid-cols-1 lg:grid-cols-2 gap-5 my-6")}
    >
      {children}
    </div>
  );
}

export function TextField({
  id,
  label,
  type = "text",
  innerref,
  className,
  ...props
}) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <input
        id={id}
        type={type}
        ref={innerref}
        {...props}
        className={formClasses}
      />
    </div>
  );
}

export function TextArea({ id, label, innerref, className, ...props }) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <textarea id={id} ref={innerref} {...props} className={formClasses} />
    </div>
  );
}

export function DateTimeField({
  id,
  label,
  type = "datetime-local",
  innerref,
  className,
  ...props
}) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <input id={id} type={type} {...props} className={formClasses} />
    </div>
  );
}

export function ImageField({ id, label, type = "file", className, ...props }) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <input
        id={id}
        type={type}
        {...props}
        className="text-sm text-grey-500
            file:mr-5 file:py-2 file:px-6
            file:rounded-full file:border-0
            file:text-sm file:font-medium
            file:bg-blue-50 file:text-blue-700
            hover:file:cursor-pointer hover:file:bg-amber-50
            hover:file:text-amber-700
          "
      />
    </div>
  );
}

export function SelectField({ id, label, innerref, className, ...props }) {
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <div
        className={clsx(
          formClasses,
          "flex gap-3 border rounded-lg mt-2 group focus-within:border-[#3186D5] transition duration-200 items-center relative"
        )}
      >
        <select
          id={id}
          ref={innerref}
          {...props}
          className="w-full h-full focus:outline-none px-1 active:bg-white active:bg-transparent appearance-none bg-transparent"
        />
        <ChevronDownIcon className="w-4 h-4 opacity-70 inset-auto absolute right-3" />
      </div>
    </div>
  );
}

export function CheckBoxField({
  id,
  label,
  type = "checkbox",
  innerref,
  className,
  ...props
}) {
  return (
    <div className={clsx(className, "flex items-start")}>
      <div className="flex items-center h-5">
        <input
          id={id}
          type={type}
          ref={innerref}
          {...props}
          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300  dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      {label && (
        <div className="ml-3 text-sm">
          <label
            htmlFor={id}
            className="font-medium text-gray-900 dark:text-gray-300"
          >
            {label}
          </label>
        </div>
      )}
    </div>
  );
}

export function ColorField({ id, label, type = "color", className, ...props }) {
  return (
    <div className={clsx(className, "flex items-start")}>
      <div className="flex items-center h-5">
        <input
          id={id}
          type={type}
          {...props}
          className="appearance-none bg-none border-gray-300 dark:border-gray-700 border cursor-pointer h-9 w-9 rounded-full overflow-hidden p-0"
        />
      </div>
      {label && (
        <div className="ml-3 text-sm">
          <label
            htmlFor={id}
            className="font-medium text-gray-900 dark:text-gray-300"
          >
            {label}
          </label>
        </div>
      )}
    </div>
  );
}

export function SubmitButton({
  children,
  className,
  type = "submit",
  ...props
}) {
  return (
    <button
      type={type}
      {...props}
      className={clsx(
        "bg-blue-600 px-8 py-2.5 rounded-xl text-white hover:scale-[0.98] active:scale-[0.97] transition duration-200",
        `${className}`
      )}
    >
      {children}
    </button>
  );
}

export function RadioButtonGroup({ title = "", radioMethods = [], ...props }) {
  return (
    <div>
      <label className="text-base font-semibold text-gray-900 dark:text-white">
        {title}
      </label>
      <fieldset className="mt-1">
        <legend className="sr-only">Radio method</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-3 sm:space-y-0">
          {radioMethods.map((radioMethod) => (
            <div key={radioMethod.id} className="flex items-center">
              <input
                id={`${radioMethod.id}-${title}`}
                name={`radio-method-$-${title}`}
                type="radio"
                value={radioMethod.id}
                // checked={radioMethod.id}
                defaultChecked={radioMethod.id === ""}
                {...props}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor={`${radioMethod.id}-${title}`}
                className="ml-1.5 block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                {radioMethod.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
