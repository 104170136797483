import { FormGrid, SubmitButton, TextField } from "components/Fields";
import FileDialog from "components/FileDialog";
import usePathNames from "hooks/usePathnames";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const EditSubject = () => {
  const { subjectId, courseId, uniId, moduleId } = useParams();
  const pathname = usePathNames();
  let navigate = useNavigate();
  const [subjectData, setSubjectData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    client(`subject/getSubject/${subjectId}`).then(
      (data) => {
        setSubjectData(data.subjectDoc);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [subjectId]);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setSubjectData({ ...subjectData, [name]: value });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    delete subjectData.__v;
    delete subjectData.course;

    setLoading(true);

    await client(`subject/edit`, { body: subjectData }).then(
      (data) => {
        console.log(data);
        setLoading(false);
        navigate(
          `/${pathname}/${uniId}/${courseId}/${moduleId}/createContent/subjects`,
          {
            replace: true,
          }
        );
      },
      (error) => {
        console.log(error);
        setLoading(false);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }

  return (
    <>
      <div>
        <h1 className="text-4xl">Edit Subject</h1>
        <form onSubmit={handleSubmit}>
          <FormGrid>
            <TextField
              label="Subject Name"
              id="SubjectName"
              name="name"
              value={subjectData?.name}
              onChange={handleChange}
            />

            <div className="flex">
              <FileDialog
                handleChange={(selected) => {
                  setSubjectData({ ...subjectData, image: selected });
                }}
              />
              <img src={subjectData?.image} alt="" className="max-w-[100px]" />
              {/* {loading && "loading..."}
              {error && `${error}`} */}
            </div>
          </FormGrid>
          <SubmitButton disabled={loading}>
            {loading ? <span> Submitting...</span> : <span>Submit</span>}
          </SubmitButton>
        </form>
      </div>
    </>
  );
};

export default EditSubject;
