import React from "react";

const Home = () => {
  return (
    <div>
      <h1 className="text-4xl font-bold">Welcome to Dashboard</h1>
      <p className="text-xl my-3 opacity-60">
        Welcome to the admin panel. This is where you will be able to manage all
        aspects of the website, from content to design. Make sure to take some
        time to familiarize yourself with all the features and options available
        to you. If you have any questions, feel free to contact me. Thank you
        for choosing me as your web developer!
      </p>
    </div>
  );
};

export default Home;
