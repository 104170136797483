import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import client from "utils/api-client";

const Coupons = () => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["allPromos"],
    queryFn: () => client(`promo/allPromos`),
  });

  return (
    <PageLayout
      title="All Coupons"
      buttonTitle="Create New +"
      linkTo="/Coupons/createCoupon"
    >
      {isError && (
        <div className="text-red-400">
          <p>There was an error:</p>
          <pre>{error.message}</pre>
        </div>
      )}

      <DataTable
        rows={data?.value}
        columns={columns}
        deleteUrl={`promo/delete`}
        queryKey={["allPromos"]}
        editTo={`Coupons`}
        loading={isLoading}
      />
    </PageLayout>
  );
};

export default Coupons;

const columns = [
  {
    label: "Promo Name",
    field: "promo",
  },
  {
    label: "Validity",
    field: "validity",
  },
  {
    label: "Active Date",
    field: "active_date",
  },
  {
    label: "Type",
    field: "promo_type",
  },
  {
    label: "Promo For",
    field: "promo_for",
  },
  {
    label: "University",
    field: "university",
  },
  {
    label: "Course",
    field: "course",
  },
  {
    label: "Discount",
    field: "discount",
  },
  {
    label: "Max Discount",
    field: "maxDiscount",
  },
  {
    label: "Min Discount",
    field: "minAmount",
  },
  // {
  //   label: "Edit",
  //   field: "edit",
  // },
  {
    label: "Delete",
    field: "_id",
  },
];
