import "styles/rte.css";
import {
  Count,
  EmojiPicker,
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  Table,
} from "@syncfusion/ej2-react-richtexteditor";
import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NGaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZjUX5ecXFXR2NcUUFwXg=="
);

function TextEditor({ defaultData, dataHtml }) {
  const quickToolbarSettings = {
    table: [
      "TableHeader",
      "TableRows",
      "TableColumns",
      "TableCell",
      "-",
      "BackgroundColor",
      "TableRemove",
      "TableCellVerticalAlign",
      "Styles",
    ],
  };
  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "CreateTable",
      "CreateLink",
      "Image",
      "|",
      "Formats",
      "Alignments",
      "NumberFormatList",
      "BulletFormatList",
      "Outdent",
      "Indent",
      "SuperScript",
      "SubScript",
      "EmojiPicker",
      "|",
      "ClearFormat",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
  };

  let rteObj;

  function onChange() {
    let textArea = rteObj.getHtml();
    dataHtml(textArea);
  }

  return (
    <>
      <RichTextEditorComponent
        ref={(richtexteditor) => {
          rteObj = richtexteditor;
        }}
        toolbarSettings={toolbarSettings}
        quickToolbarSettings={quickToolbarSettings}
        insertImageSettings={{
          saveUrl: `${process.env.REACT_APP_API_URL}/upload/image`,
          saveFormat: "Base64",
        }}
        imageUploading={(e) => {
          e.currentRequest.setRequestHeader(
            "x-access-token",
            JSON.parse(window.localStorage.getItem("token"))
          );
          const formData = new FormData();
          formData.append("image", e.fileData.rawFile);
          e.currentRequest.send(formData);
        }}
        showCharCount={true}
        change={onChange}
        saveInterval={10}
        value={defaultData ? defaultData : ""}
      >
        <Inject
          services={[
            Toolbar,
            Image,
            Link,
            HtmlEditor,
            Count,
            QuickToolbar,
            Table,
            EmojiPicker,
          ]}
        />
      </RichTextEditorComponent>
    </>
  );
}
export default TextEditor;
