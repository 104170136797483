import Header from "./Header";
import NavBar from "./NavBar";
import { useEffect, useState } from "react";

const Layout = ({ children }) => {
  let [isScrolled, setIsScrolled] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    function onScroll() {
      setIsScrolled(window.scrollY > 0);
    }
    onScroll();
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col h-screen">
        <Header isScrolled={isScrolled} show={show} setShow={setShow} />
        <div className="flex">
          <NavBar show={show} setShow={setShow} />
          <div className="sm:ml-0 md:ml-48 lg:ml-64 p-8 rounded-2xl overflow-hidden w-full mt-20 h-full">
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;

function Footer() {
  return (
    <footer className="mt-auto">
      <p className="flex justify-center text-slate-500 dark:text-white/50 my-6">
        © 2023 EasyPaper / v1.0.1
      </p>
    </footer>
  );
}
