import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import client from "utils/api-client";
import { useQuery } from "@tanstack/react-query";

const GovtExam = () => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["govtUniData"],
    queryFn: () => client(`uni/getAllUniversity?label=GOVT`),
  });

  return (
    <>
      <PageLayout
        title="Government Exams"
        data={`Total Government Exams: ${data ? data.data.length : ""}`}
        buttonTitle="Create +"
        linkTo="/govt/createUniversity"
        breadcrumbs={true}
      >
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}

        <DataTable
          rows={data?.data}
          columns={columns}
          deleteUrl={`uni/delete`}
          queryKey={["govtUniData"]}
          editTo={`editUni`}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default GovtExam;

const columns = [
  {
    label: "",
    field: "image",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "SubTitle",
    field: "subTitle",
  },
  {
    label: "Courses",
    field: "name",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
