import { PencilAltIcon } from "@heroicons/react/outline";
import DeleteButton from "components/dataTable/DeleteButton";
import Loading from "components/dataTable/Loading";
import NoDataFound from "components/dataTable/NoDataFound";
import PageLayout from "components/PageLayout";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import client from "utils/api-client";
import { useAsync } from "utils/hooks";

const PaymentPlans = () => {
  const { run, data, error, isError, isLoading, isSuccess } = useAsync();

  useEffect(() => {
    run(client(`course/paymentPlans`));
  }, [run]);

  return (
    <>
      <PageLayout
        title="All Payment Plans"
        buttonTitle="Create Payment Plan +"
        linkTo="/paymentPlans/createPaymentPlan"
      >
        <NoDataFound length={data?.pricing.length} />
        <div className="flex flex-wrap my-6 gap-5 relative">
          <Loading loading={isLoading} />
          {isError && (
            <div className="text-red-400">
              <p>There was an error:</p>
              <pre>{error.message}</pre>
            </div>
          )}
          {isSuccess &&
            data?.pricing?.map((pricing) => (
              <div
                key={pricing._id}
                className="border dark:border-slate-700 bottom-2 p-4 relative w-fit dark:bg-slate-800 bg-white rounded-xl shadow-lg"
              >
                <div>
                  <h3 className="uppercase font-semibold text-sm text-gray-400">
                    Plan Name
                  </h3>
                  <h2 className="font-medium mb-3">{pricing.planName}</h2>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  {pricing.plan.map((planns) => (
                    <div
                      className="border dark:border-slate-600 p-3 rounded-xl grid grid-cols-2 gap-3"
                      key={planns._id}
                    >
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          Name
                        </h3>
                        <p>{planns.name}</p>
                      </div>
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          Amount
                        </h3>
                        <p>{planns.amount}</p>
                      </div>
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          Duration
                        </h3>
                        <p>{planns.duration}</p>
                      </div>
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          Price
                        </h3>
                        <p className="line-through">{planns.price}</p>
                      </div>
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          Plan label
                        </h3>
                        <p className="">
                          {planns.plan_label ? planns.plan_label : "N/A"}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="absolute -top-3 -right-3">
                  <DeleteButton
                    rowid={pricing._id}
                    url={`payment/plan/delete`}
                  />
                </div>
                <Link
                  className="absolute top-8 -right-3"
                  to={`/paymentPlans/editPaymentPlan/${pricing._id}`}
                >
                  <div className="text-blue-400 dark:text-blue-300 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow border dark:border-slate-600">
                    <PencilAltIcon className="w-4 h-4" />
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </PageLayout>
    </>
  );
};

export default PaymentPlans;
