import myImf from "assets/image.png";
import { Logo } from "../../assets/icons/Logo";
import { ThemeSelector } from "../ThemeSelector";
import { useLogout } from "hooks/useLogout";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default function Header({ isScrolled, show, setShow }) {
  const { logout } = useLogout();

  return (
    <header
      className={clsx(
        "fixed top-0 left-0 right-0 z-50 flex flex-wrap items-center justify-between bg-white px-4 py-5 shadow-sm shadow-slate-900/5 transition duration-500 dark:shadow-none sm:px-6 lg:px-8 border-b border-slate-100/40 dark:border-white/10",
        {
          "dark:bg-slate-900/95 dark:backdrop-blur dark:[@supports(backdrop-filter:blur(0))]:bg-slate-900/75 bg-white/95 backdrop-blur [@supports(backdrop-filter:blur(0))]:bg-white/75":
            isScrolled,
          "dark:bg-transparent bg-transparent": !isScrolled,
        }
      )}
    >
      <div className="relative flex flex-grow basis-0 items-center gap-2">
        <button
          className="md:hidden border border-slate-700 rounded-md"
          aria-controls="primary-navigation"
          aria-expanded="false"
          onClick={() => {
            setShow(!show);
          }}
        >
          <MenuAlt2Icon className="w-6 h-6 stroke-slate-400" />
        </button>
        <Link to="/">
          <div className="block">
            <Logo />
          </div>
        </Link>
      </div>
      <div className="relative flex basis-0 justify-end items-center space-x-6 sm:space-x-8 md:flex-grow">
        <ThemeSelector className="relative z-10" />
        <div className="bg-slate-800 dark:bg-slate-500 w-10 h-10 rounded-full overflow-hidden">
          <Menu as="div" className=" inline-block text-left">
            <div>
              <Menu.Button className="">
                <img src={myImf} alt="" className="" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-slate-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          logout();
                        }}
                        className={`${
                          active ? "bg-blue-500" : ""
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        Log Out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </header>
  );
}
