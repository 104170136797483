import { useEffect } from "react";
import client from "utils/api-client";
import { useAsync } from "utils/hooks";

import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import usePathNames from "hooks/usePathnames";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AddBanner = () => {
  const { data, run } = useAsync();
  const navigate = useNavigate();
  const { uniId } = useParams();
  const pathname = usePathNames();

  useEffect(() => {
    run(client(`banner/allBanner`));
  }, [run]);

  const [selectedPeople, setSelectedPeople] = useState([]);

  useLayoutEffect(() => {}, [data?.bannerDoc.length, selectedPeople]);

  function handleSubmitBanner() {
    client(`banner/uniBanner`, {
      body: { _id: uniId, bannerId: selectedPeople },
    })
      .then((res) => {
        console.log(res);
        navigate(`/${pathname}/editUni/${uniId}`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <>
      <div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-4xl font-semibold">Banners</h1>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full table-fixed shadow-2xl">
                  <thead className="text-gray-700 text-xs uppercase bg-gray-50 dark:bg-slate-700 dark:text-gray-400 sticky top-0">
                    <tr>
                      <th
                        scope="col"
                        className="relative w-12 px-6 sm:w-16 sm:px-8"
                      >
                        Select
                      </th>
                      <th scope="col" className="py-3.5 pr-3 text-left">
                        Banner Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left">
                        University Id
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left">
                        Course Id
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left">
                        Title
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left">
                        Label
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left">
                        URL
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-slate-700">
                    {data?.bannerDoc?.map((banner) => (
                      <tr
                        key={banner?._id}
                        className={
                          selectedPeople.includes(banner)
                            ? "bg-white dark:bg-gray-900"
                            : undefined
                        }
                      >
                        <td className="relative w-12 px-6 sm:w-16 sm:px-8 whitespace-nowrap">
                          {selectedPeople.includes(banner) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                            value={banner?._id}
                            checked={selectedPeople.includes(banner?._id)}
                            onChange={(e) =>
                              setSelectedPeople(
                                e.target.checked
                                  ? [...selectedPeople, banner?._id]
                                  : selectedPeople.filter(
                                      (p) => p !== banner?._id
                                    )
                              )
                            }
                          />
                        </td>
                        <td
                          className={classNames(
                            "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                            selectedPeople.includes(banner?._id)
                              ? "text-blue-600"
                              : ""
                          )}
                        >
                          <div className="flex gap-4">
                            <div className="h-10 w-16 flex-shrink-0">
                              <img
                                className="h-10 w-auto rounded-xl"
                                src={banner?.image_url}
                                alt=""
                              />
                            </div>
                            {banner?.banner_type}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {banner?.uni_id?.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {banner?.course_id?.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {banner?.title}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {banner?.label}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {banner?.url}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {selectedPeople.length > 0 && (
          <div className="mt-8">
            <button
              type="button"
              onClick={handleSubmitBanner}
              disabled={selectedPeople.length === 0}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Banners
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default AddBanner;
