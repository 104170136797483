import PageLayout from "components/PageLayout";
import {
  TextField,
  SelectField,
  SubmitButton,
  CheckBoxField,
  Label,
} from "components/Fields";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";
import FileDialog from "components/FileDialog";
import TextEditor from "components/TextEditor";
import { useQuery } from "@tanstack/react-query";
import Spinner from "components/Spinner";

const EditJobs = () => {
  const navigate = useNavigate();
  const [jobData, setJobData] = useState({});
  const [jobDescriptionHtml, setJobDescriptionHtml] = useState(null);
  const { id } = useParams();

  const { isError, error, isLoading } = useQuery({
    queryKey: ["editjob", { id }],
    queryFn: () => client(`job/${id}`),
    onSettled: (data) => {
      if (data) {
        setJobData(data.data);
      }
    },
  });

  function submitForm(e) {
    e.preventDefault();

    const body = jobDescriptionHtml
      ? { ...jobData, job_discription: jobDescriptionHtml }
      : jobData;

    client(`job/edit/${id}`, {
      body: body,
    })
      .then(() => navigate("/jobs", { replace: true }))
      .catch((err) => {
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }
  function handleChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setJobData({ ...jobData, [name]: value });
  }

  return (
    <PageLayout title="Edit Jobs">
      {isError ? (
        <p>
          <pre>{error}</pre>
        </p>
      ) : null}
      {isLoading ? (
        <Spinner />
      ) : (
        <form onSubmit={submitForm}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <TextField
              label="Job Title"
              id="name"
              name="job_title"
              value={jobData?.job_title}
              onChange={handleChange}
              required
            />
            <div className="flex">
              <FileDialog
                handleChange={(selected) => {
                  setJobData({
                    ...jobData,
                    image_url: selected,
                  });
                }}
              />
              <img src={jobData?.image_url} alt="" className="max-w-[100px]" />
            </div>
            <TextField
              label="Job location"
              id="name"
              name="job_location"
              value={jobData?.job_location}
              onChange={handleChange}
              required
            />
            <SelectField
              label="Job Type"
              id="job_type"
              name="job_type"
              value={jobData?.job_type}
              onChange={handleChange}
              required
            >
              <option value="INTERNSHIP">Internship</option>
              <option value="FULL_TIME">Full Time</option>
              <option value="CONTRACT">Contract</option>
              <option value="PART_TIME">Part Time</option>
            </SelectField>

            <TextField
              label="Opening(no. of positions)"
              id="position"
              name="position"
              type="number"
              value={jobData?.position}
              onChange={handleChange}
            />
            <CheckBoxField
              label="Active"
              id="active"
              name="active"
              onChange={handleChange}
              checked={jobData?.active}
            />
          </div>
          <div className="py-4">
            <Label>Job Description</Label>
            <TextEditor
              dataHtml={(html) => {
                setJobDescriptionHtml(html);
              }}
              defaultData={jobData?.job_discription}
            />
          </div>
          <SubmitButton>Submit</SubmitButton>
        </form>
      )}
    </PageLayout>
  );
};

export default EditJobs;
