import { Menu } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import { RadioButtonGroup, SelectField } from "components/Fields";
import FilterDropDown from "components/FilterDropDown";
import PageLayout from "components/PageLayout";
import PopoverExample from "components/Popover";
import usePathNames from "hooks/usePathnames";
import { useMemo, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import client from "utils/api-client";

const ManageContent = () => {
  const { uniId, courseId, moduleId, layout } = useParams();
  const pathnames = usePathNames();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const { isLoading, data, isError, error } = useQuery({
    queryKey:
      layout === "LAYOUT_1"
        ? ["layout", { layout, page, size, moduleId }]
        : ["layout", { moduleId }],
    queryFn: () =>
      layout === "LAYOUT_1"
        ? client(
            `layout/${layout}/content/${moduleId}?page=${page}&size=${size}`
          )
        : client(`layout/${layout}/content/${moduleId}`),

    keepPreviousData: true,
  });

  const { data: semesterData } = useQuery({
    queryKey: ["semster"],
    queryFn: () => client(`module/getSemester/${moduleId}`),
    enabled: data && Object.keys(data).length > 0,
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPage = (e) => {
    setSize(+e.target.value);
  };

  let [searchParams, setSearchParams] = useSearchParams();

  const sortSemester = searchParams.get("semester");
  const sortActive = searchParams.get("active");
  const sortPaid = searchParams.get("paid");
  const sortFile = searchParams.get("file");

  // let sortedData = (
  //   data ? (layout === "LAYOUT_3" ? [...data] : [...data?.data]) : []
  // ).filter((content) => {
  //   if (sortSemester && content.semester !== sortSemester) {
  //     return false;
  //   }
  //   if (sortActive && sortActive !== "" && `${content.active}` !== sortActive) {
  //     return false;
  //   }
  //   if (sortPaid && sortPaid !== "" && `${content.paid}` !== sortPaid) {
  //     return false;
  //   }
  //   if (sortFile && sortFile !== "") {
  //     if (sortFile === "true" && !content.pdf) {
  //       return false;
  //     }
  //     if (sortFile === "false" && content.pdf) {
  //       return false;
  //     }
  //   }
  //   return true;
  // });

  let sortedData = useMemo(() => {
    return (
      data ? (layout === "LAYOUT_3" ? [...data] : [...data?.data]) : []
    ).filter((content) => {
      if (sortSemester && content.semester !== sortSemester) {
        return false;
      }
      if (
        sortActive &&
        sortActive !== "" &&
        `${content.active}` !== sortActive
      ) {
        return false;
      }
      if (sortPaid && sortPaid !== "" && `${content.paid}` !== sortPaid) {
        return false;
      }
      if (sortFile && sortFile !== "") {
        if (sortFile === "true" && !content.pdf) {
          return false;
        }
        if (sortFile === "false" && content.pdf) {
          return false;
        }
      }
      return true;
    });
  }, [data, layout, sortActive, sortFile, sortPaid, sortSemester]);

  return (
    <PageLayout
      title="Manage Content"
      description={layout}
      buttonTitle="Add Content +"
      breadcrumbs={true}
      linkTo={`/${pathnames}/${uniId}/${courseId}/${moduleId}/${layout}/createContent`}
      buttonTwo={
        <>
          {layout === "LAYOUT_1" || layout === "LAYOUT_2" ? (
            <div className="flex">
              <PopoverExample>
                <Link
                  to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/${layout}/import`}
                  className="flex items-center rounded-lg transition duration-150 ease-in-out hover:bg-gray-100 dark:hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                >
                  <div className="m-2">
                    <p className="text-sm font-medium">Import</p>
                  </div>
                </Link>
                <Link
                  to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/${layout}/importFromCourse`}
                  className="flex items-center rounded-lg transition duration-150 ease-in-out hover:bg-gray-100 dark:hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                >
                  <div className="m-2">
                    <p className="text-sm font-medium">Import from Course</p>
                  </div>
                </Link>
                <Link
                  to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/${layout}/importFromCourse?copy=true`}
                  className="flex items-center rounded-lg transition duration-150 ease-in-out group hover:bg-gray-100 dark:hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                >
                  <div className="m-2">
                    <p className="text-sm font-medium">Copy from Course</p>
                  </div>
                </Link>
              </PopoverExample>
            </div>
          ) : null}
        </>
      }
    >
      {isError ? (
        <div className="text-red-400">
          <p>There was an error:</p>
          <pre>{error.message}</pre>
        </div>
      ) : null}

      <FilterDropDown>
        <Menu.Items className="text-black p-1">
          <div className="p-1">
            <SelectField
              label={`Semester`}
              value={sortSemester || ""}
              onChange={(e) => {
                searchParams.set("semester", e.target.value);
                setSearchParams(searchParams);
              }}
            >
              <option value="">All</option>
              {semesterData?.semesterDoc?.map((sem) => (
                <option key={sem._id}>{sem.name}</option>
              ))}
            </SelectField>
          </div>
        </Menu.Items>
        <Menu.Items className="text-black p-1">
          <div className="p-1">
            <RadioButtonGroup
              title="Status"
              radioMethods={[
                { id: "", title: "All" },
                { id: "true", title: "Active" },
                { id: "false", title: "Inactive" },
              ]}
              onChange={(e) => {
                searchParams.set("active", e.target.value);
                setSearchParams(searchParams);
              }}
            />
          </div>
        </Menu.Items>
        <Menu.Items className="text-black p-1">
          <div className="p-1">
            <RadioButtonGroup
              title="Pricing"
              radioMethods={[
                { id: "", title: "All" },
                { id: "true", title: "Paid" },
                { id: "false", title: "Free" },
              ]}
              // checked={sortPaid ? sortPaid : null}
              onChange={(e) => {
                searchParams.set("paid", e.target.value);
                setSearchParams(searchParams);
              }}
            />
          </div>
        </Menu.Items>
        <Menu.Items className="text-black p-1">
          <div className="p-1">
            <RadioButtonGroup
              title="File"
              radioMethods={[
                { id: "", title: "All" },
                { id: "true", title: "File" },
                { id: "false", title: "No File" },
              ]}
              onChange={(e) => {
                searchParams.set("file", e.target.value);
                setSearchParams(searchParams);
              }}
            />
          </div>
        </Menu.Items>
      </FilterDropDown>

      <DataTable
        rows={sortedData}
        columns={
          layout === "LAYOUT_1"
            ? columns1
            : layout === "LAYOUT_2"
            ? columns2
            : columns3
        }
        editTo={`editContent`}
        deleteUrl={`layout/${layout}/delete`}
        queryKey={
          layout === "LAYOUT_1"
            ? ["layout", { layout, page, size, moduleId }]
            : ["layout", { moduleId }]
        }
        loading={isLoading}
        paging={layout === "LAYOUT_1" && true}
        pageCount={data?.pages}
        theCurrentPage={page}
        onPageChange={handlePageChange}
        usersPerPage={size}
        handleChangeRowsPerPage={handleRowsPerPage}
        rowsPerpage={data?.data?.length}
      />
    </PageLayout>
  );
};

export default ManageContent;

const columns1 = [
  {
    label: "Semester",
    field: "semester",
  },
  {
    label: "Subject Name",
    field: "subject",
  },
  {
    label: "File",
    field: "pdf._id",
  },
  {
    label: "Paid",
    field: "paid",
  },
  {
    label: "Show AD",
    field: "showAd",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
const columns2 = [
  {
    label: "Semester",
    field: "semester",
  },
  {
    label: "Subject Name",
    field: "subject",
  },
  {
    label: "File",
    field: "pdf",
  },
  {
    label: "Year",
    field: "year",
  },
  {
    label: "Paid",
    field: "paid",
  },
  {
    label: "Show AD",
    field: "showAd",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "content_id",
  },
];
const columns3 = [
  {
    label: "Semester",
    field: "semester",
  },
  {
    label: "Unit Name",
    field: "unit_name",
  },
  {
    label: "Subject Name",
    field: "subject",
  },
  {
    label: "File",
    field: "pdf._id",
  },
  {
    label: "Year",
    field: "year",
  },
  {
    label: "Paid",
    field: "paid",
  },
  {
    label: "Show AD",
    field: "showAd",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Edit",
    field: "edit",
  },
];
