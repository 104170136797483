const Spinner = ({ className }) => {
  return (
    <div className={className}>
      <div className="my-3 flex justify-center">
        <span className="animate-spin w-6 h-6 rounded-full inline-block box-border border-2 border-solid dark:border-white/[0.1] dark:border-b-white border-b-slate-700 border-slate-400"></span>
      </div>
    </div>
  );
};

export default Spinner;
