import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import usePathNames from "hooks/usePathnames";
import { useParams } from "react-router-dom";
import client from "utils/api-client";

const Modules = () => {
  const { courseId, uniId } = useParams();
  const pathnames = usePathNames();

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["allModules", { courseId }],
    queryFn: () => client(`course/getCourseDetails/${courseId}`),
  });

  return (
    <>
      <PageLayout
        title={`Course Modules`}
        buttonTitle="Add Module +"
        description={`Modules Count: ${
          data ? data.coursesDoc.module.length : ""
        }`}
        linkTo={`createModule`}
        breadcrumbs={true}
      >
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}

        <DataTable
          columns={columns}
          rows={data?.coursesDoc.module}
          deleteUrl={`module/delete`}
          queryKey={["allModules", { courseId }]}
          editTo={`editModule`}
          nextRoute={`${pathnames}/${uniId}/${courseId}`}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default Modules;

const columns = [
  {
    label: "Module Icon",
    field: "image",
  },
  {
    label: "Module Name",
    field: "name",
  },
  {
    label: "Module Type",
    field: "type",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Manage Content",
    field: "name",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
