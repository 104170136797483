import DataNotFoundImage from "assets/no-data.png";

export default function NoDataFound({ length }) {
  return (
    <>
      {length === 0 && (
        <>
          <div className="flex justify-center my-4 ">
            <div className="flex flex-col justify-center gap-2">
              <img
                src={DataNotFoundImage}
                alt=""
                className="w-14 h-auto mx-auto opacity-80"
              />
              <h1 className="text-md">No Data</h1>
            </div>
          </div>
        </>
      )}
    </>
  );
}
