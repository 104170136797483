import PageLayout from "components/PageLayout";
import {
  CheckBoxField,
  Label,
  SelectField,
  SubmitButton,
  TextArea,
  TextField,
} from "components/Fields";
import FileDialog from "components/FileDialog";
import TextEditor from "components/TextEditor";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";
import CreatableSelect from "react-select/creatable";
import { useQuery } from "@tanstack/react-query";
import Spinner from "components/Spinner";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const AdminInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [blogData, setBlogData] = useState({
    title: "",
    short_discription: "",
    image: "",
    active: false,
    uni_id: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [blogHtml, setBlogHtml] = useState(null);
  const [value, setValue] = useState([]);

  const { active, image, short_discription, title, uni_id } = blogData;

  const { data: allUni } = useQuery({
    queryKey: ["uni/getAllUniversity?label=ALL"],
    queryFn: () => client(`uni/getAllUniversity?label=ALL`),
  });
  const { isLoading } = useQuery({
    queryKey: ["edit blog", { id }],
    queryFn: () => client(`blog/${id}`),
    onSettled: (data) => {
      if (data) {
        setBlogData(data.data);
        const valus = data.data.tags.map((val) => {
          return {
            label: val,
            value: val,
          };
        });
        setValue(valus);
      }
    },
  });

  function submitForm(e) {
    e.preventDefault();

    const outputvalue = value.map((item) => item.value);

    const body = blogHtml
      ? { ...blogData, tags: outputvalue, blog: blogHtml }
      : { ...blogData, tags: outputvalue };

    client(`blog/edit/${id}`, {
      body: body,
      method: "PUT",
    })
      .then(() => navigate("/blogs"))
      .catch((err) => {
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  function handleChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setBlogData({ ...blogData, [name]: value });
  }

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
        break;
      default:
    }
  };

  return (
    <PageLayout title={"Admission Info"}>
      {isLoading ? (
        <Spinner />
      ) : (
        <form onSubmit={submitForm}>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
            <TextField
              label="Blog Title"
              id="title"
              name="title"
              value={title}
              onChange={handleChange}
              required
            />
            <SelectField
              label="Select University"
              id="uni"
              name="uni_id"
              value={uni_id}
              onChange={handleChange}
            >
              <option value="">-----Select University-----</option>
              {allUni?.university?.map((uni) => (
                <option key={uni._id} value={uni._id}>
                  {uni.name}
                </option>
              ))}
            </SelectField>
            <div className="flex">
              <FileDialog
                handleChange={(selected) => {
                  setBlogData({
                    ...blogData,
                    image: selected,
                  });
                }}
              />
              <img src={image} alt="" className="max-w-[100px]" />
            </div>
            <CheckBoxField
              label="Active"
              id="active"
              name="active"
              onChange={handleChange}
              checked={active}
            />
            <div>
              <Label>Tags</Label>
              <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(newValue) => setValue(newValue)}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder="Type something and press enter to add Tags..."
                value={value}
              />
            </div>
          </div>
          <TextArea
            rows={4}
            label="Description"
            id="short_discription"
            name="short_discription"
            value={short_discription}
            onChange={handleChange}
          />
          <div className="py-4">
            <Label>Blog</Label>
            <TextEditor
              dataHtml={(html) => {
                setBlogHtml(html);
              }}
              defaultData={blogData?.blog}
            />
          </div>
          <SubmitButton>Submit</SubmitButton>
        </form>
      )}
    </PageLayout>
  );
};

export default AdminInfo;
