import PageLayout from "components/PageLayout";
import dummyImg from "assets/background-auth.jpg";
import { StatusButton } from "components/dataTable/TableButtons";
import { TrashIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { PencilAltIcon } from "@heroicons/react/solid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import client from "utils/api-client";
import Spinner from "components/Spinner";
import { toast } from "react-toastify";

const Blogs = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["all blogs"],
    queryFn: () => client(`blog/all/blogs`),
  });

  const mutation = useMutation({
    mutationFn: (id) => client(`blog/delete/${id}`),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["all blogs"] });
      }, 100);
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return (
    <PageLayout
      title={"Blogs"}
      linkTo={"/blogs/new"}
      buttonTitle={"+  Create Blog"}
    >
      {isError ? <p>{error.message}</p> : null}
      {isLoading ? <Spinner /> : null}
      <div className="flex flex-wrap gap-6">
        {data?.data?.map((blog) => (
          <div
            key={blog._id}
            className="border dark:border-slate-700 bottom-2 p-4 relative w-fit dark:bg-slate-800 bg-white rounded-xl shadow-lg h-fit max-w-lg"
          >
            <div>
              <h3 className="uppercase font-semibold text-sm text-gray-400">
                Title
              </h3>
              <h2 className="font-medium mb-3">{blog.title}</h2>
            </div>
            <div className="mb-3">
              <img
                src={blog.image ? blog.image : dummyImg}
                alt=""
                className="rounded-2xl h-48 w-full object-cover"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-full">
                <h3 className="uppercase font-semibold text-sm text-gray-400">
                  Short Description
                </h3>
                <p>{blog.short_discription}</p>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm text-gray-400">
                  University
                </h3>
                <p className="text-ellipsis overflow-hidden">
                  {blog.uni_id.name}
                </p>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm text-gray-400">
                  Active
                </h3>
                <div>
                  <StatusButton value={blog.active} />
                </div>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm text-gray-400">
                  Tags
                </h3>
                <div className="flex flex-wrap gap-2">
                  {blog.tags.map((tag) => (
                    <span
                      key={tag}
                      className="bg-gray-100 dark:bg-gray-500 px-1.5 rounded-md"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <button
              className="absolute -top-3 -right-3 text-red-400"
              onClick={() => {
                mutation.mutate(blog._id);
              }}
            >
              <div className="text-slate-700 dark:text-slate-200 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow border dark:border-slate-600">
                <TrashIcon className="w-4 h-4 text-red-400" />
              </div>
            </button>
            <Link className="absolute top-8 -right-3" to={`/blogs/${blog._id}`}>
              <div className="text-slate-700 dark:text-slate-200 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow border dark:border-slate-600">
                <PencilAltIcon className="w-4 h-4 text-blue-400" />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </PageLayout>
  );
};

export default Blogs;
