import Layout from "components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "assets/icons/Logo";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Layout>
      <div className="flex min-h-full flex-col pt-16 pb-12">
        <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Your Company</span>
              <Logo />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              {/* <p className="text-base font-semibold text-indigo-600">404</p> */}
              <h1 className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">
                Unauthorized Page
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6 flex justify-center space-x-3 sm:border-l sm:border-transparent sm:pl-6 mx-auto">
                <Link
                  to="/"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Go back home
                </Link>
                <button
                  onClick={goBack}
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Unauthorized;
