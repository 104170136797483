import {
  CheckBoxField,
  Label,
  SelectField,
  SubmitButton,
  TextArea,
  TextField,
} from "components/Fields";
import FileDialog from "components/FileDialog";
import PageLayout from "components/PageLayout";
import TextEditor from "components/TextEditor";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";
import CreatableSelect from "react-select/creatable";
import { useQuery } from "@tanstack/react-query";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export default function NewBlog() {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState({
    title: "",
    short_discription: "",
    blog: "",
    image: "",
    active: false,
    uni_id: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);

  const { active, image, short_discription, title, uni_id } = blogData;

  const { data: allUni } = useQuery({
    queryKey: ["uni/getAllUniversity?label=ALL"],
    queryFn: () => client(`uni/getAllUniversity?label=ALL`),
  });

  function submitForm(e) {
    e.preventDefault();

    const outputvalue = value.map((item) => item.value);

    client(`blog/create`, {
      body: { ...blogData, tags: outputvalue },
    })
      .then(() => navigate("/blogs"))
      .catch((err) => {
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  function handleChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setBlogData({ ...blogData, [name]: value });
  }

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
        break;
      default:
    }
  };

  return (
    <PageLayout title={"Create Blog"}>
      <form onSubmit={submitForm}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <TextField
            label="Blog Title"
            id="title"
            name="title"
            value={title}
            onChange={handleChange}
            required
          />
          <SelectField
            label="Select University"
            id="uni"
            name="uni_id"
            value={uni_id}
            onChange={handleChange}
          >
            <option value="">-----Select University-----</option>
            {allUni?.university?.map((uni) => (
              <option key={uni._id} value={uni._id}>
                {uni.name}
              </option>
            ))}
          </SelectField>
          <div className="flex">
            <FileDialog
              handleChange={(selected) => {
                setBlogData({
                  ...blogData,
                  image: selected,
                });
              }}
            />
            <img src={image} alt="" className="max-w-[100px]" />
          </div>
          <CheckBoxField
            label="Active"
            id="active"
            name="active"
            onChange={handleChange}
            checked={active}
          />
          <div>
            <Label>Tags</Label>
            <CreatableSelect
              components={components}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => setValue(newValue)}
              onInputChange={(newValue) => setInputValue(newValue)}
              onKeyDown={handleKeyDown}
              placeholder="Type something and press enter to add Tags..."
              value={value}
            />
          </div>
        </div>
        <TextArea
          label="Description"
          id="short_discription"
          name="short_discription"
          value={short_discription}
          onChange={handleChange}
        />
        <div className="py-4">
          <Label>Blog</Label>
          <TextEditor
            dataHtml={(html) => {
              setBlogData({ ...blogData, blog: html });
            }}
          />
        </div>
        <SubmitButton>Submit</SubmitButton>
      </form>
    </PageLayout>
  );
}
