import {
  TextField,
  SelectField,
  SubmitButton,
  DateTimeField,
} from "components/Fields";
import PageLayout from "components/PageLayout";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const CreateCoupon = () => {
  const navigate = useNavigate();
  const [promoData, setPromoData] = useState({
    promo: "",
    validity: "",
    promo_for: "all",
    label: "UNI",
    active_date: "",
    discount: "",
    promo_type: null,
    minAmount: "",
    maxDiscount: "",
    uniId: "",
    courseId: "",
  });

  const {
    promo,
    validity,
    promo_for,
    label,
    active_date,
    discount,
    promo_type,
    minAmount,
    maxDiscount,
    uniId,
    courseId,
  } = promoData;

  const [allUni, setAllUni] = useState(null);
  const [allCourses, setAllCourses] = useState(null);

  useEffect(() => {
    Promise.all([
      client(`uni/getAllUniversity?label=${label}`),
      uniId && client(`course/allCourses/${uniId}`),
    ]).then(
      (res) => {
        setAllUni(res[0].data);
        uniId && setAllCourses(res[1]);
      },
      (error) => {
        console.log(error);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }, [uniId, label]);

  function submitForm(e) {
    e.preventDefault();

    let data = {};

    if (courseId !== "" && promo_for !== "all") {
      data = {
        promo: promo.toUpperCase(),
        validity,
        promo_for,
        active_date,
        discount,
        promo_type,
        minAmount,
        maxDiscount,
        university: uniId,
        course: courseId,
      };
    } else if (uniId !== "" && promo_for !== "all") {
      data = {
        promo: promo.toUpperCase(),
        validity,
        promo_for,
        active_date,
        discount,
        promo_type,
        minAmount,
        maxDiscount,
        university: uniId,
      };
    } else {
      data = {
        promo: promo.toUpperCase(),
        validity,
        promo_for,
        active_date,
        discount,
        promo_type,
        minAmount,
        maxDiscount,
      };
    }

    client(`promo/create`, {
      body: data,
    })
      .then(() => {
        navigate("/Coupons");
      })
      .catch((err) => {
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  function handleChange(e) {
    setPromoData({ ...promoData, [e.target.name]: e.target.value });
  }

  return (
    <PageLayout title="Create New Coupon">
      <form onSubmit={submitForm}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <TextField
            label="Promo Name"
            id="name"
            name="promo"
            value={promo.toUpperCase()}
            onChange={handleChange}
            required
          />

          <DateTimeField
            label="Validity"
            id="Validity"
            name="validity"
            value={validity}
            onChange={handleChange}
            required
          />

          <SelectField
            label="Promo For"
            id="promo_for"
            name="promo_for"
            value={promo_for}
            onChange={handleChange}
            required
          >
            <option value="all">All</option>
            <option value="university">University</option>
            <option value="course">Course</option>
          </SelectField>

          {(promo_for === "university" || promo_for === "course") && (
            <SelectField
              label="Label"
              id="label"
              name="label"
              value={label}
              onChange={handleChange}
            >
              <option value="UNI">University</option>
              <option value="BOARD">Boards</option>
              <option value="GOVT">Government Exam</option>
              <option value="ENTRANCE">Entrance</option>
            </SelectField>
          )}

          {(promo_for === "university" || promo_for === "course") && (
            <SelectField
              label="Select University"
              id="uni"
              name="uniId"
              value={uniId}
              onChange={handleChange}
            >
              <option value="">------Select University------</option>
              {allUni?.map((uni) => {
                return (
                  <option key={uni._id} value={uni._id}>
                    {uni.name}
                  </option>
                );
              })}
            </SelectField>
          )}

          {promo_for === "course" && (
            <SelectField
              label="Select Course"
              id="course"
              name="courseId"
              value={courseId}
              onChange={handleChange}
            >
              <option value="">------Select Courses------</option>
              {allCourses?.map((course) => {
                return (
                  <option key={course._id} value={course._id}>
                    {course.course_name}
                  </option>
                );
              })}
            </SelectField>
          )}

          <DateTimeField
            label="Active Date"
            id="active_date"
            name="active_date"
            value={active_date}
            onChange={handleChange}
            required
          />

          <TextField
            label="Discount"
            id="dicount"
            name="discount"
            type="number"
            value={discount}
            onChange={handleChange}
          />

          <SelectField
            label="Promo Type"
            id="promo_type"
            name="promo_type"
            onChange={handleChange}
            required
          >
            <option value="">------Select Discount Type------</option>
            <option value="Flat">Flat</option>
            <option value="Percentage">Percentage</option>
          </SelectField>

          <TextField
            label="Min Amount"
            id="minAmount"
            name="minAmount"
            type="number"
            value={minAmount}
            onChange={handleChange}
            required
          />
          <TextField
            label="Max Discount"
            id="maxDiscount"
            name="maxDiscount"
            type="number"
            value={maxDiscount}
            onChange={handleChange}
            required
          />
        </div>
        <SubmitButton>Submit</SubmitButton>
      </form>
    </PageLayout>
  );
};

export default CreateCoupon;
