import { useQueries } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import client from "utils/api-client";
import Loading from "components/dataTable/Loading";
import NoDataFound from "components/dataTable/NoDataFound";
import { BooleanButton, StatusButton } from "components/dataTable/TableButtons";
import { useState } from "react";
import { SelectField } from "components/Fields";
import PageLayout from "components/PageLayout";
import { toast } from "react-toastify";
import useQueryParams from "hooks/useQueryParams";

const ImportPageContent = () => {
  const { moduleId, layout, importId } = useParams();
  // const { uniId, courseId, moduleId, layout, importId } = useParams();
  // let navigate = useNavigate();
  // const pathnames = usePathNames();
  let queryParams = useQueryParams();

  const copy = queryParams.get("copy")
    ? Boolean(queryParams.get("copy"))
    : false;

  const [layoutData, semesters] = useQueries({
    queries: [
      {
        queryKey: ["layoutData"],
        queryFn: () => client(`layout/${layout}/content/${importId}`),
      },
      {
        queryKey: ["semesters"],
        queryFn: () => client(`module/getSemester/${moduleId}`),
      },
    ],
  });

  const [selectedPeople, setSelectedPeople] = useState([]);
  const [formData, setFormData] = useState({
    semesterId: "",
  });

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.semesterId === "")
      return toast.error("Please Select Semester", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    client(`layout/import`, {
      body: {
        layout_id: selectedPeople,
        new_module: moduleId,
        new_semester: formData.semesterId,
        copy: copy,
      },
    }).then(
      (data) => {
        console.log(data);
        // navigate(`/${pathnames}/${uniId}/${courseId}/${moduleId}/${layout}`);
        // navigate(-2);
        toast.success(``, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      },
      (error) => {
        console.log(error);
        // setError(error);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  };

  return (
    <PageLayout title="Import Layout Content">
      <div>
        {layoutData?.isError || semesters?.isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{layoutData?.error || layoutData?.error}</pre>
          </div>
        ) : null}

        <SelectField
          label="Semsters"
          name="semesterId"
          onChange={handleChange}
          value={formData.semesterId}
          required
        >
          <option value="">------Select Semester------</option>
          {semesters?.data?.semesterDoc?.map((sem) => (
            <option key={sem._id} value={sem._id}>
              {sem.name}
            </option>
          ))}
        </SelectField>

        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-xl">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="text-gray-700 text-xs uppercase bg-gray-50 dark:bg-slate-700 dark:text-gray-400 sticky top-0">
                    <tr>
                      <th
                        scope="col"
                        className="relative w-12 px-6 sm:w-16 sm:px-8"
                      ></th>
                      <th scope="col" className="py-3.5 pr-3 text-left">
                        Semester
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left">
                        Subject
                      </th>
                      {layout === "LAYOUT_2" ? (
                        <th scope="col" className="px-3 py-3.5 text-left">
                          Year
                        </th>
                      ) : null}
                      <th scope="col" className="px-3 py-3.5 text-left">
                        File
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left">
                        Active
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left">
                        Paid
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left">
                        ShowAd
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {layoutData?.data?.data?.map((subject, idx) => (
                      <tr
                        key={idx}
                        className={
                          selectedPeople.includes(subject)
                            ? "bg-white dark:bg-gray-900"
                            : undefined
                        }
                      >
                        <td className="relative w-12 px-6 sm:w-16 sm:px-8 whitespace-nowrap">
                          {selectedPeople.includes(subject) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                            value={subject._id}
                            checked={selectedPeople.includes(subject?._id)}
                            onChange={(e) =>
                              setSelectedPeople(
                                e.target.checked
                                  ? [...selectedPeople, subject._id]
                                  : selectedPeople.filter(
                                      (p) => p !== subject._id
                                    )
                              )
                            }
                          />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {subject?.semester}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {subject?.subject}
                        </td>
                        {layout === "LAYOUT_2" ? (
                          <td className="px-3 py-3.5 text-left">
                            {subject?.year}
                          </td>
                        ) : null}
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          <>
                            {subject?.pdf?._id ? (
                              <span
                                // to={`/${pathnames}/pdfView/${value}`}
                                // title="View"
                                className="dark:bg-slate-700 p-3 rounded-md shadow-md"
                              >
                                File
                              </span>
                            ) : (
                              <span className="dark:bg-slate-700 text-orange-500 font-bold p-3 rounded-md shadow-md">
                                No File
                              </span>
                            )}
                          </>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          <StatusButton value={subject?.active} />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          <BooleanButton value={subject?.paid} />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          <StatusButton value={subject?.showAd} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Loading loading={layoutData?.isLoading} />
                <NoDataFound length={layoutData?.data?.data?.length} />
              </div>
            </div>
          </div>
        </div>
        {selectedPeople.length > 0 && (
          <div className="mt-8 -mx-4 sm:-mx-0 lg:-mx-0">
            <button
              type="button"
              onClick={handleSubmit}
              disabled={selectedPeople.length === 0}
              className="rounded-md bg-blue-600 px-6 py-2 text-white shadow-sm hover:bg-blue-700"
            >
              Add Layouts
            </button>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default ImportPageContent;
