import noimage from "assets/no-image.png";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { DownloadIcon } from "@heroicons/react/outline";

const AttachmentPopup = ({ value }) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      {!value ? (
        <div className="w-[52px] h-[52px]">
          <img src={noimage} alt="" className="w-full h-full object-cover" />
        </div>
      ) : (
        <>
          <button type="button" onClick={openModal}>
            <div className="bg-white dark:bg-slate-700 shadow-xl rounded-full w-[52px] h-[52px] overflow-hidden dark:border-slate-700 border-2">
              <img src={value} alt="" className="w-full h-full object-cover" />
            </div>
          </button>

          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-5" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative space-y-3">
                      <div className="flex justify-between items-center border-b pb-4">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-bold leading-6 text-black"
                        >
                          Attachment File
                        </Dialog.Title>

                        <a
                          href={value}
                          download
                          className="bg-gray-200 p-1.5 rounded-full"
                        >
                          <DownloadIcon className="w-6 h-6 text-black" />
                        </a>
                      </div>
                      <div className="">
                        <img src={value} alt="" />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </>
  );
};

export default AttachmentPopup;
