import { SubmitButton, TextField } from "components/Fields";
import PageLayout from "components/PageLayout";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const EditPaymentPlans = () => {
  let navigate = useNavigate();
  const { paymentId } = useParams();
  const [MainName, setMainName] = useState("");
  const [loading, setLoading] = useState(false);
  const [plansData, setPlansData] = useState([]);

  useEffect(() => {
    client(`payment/plan/details/${paymentId}`).then(
      (data) => {
        setMainName(data?.data.planName);
        setPlansData(data?.data.plan);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [paymentId]);

  async function sendPayments(e) {
    e.preventDefault();
    setLoading(true);

    client(`payment/plan/edit`, {
      body: {
        _id: paymentId,
        planName: MainName,
        plan: plansData,
      },
    })
      .then((res) => {
        setLoading(false);
        navigate("/paymentPlans");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  let handleChange = (i, e) => {
    let newFormValues = [...plansData];
    newFormValues[i][e.target.name] = e.target.value;
    setPlansData(newFormValues);
  };

  let addFormFields = () => {
    setPlansData([
      ...plansData,
      {
        plan_label: "",
        name: "",
        amount: "",
        price: "",
        duration: "",
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...plansData];
    newFormValues.pop();
    // newFormValues.splice(i, 1);
    setPlansData(newFormValues);
  };

  return (
    <>
      <PageLayout title="Edit Payment Plan">
        <form onSubmit={sendPayments}>
          <TextField
            label="Main Name"
            id="Main Name"
            value={MainName}
            onChange={(e) => setMainName(e.target.value)}
            required
          />
          {plansData?.map((plan, index) => (
            <div
              className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6 border-b pb-4 border-blue-400"
              key={index}
            >
              <TextField
                label="Plan Name"
                id="Plan Name"
                name="name"
                value={plan?.name}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <TextField
                label="Plan Amount"
                type="number"
                id="Plan Amount"
                name="amount"
                value={plan?.amount}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <TextField
                label="Plan Price"
                type="number"
                id="Plan Price"
                name="price"
                value={plan?.price}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <TextField
                label="Duration"
                type="number"
                id="Plan Duration"
                name="duration"
                value={plan?.duration}
                onChange={(e) => handleChange(index, e)}
                required
              />
              <TextField
                label="Plan label"
                id="Plan_label"
                name="plan_label"
                value={plan?.plan_label}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
          ))}
          <div className="flex justify-between items-center">
            <div className="flex gap-3">
              <div className="button-section">
                <button type="button" onClick={() => addFormFields()}>
                  Add
                </button>
              </div>
              <div className="button-section">
                <button type="button" onClick={() => removeFormFields()}>
                  Remove
                </button>
              </div>
            </div>
            <SubmitButton disabled={loading}>
              {loading ? <span> Submitting...</span> : <span>Submit</span>}
            </SubmitButton>
          </div>
        </form>
      </PageLayout>
    </>
  );
};

export default EditPaymentPlans;
