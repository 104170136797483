import {
  TextField,
  SelectField,
  SubmitButton,
  CheckBoxField,
  Label,
} from "components/Fields";
import PageLayout from "components/PageLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";
import FileDialog from "components/FileDialog";
import TextEditor from "components/TextEditor";
const CreateJob = () => {
  const navigate = useNavigate();
  const [jobData, setJobData] = useState({
    job_title: "",
    image_url: "",
    active: false,
    job_location: "",
    job_discription: "",
    position: "",
    job_type: "INTERNSHIP",
  });

  const { job_title, image_url, job_location, position, job_type, active } =
    jobData;

  function submitForm(e) {
    e.preventDefault();

    client(`job/create`, {
      body: jobData,
    })
      .then(() => navigate("/jobs"))
      .catch((err) => {
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }
  function handleChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setJobData({ ...jobData, [name]: value });
  }

  return (
    <PageLayout title="Add new job">
      <form onSubmit={submitForm}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <TextField
            label="Job Title"
            id="name"
            name="job_title"
            value={job_title}
            onChange={handleChange}
            required
          />
          <div className="flex">
            <FileDialog
              handleChange={(selected) => {
                setJobData({
                  ...jobData,
                  image_url: selected,
                });
              }}
            />
            <img src={image_url} alt="" className="max-w-[100px]" />
          </div>
          <TextField
            label="Job location"
            id="name"
            name="job_location"
            value={job_location}
            onChange={handleChange}
            required
          />
          <SelectField
            label="Job Type"
            id="job_type"
            name="job_type"
            value={job_type}
            onChange={handleChange}
            required
          >
            <option value="INTERNSHIP">Internship</option>
            <option value="FULL_TIME">Full Time</option>
            <option value="CONTRACT">Contract</option>
            <option value="PART_TIME">Part Time</option>
          </SelectField>

          <TextField
            label="Openings (no. of positions)"
            id="position"
            name="position"
            type="number"
            value={position}
            onChange={handleChange}
          />
          <CheckBoxField
            label="Active"
            id="active"
            name="active"
            onChange={handleChange}
            checked={active}
          />
        </div>
        <div className="py-4">
          <Label>Job Description</Label>
          <TextEditor
            dataHtml={(html) => {
              setJobData({ ...jobData, job_discription: html });
            }}
          />
        </div>
        <SubmitButton>Submit</SubmitButton>
      </form>
    </PageLayout>
  );
};
export default CreateJob;
