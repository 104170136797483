const LengthPerPage = ({
  rowsPerpage,
  usersPerPage,
  handleChangeRowsPerPage,
}) => {
  let disable = false;

  if (rowsPerpage > 9) {
    disable = false;
  } else {
    disable = true;
  }

  return (
    <>
      <div>
        <span className="mr-3">Rows per page:</span>
        <select
          disabled={disable}
          value={usersPerPage}
          onChange={handleChangeRowsPerPage}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white active:border-none p-1.5"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="100">100</option>
        </select>
      </div>
    </>
  );
};

export default LengthPerPage;
