import {
  FormGrid,
  SelectField,
  SubmitButton,
  TextField,
} from "components/Fields";
import PageLayout from "components/PageLayout";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import client from "utils/api-client";

const CreateRoles = () => {
  let navigate = useNavigate();
  const [createRoleData, setCreateRoleData] = useState({
    name: "",
    role: "ADMIN",
    email: "",
    password: "",
  });

  const { name, role, email, password } = createRoleData;

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setCreateRoleData({ ...createRoleData, [name]: value });
  };

  function handleRoleSubmit(e) {
    e.preventDefault();

    client(`admin/create`, { body: createRoleData }).then(
      (data) => {
        //   setCreateLoading(false);
        navigate("/roles");
        console.log(data);
      },
      (err) => {
        //   setCreateError(err);
        //   setCreateLoading(false);
        console.log(err);
      }
    );
  }

  return (
    <PageLayout title="Create Role">
      <form onSubmit={handleRoleSubmit}>
        <FormGrid>
          <TextField
            label="Name"
            id="Name"
            name="name"
            value={name}
            onChange={handleChange}
          />

          <SelectField
            label="Role"
            id="role"
            name="role"
            value={role}
            onChange={handleChange}
          >
            <option value="ADMIN">ADMIN</option>
            <option value="MANAGER">MANAGER</option>
            <option value="SUPPORT">SUPPORT</option>
            <option value="CONTENT_MANAGER">CONTENT_MANAGER</option>
            <option value="TEL_SUPPORT">TEL_SUPPORT</option>
          </SelectField>

          <TextField
            label="Email"
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={handleChange}
          />
          <TextField
            label="Password"
            id="Password"
            name="password"
            value={password}
            onChange={handleChange}
          />
        </FormGrid>
        <SubmitButton
          type="submit"
          // disabled={loading}
        >
          {/* {loading ? <>Submitting...</> : <>Submit</>} */}
          Submit
        </SubmitButton>
      </form>
    </PageLayout>
  );
};

export default CreateRoles;
