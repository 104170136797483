import { useAuth } from "context/AuthProvider";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useLogin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const location = useLocation();
  const { dispatch } = useAuth();
  const from = location.state?.from?.pathname || "/";

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/signIn`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, password: password }),
      }
    );

    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(json.error);
    }

    if (response.ok) {
      localStorage.setItem("token", JSON.stringify(json.user.token));
      localStorage.setItem("roles", JSON.stringify([json.user.role]));

      dispatch({
        type: "LOGIN",
        payload: json.user.token,
        roles: [json.user.role],
      });

      navigate(from, { replace: true });

      setIsLoading(false);
    }
  };

  return { login, isLoading, error };
};
