import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import usePathNames from "hooks/usePathnames";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import client from "utils/api-client";

const Subjects = () => {
  const { uniId, courseId, moduleId } = useParams();
  const pathnames = usePathNames();

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["allSubjects"],
    queryFn: () => client(`subject/allSubject/${courseId}`),
  });

  return (
    <>
      <div className="mb-6 flex flex-col gap-4 md:flex-row md:justify-between md:items-end">
        <div>
          <h1 className="text-4xl">All Subjects</h1>
        </div>
        <div className="flex gap-6 sm:gap-4">
          <Link
            to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/subjects/importSubjects`}
            className="border px-6 py-2 rounded-lg w-fit"
          >
            Import Subjects
          </Link>

          <Link
            to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/subjects/createSubjects`}
            className="border px-6 py-2 rounded-lg w-fit"
          >
            Create Subjects +
          </Link>
        </div>
      </div>

      {isError ? (
        <div className="text-red-400">
          <p>There was an error:</p>
          <pre>{error.message}</pre>
        </div>
      ) : null}

      <DataTable
        rows={data?.subjectDoc}
        columns={columns}
        deleteUrl={`subject/delete`}
        queryKey={["allSubjects"]}
        editTo={``}
        loading={isLoading}
      />
    </>
  );
};

export default Subjects;

const columns = [
  {
    label: "Icon",
    field: "image",
  },
  {
    label: "Subject Name",
    field: "name",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
