import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import { useParams } from "react-router-dom";
import client from "utils/api-client";

const Courses = () => {
  const { uniId } = useParams();

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["course", { uniId }],
    queryFn: () => client(`course/allCourses/${uniId}`),
  });

  return (
    <>
      <PageLayout
        title="All Courses"
        data={`Total Courses: ${data ? data.length : ""}`}
        buttonTitle="Add Course +"
        linkTo={`createCourse`}
        breadcrumbs={true}
      >
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}
        <DataTable
          columns={columns2}
          rows={data && data}
          editTo={`editCourse`}
          deleteUrl={`course/delete`}
          queryKey={["course", { uniId }]}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default Courses;

const columns2 = [
  {
    label: "Course Icon",
    field: "image",
  },
  {
    label: "Name",
    field: "course_name",
  },
  {
    label: "Course Type",
    field: "course_type_name",
  },
  {
    label: "Modules",
    field: "course_name",
  },

  { label: "Paid", field: "paid" },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
