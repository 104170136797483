import {
  TextField,
  SelectField,
  SubmitButton,
  DateTimeField,
} from "components/Fields";
import PageLayout from "components/PageLayout";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import client from "utils/api-client";
import format from "date-fns/format";
import { toast } from "react-toastify";

const EditCoupon = () => {
  const navigate = useNavigate();
  const { couponId } = useParams();
  const [promoData, setPromoData] = useState({
    promo: "",
    validity: "",
    promo_for: "all",
    label: "UNI",
    active_date: "",
    discount: "",
    promo_type: null,
    minAmount: "",
    maxDiscount: "",
    uniId: "",
    courseId: "",
  });

  const [allUni, setAllUni] = useState(null);
  const [allCourses, setAllCourses] = useState(null);

  const {
    promo,
    validity,
    promo_for,
    active_date,
    discount,
    promo_type,
    minAmount,
    maxDiscount,
    uniId,
    courseId,
  } = promoData;

  useEffect(() => {
    Promise.all([
      client(`promo/promo/${couponId}`),
      client(`uni/getAllUniversity?label=ALL`),
      uniId && client(`course/allCourses/${uniId}`),
    ]).then(
      (res) => {
        console.log(res);
        setPromoData(res[0].promoDoc);
        setAllUni(res[1].university);
        uniId && setAllCourses(res[2]);
      },
      (error) => {
        console.log(error);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }, [couponId, uniId]);

  function submitForm(e) {
    e.preventDefault();

    let data = {};

    if (courseId !== "" && promo_for !== "all") {
      data = {
        promo,
        validity,
        promo_for,
        active_date,
        discount,
        promo_type,
        minAmount,
        maxDiscount,
        university: uniId,
        course: courseId,
      };
    } else if (uniId !== "" && promo_for !== "all") {
      data = {
        promo,
        validity,
        promo_for,
        active_date,
        discount,
        promo_type,
        minAmount,
        maxDiscount,
        university: uniId,
      };
    } else {
      data = {
        promo,
        validity,
        promo_for,
        active_date,
        discount,
        promo_type,
        minAmount,
        maxDiscount,
      };
    }

    client(`promo/edit/${couponId}`, {
      body: data,
    })
      .then((res) => {
        console.log(res);
        navigate("/Coupons");
      })
      .catch((err) => console.log(err));
  }

  function handleChange(e) {
    setPromoData({ ...promoData, [e.target.name]: e.target.value });
  }

  return (
    <PageLayout title="Edit Coupon">
      <form onSubmit={submitForm}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <TextField
            label="Promo Name"
            id="name"
            name="promo"
            value={promoData?.promo}
            onChange={handleChange}
          />

          <DateTimeField
            label="Validity"
            id="Validity"
            name="validity"
            value={
              promoData.validity &&
              format(new Date(promoData?.validity), "yyyy-MM-dd'T'hh:mm")
            }
            onChange={handleChange}
          />

          <SelectField
            label="Promo For"
            id="promo_for"
            name="promo_for"
            onChange={handleChange}
            value={promoData?.promo_for}
          >
            <option value="all">All</option>
            <option value="university">University</option>
            <option value="course">Course</option>
          </SelectField>

          {/* {(promoData?.promo_for === "university" ||
            promoData?.promo_for === "course") && (
            <SelectField
              label="Label"
              id="label"
              name="label"
              onChange={handleChange}
            >
              <option value="UNI">University</option>
              <option value="BOARD">Boards</option>
              <option value="GOVT">Government Exam</option>
              <option value="ENTRANCE">Entrance</option>
            </SelectField>
          )} */}

          {(promoData?.promo_for === "university" ||
            promoData?.promo_for === "course") && (
            <SelectField
              label="Select University"
              id="uni"
              name="uniId"
              value={promoData?.university}
              onChange={handleChange}
            >
              <option value="">------Select University------</option>
              {allUni?.map((uni) => {
                return (
                  <option key={uni._id} value={uni._id}>
                    {uni.name}
                  </option>
                );
              })}
            </SelectField>
          )}

          {promoData?.promo_for === "course" && (
            <SelectField
              label="Select Course"
              id="course"
              name="courseId"
              value={promoData?.course}
              onChange={handleChange}
            >
              <option value="">------Select Courses------</option>
              {allCourses?.map((course) => {
                return (
                  <option key={course._id} value={course._id}>
                    {course.course_name}
                  </option>
                );
              })}
            </SelectField>
          )}

          <DateTimeField
            label="Active Date"
            id="active_date"
            name="active_date"
            value={
              promoData.active_date &&
              format(new Date(promoData?.active_date), "yyyy-MM-dd'T'hh:mm")
            }
            onChange={handleChange}
          />

          <TextField
            label="Discount"
            id="dicount"
            name="discount"
            type="number"
            value={promoData?.discount}
            onChange={handleChange}
          />

          <SelectField
            label="Promo Type"
            id="promo_type"
            name="promo_type"
            value={promoData?.promo_type}
            onChange={handleChange}
          >
            <option>------Select Discount Type------</option>
            <option>Flat</option>
            <option>Percentage</option>
          </SelectField>

          <TextField
            label="Min Amount"
            id="minAmount"
            name="minAmount"
            type="number"
            value={promoData?.minAmount}
            onChange={handleChange}
          />
          <TextField
            label="Max Discount"
            id="maxDiscount"
            name="maxDiscount"
            type="number"
            value={promoData?.maxDiscount}
            onChange={handleChange}
          />
        </div>
        <SubmitButton>Submit</SubmitButton>
      </form>
    </PageLayout>
  );
};

export default EditCoupon;
