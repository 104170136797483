import { useState } from "react";

function useUploadFile() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [inputValue, setInputValue] = useState("");

  async function handleImageUpload(e) {
    var file = e.target.files[0];

    if (file.size >= 1048576) {
      setError("Max File size is 1MB");
      throw new Error("Max File size is 1MB");
    } else {
      setLoading(true);
      setInputValue(e.target.value);
      const data = new FormData();
      data.append("image", file);

      return await fetch(`${process.env.REACT_APP_API_URL}/upload/image`, {
        method: "POST",
        headers: {
          "x-access-token": JSON.parse(window.localStorage.getItem("user")),
        },
        body: data,
      })
        .then((response) => {
          setLoading(false);
          setUploadResponse(URL.createObjectURL(file));
          setError(null);
          return response.json();
        })
        .catch((err) => {
          setLoading(false);
          setUploadResponse(null);
          setInputValue("");
          setError(err.message);
          throw Error("could not fetch the data for that resource");
        });
    }
  }

  return { loading, error, handleImageUpload, inputValue, uploadResponse };
}

export default useUploadFile;
