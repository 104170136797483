import {
  CheckBoxField,
  FormGrid,
  SubmitButton,
  TextField,
} from "components/Fields";
import usePathNames from "hooks/usePathnames";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import client from "utils/api-client";

const EditSemester = () => {
  const { semesterId, courseId, moduleId, uniId } = useParams();
  let navigate = useNavigate();
  const pathname = usePathNames();
  const [semesterData, setSemesterData] = useState();

  useEffect(() => {
    client(`module/getSemesterById/${semesterId}`).then(
      (data) => {
        setSemesterData(data.semesterDoc);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [semesterId]);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setSemesterData({ ...semesterData, [name]: value });
  };

  function handleSubmit(e) {
    e.preventDefault();

    delete semesterData.__v;
    delete semesterData.moduleId;
    delete semesterData.course;

    client(`module/editSemester`, { body: semesterData }).then(
      (data) => {
        console.log(data);
        navigate(
          `/${pathname}/${uniId}/${courseId}/${moduleId}/createContent/semesters`,
          {
            replace: true,
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  return (
    <>
      <h1 className="text-4xl">Edit Semester</h1>

      <form className="mt-5" onSubmit={handleSubmit}>
        <FormGrid>
          <TextField
            label="Semester Name"
            name="name"
            id="SemesterName"
            value={semesterData?.name}
            onChange={handleChange}
          />
          <CheckBoxField
            label="Active On Mobile"
            name="active"
            id="isActive"
            checked={semesterData?.active}
            onChange={handleChange}
          />
          <CheckBoxField
            id="showOnWeb"
            label="Active On Web"
            name="showOnWeb"
            checked={semesterData?.showOnWeb}
            onChange={handleChange}
          />
        </FormGrid>
        <SubmitButton>Submit</SubmitButton>
      </form>
    </>
  );
};

export default EditSemester;
