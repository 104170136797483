import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment } from "react";

export default function PopoverExample({ children }) {
  return (
    <div className="w-full max-w-sm relative">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-lg bg-blue-700 px-3.5 py-3 text-base font-medium text-white focus-within:outline-none`}
            >
              <span>Import</span>
              <ChevronDownIcon
                className={`${open ? "rotate-0" : "text-opacity-70 -rotate-90"}
                  ml-2 h-5 w-5 text-white-300 transition duration-150 ease-in-out group-hover:text-opacity-100 group-hover:translate-x-1`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-3 w-52 max-w-xs px-4 sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black dark:ring-slate-600 ring-opacity-5 dark:ring-opacity-100">
                  <div className="relative grid gap-1 bg-white dark:bg-slate-900 text-slate-900 dark:text-white p-2">
                    {children}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
