import DashBoard from "pages/Dashboard";
import CreateHomeBanner from "pages/homeBanner/CreateHomeBanner";
import HomeBanner from "pages/homeBanner/HomeBanner";
import Coupons from "pages/coupon/Coupons";
import Login from "pages/Login";
import Query from "pages/Query";
import Transactions from "pages/Transactions";
import University from "pages/university";
import CreateModule from "pages/university/module/CreateModule";
import Courses from "pages/university/course/Courses";
import CreateCourse from "pages/university/course/CreateCourse";
import CreateUniversity from "pages/university/CreateUniversity";
import EditModule from "pages/university/module/EditModule";
import EditUniversity from "pages/university/EditUniversity";
import Modules from "pages/university/module/Modules";
import Users from "pages/users";
import UserDetail from "pages/users/UserDetail";
import EditCourse from "pages/university/course/EditCourse";
import PaymentPlans from "pages/paymentPlans/PaymentPlans";
import CreatePaymentPlan from "pages/paymentPlans/CreatePaymentPlan";
import EditPaymentPlans from "pages/paymentPlans/EditPaymentPlans";
import ManageContent from "pages/university/content/ManageContent";
import CreateContent from "pages/university/content/CreateContent";
import EditContent from "pages/university/content/EditContent";
import Subjects from "pages/university/content/subjects/Subjects";
import Semesters from "pages/university/content/semesters/Semesters";
import CreateSemesters from "pages/university/content/semesters/CreateSemesters";
import CreateSubjects from "pages/university/content/subjects/CreateSubjects";
import PdfView from "pages/university/content/PdfView";
import CreateCoupon from "pages/coupon/CreateCoupon";
import AddBanner from "pages/university/AddBanner";
import AddCourseBanner from "pages/university/course/AddCourseBanner";
import TimeBanner from "pages/timeBanner/TimeBanner";
import CreateTimeBanner from "pages/timeBanner/CreateTimeBanner";
import EditTimeBanner from "pages/timeBanner/EditTimeBanner";
import EditSemester from "pages/university/content/semesters/EditSemester";
import EditSubject from "pages/university/content/subjects/EditSubject";
import ImportSubjects from "pages/university/content/subjects/ImportSubjects";
import EditHomeBanner from "pages/homeBanner/EditHomeBanner";
import EditCoupon from "pages/coupon/EditCoupon";
import Assets from "pages/asset/Assets";
import CreateAsset from "pages/asset/CreateAsset";
import Board from "pages/board/Board";
import GovtExam from "pages/govtExam/GovtExam";
import EntranceExam from "pages/entranceExam/EntranceExam";
import Unauthorized from "pages/Unauthorized";
import Roles from "pages/roles/Roles";
import CreateRoles from "pages/roles/CreateRoles";
import ImportPage from "pages/university/content/import/ImportPage";
import ImportPageContent from "pages/university/content/import/ImportPageContent";
import Home from "pages/Home";
import Configs from "pages/Configs";
import ImportFromCourse from "pages/university/content/import/ImportFromCourse";
import Jobs from "pages/job/job";
import CreateJob from "pages/job/createJob";
import EditJobs from "pages/job/EditJob";
import Blogs from "pages/blogs/index";
import NewBlog from "pages/blogs/newBlog";
import EditBlog from "pages/blogs/editBlog";
import Flag from "pages/flags/Flag";
import AdminInfo from "pages/university/admissionInfo.jsx";
import UniInfo from "pages/university/uniInfo.jsx";
const privateRoutes = [
  { path: "/", element: <Home /> },
  //flagRoute
  { path: "/flag", element: <Flag /> },
  // Dashboard
  { path: "/dashboard", element: <DashBoard /> },

  // User
  { path: "/user", element: <Users /> },
  { path: "/user/:userId", element: <UserDetail /> },

  // University
  { path: "/university", element: <University /> },
  { path: "/university/createUniversity", element: <CreateUniversity /> },
  { path: "/university/editUni/:uniId", element: <EditUniversity /> },
  { path: "/university/editUni/:uniId/addBanner", element: <AddBanner /> },
  //Admission Info
  {
    path: "/university/editUni/:uniId/admissionInfo",
    element: <AdminInfo />,
  },
  //University Info
  {
    path: "/university/editUni/:uniId/info",
    element: <UniInfo />,
  },

  // University - Course
  { path: "/university/:uniId", element: <Courses /> },
  {
    path: "/university/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/university/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/university/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // University - Course - Modules
  {
    path: "/university/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/university/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/university/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // University - Course - Modules - Content
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/pdfView/:pdfId",
    element: <PdfView />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // University - Course - Modules - Content - Subjects
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // University - Course - Modules - Content - Semestes
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Board
  { path: "/board", element: <Board /> },
  { path: "/board/createUniversity", element: <CreateUniversity /> },
  { path: "/board/editUni/:uniId", element: <EditUniversity /> },
  { path: "/board/editUni/:uniId/addBanner", element: <AddBanner /> },
  //Admission Info
  {
    path: "/board/editUni/:uniId/admissionInfo",
    element: <AdminInfo />,
  },
  //University Info
  {
    path: "/board/editUni/:uniId/info",
    element: <UniInfo />,
  },

  // Board - Course
  { path: "/board/:uniId", element: <Courses /> },
  {
    path: "/board/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/board/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/board/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // Board - Course - Modules
  {
    path: "/board/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/board/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/board/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // Board - Course - Modules - Content
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/import/:importId",
    element: <ImportPageContent />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/pdfView/:pdfId",
    element: <PdfView />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // Board - Course - Modules - Content - Subjects
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // Board - Course - Modules - Content - Semestes
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Govt Exam
  { path: "/govt", element: <GovtExam /> },
  { path: "/govt/createUniversity", element: <CreateUniversity /> },
  { path: "/govt/editUni/:uniId", element: <EditUniversity /> },
  { path: "/govt/editUni/:uniId/addBanner", element: <AddBanner /> },
  //Admission Info
  {
    path: "/govt/editUni/:uniId/admissionInfo",
    element: <AdminInfo />,
  },
  //University Info
  {
    path: "/govt/editUni/:uniId/info",
    element: <UniInfo />,
  },
  // Govt - Course
  { path: "/govt/:uniId", element: <Courses /> },
  {
    path: "/govt/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/govt/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/govt/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // Govt - Course - Modules
  {
    path: "/govt/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/govt/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/govt/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // Govt - Course - Modules - Content
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/pdfView/:pdfId",
    element: <PdfView />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // Govt - Course - Modules - Content - Subjects
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // Govt - Course - Modules - Content - Semestes
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Entrance Exam
  { path: "/entrance", element: <EntranceExam /> },
  { path: "/entrance/createUniversity", element: <CreateUniversity /> },
  { path: "/entrance/editUni/:uniId", element: <EditUniversity /> },
  { path: "/entrance/editUni/:uniId/addBanner", element: <AddBanner /> },
  //Admission Info
  {
    path: "/entrance/editUni/:uniId/admissionInfo",
    element: <AdminInfo />,
  },
  //University Info
  {
    path: "/entrance/editUni/:uniId/info",
    element: <UniInfo />,
  },
  // Entrance - Course
  { path: "/entrance/:uniId", element: <Courses /> },
  {
    path: "/entrance/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/entrance/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/entrance/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // Entrance - Course - Modules
  {
    path: "/entrance/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/entrance/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/entrance/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // Entrance - Course - Modules - Content
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/pdfView/:pdfId",
    element: <PdfView />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // Entrance - Course - Modules - Content - Subjects
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // Entrance - Course - Modules - Content - Semestes
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Payment Plans
  { path: "/paymentPlans", element: <PaymentPlans /> },
  { path: "/paymentPlans/createPaymentPlan", element: <CreatePaymentPlan /> },
  {
    path: "/paymentPlans/editPaymentPlan/:paymentId",
    element: <EditPaymentPlans />,
  },

  // Home Banner
  { path: "/homeBanner", element: <HomeBanner /> },
  { path: "/homeBanner/createHomeBanner", element: <CreateHomeBanner /> },
  { path: "/homeBanner/editHomeBanner/:bannerId", element: <EditHomeBanner /> },

  // Time Banner
  { path: "/timeBanner", element: <TimeBanner /> },
  { path: "/timeBanner/createTimeBanner", element: <CreateTimeBanner /> },
  { path: "/timeBanner/:timeBannerId", element: <EditTimeBanner /> },

  // Transactions
  { path: "/transactions", element: <Transactions /> },

  // List Coupons
  { path: "/Coupons", element: <Coupons /> },
  { path: "/Coupons/createCoupon", element: <CreateCoupon /> },
  { path: "/Coupons/:couponId", element: <EditCoupon /> },

  // Assets
  { path: "/assets", element: <Assets /> },
  { path: "/assets/createAsset", element: <CreateAsset /> },

  // Query
  { path: "/query", element: <Query /> },

  // Roles
  { path: "/roles", element: <Roles /> },
  { path: "/roles/createRole", element: <CreateRoles /> },
  //List_Jobs
  { path: "/jobs", element: <Jobs /> },
  { path: "/jobs/edit/:id", element: <EditJobs /> },
  { path: "/jobs/create", element: <CreateJob /> },
  // { path: "/roles/createRole", element: <CreateRoles /> },
  // 404 page (not found)
  // { path: "*", element: <NotFound /> },

  { path: "/configs", element: <Configs /> },

  { path: "/blogs", element: <Blogs /> },
  { path: "/blogs/new", element: <NewBlog /> },
  { path: "/blogs/:id", element: <EditBlog /> },
];

const managerRoutes = [
  { path: "/", element: <Home /> },
  // User
  { path: "/user", element: <Users /> },
  { path: "/user/:userId", element: <UserDetail /> },

  // University
  { path: "/university", element: <University /> },
  { path: "/university/createUniversity", element: <CreateUniversity /> },
  { path: "/university/editUni/:uniId", element: <EditUniversity /> },
  { path: "/university/editUni/:uniId/addBanner", element: <AddBanner /> },

  // University - Course
  { path: "/university/:uniId", element: <Courses /> },
  {
    path: "/university/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/university/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/university/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // University - Course - Modules
  {
    path: "/university/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/university/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/university/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // University - Course - Modules - Content
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/pdfView/:pdfId",
    element: <PdfView />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // University - Course - Modules - Content - Subjects
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // University - Course - Modules - Content - Semestes
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Board
  { path: "/board", element: <Board /> },
  { path: "/board/createUniversity", element: <CreateUniversity /> },
  { path: "/board/editUni/:uniId", element: <EditUniversity /> },
  { path: "/board/editUni/:uniId/addBanner", element: <AddBanner /> },
  // Board - Course
  { path: "/board/:uniId", element: <Courses /> },
  {
    path: "/board/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/board/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/board/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // Board - Course - Modules
  {
    path: "/board/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/board/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/board/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // Board - Course - Modules - Content
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/pdfView/:pdfId",
    element: <PdfView />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // Board - Course - Modules - Content - Subjects
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // Board - Course - Modules - Content - Semestes
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Govt Exam
  { path: "/govt", element: <GovtExam /> },
  { path: "/govt/createUniversity", element: <CreateUniversity /> },
  { path: "/govt/editUni/:uniId", element: <EditUniversity /> },
  { path: "/govt/editUni/:uniId/addBanner", element: <AddBanner /> },
  // Govt - Course
  { path: "/govt/:uniId", element: <Courses /> },
  {
    path: "/govt/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/govt/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/govt/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // Govt - Course - Modules
  {
    path: "/govt/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/govt/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/govt/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // Govt - Course - Modules - Content
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/pdfView/:pdfId",
    element: <PdfView />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // Govt - Course - Modules - Content - Subjects
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // Govt - Course - Modules - Content - Semestes
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Entrance Exam
  { path: "/entrance", element: <EntranceExam /> },
  { path: "/entrance/createUniversity", element: <CreateUniversity /> },
  { path: "/entrance/editUni/:uniId", element: <EditUniversity /> },
  { path: "/entrance/editUni/:uniId/addBanner", element: <AddBanner /> },
  // Entrance - Course
  { path: "/entrance/:uniId", element: <Courses /> },
  {
    path: "/entrance/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/entrance/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/entrance/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // Entrance - Course - Modules
  {
    path: "/entrance/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/entrance/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/entrance/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // Entrance - Course - Modules - Content
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/pdfView/:pdfId",
    element: <PdfView />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // Entrance - Course - Modules - Content - Subjects
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // Entrance - Course - Modules - Content - Semestes
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Payment Plans
  { path: "/paymentPlans", element: <PaymentPlans /> },
  { path: "/paymentPlans/createPaymentPlan", element: <CreatePaymentPlan /> },
  {
    path: "/paymentPlans/editPaymentPlan/:paymentId",
    element: <EditPaymentPlans />,
  },

  // Home Banner
  { path: "/homeBanner", element: <HomeBanner /> },
  { path: "/homeBanner/createHomeBanner", element: <CreateHomeBanner /> },
  { path: "/homeBanner/editHomeBanner/:bannerId", element: <EditHomeBanner /> },

  // Time Banner
  { path: "/timeBanner", element: <TimeBanner /> },
  { path: "/timeBanner/createTimeBanner", element: <CreateTimeBanner /> },
  { path: "/timeBanner/:timeBannerId", element: <EditTimeBanner /> },

  // Transactions
  { path: "/transactions", element: <Transactions /> },

  // List Coupons
  { path: "/Coupons", element: <Coupons /> },
  { path: "/Coupons/createCoupon", element: <CreateCoupon /> },
  { path: "/Coupons/:couponId", element: <EditCoupon /> },

  // Assets
  { path: "/assets", element: <Assets /> },
  { path: "/assets/createAsset", element: <CreateAsset /> },

  // Query
  { path: "/query", element: <Query /> },

  // Roles
  { path: "/roles", element: <Roles /> },
  { path: "/blogs", element: <Blogs /> },
  { path: "/blogs/new", element: <NewBlog /> },
  { path: "/blogs/:id", element: <EditBlog /> },
];

const supportRoutes = [
  { path: "/", element: <Home /> },
  { path: "/query", element: <Query /> },
  { path: "/user", element: <Users /> },
  { path: "/transactions", element: <Transactions /> },
  { path: "/Coupons", element: <Coupons /> },
];

const contentManagerRoutes = [
  { path: "/", element: <Home /> },
  // { path: "/university", element: <University /> },
  // { path: "/board", element: <Board /> },
  // { path: "/entrance", element: <EntranceExam /> },
  // { path: "/govt", element: <GovtExam /> },
  // University
  { path: "/university", element: <University /> },
  { path: "/university/createUniversity", element: <CreateUniversity /> },
  { path: "/university/editUni/:uniId", element: <EditUniversity /> },
  { path: "/university/editUni/:uniId/addBanner", element: <AddBanner /> },
  //Jobs
  { path: "/jobs", element: <Jobs /> },
  // University - Course
  { path: "/university/:uniId", element: <Courses /> },
  {
    path: "/university/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/university/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/university/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // University - Course - Modules
  {
    path: "/university/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/university/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/university/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // University - Course - Modules - Content
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // University - Course - Modules - Content - Subjects
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // University - Course - Modules - Content - Semestes
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/university/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Board
  { path: "/board", element: <Board /> },
  { path: "/board/createUniversity", element: <CreateUniversity /> },
  { path: "/board/editUni/:uniId", element: <EditUniversity /> },
  { path: "/board/editUni/:uniId/addBanner", element: <AddBanner /> },
  // Board - Course
  { path: "/board/:uniId", element: <Courses /> },
  {
    path: "/board/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/board/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/board/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // Board - Course - Modules
  {
    path: "/board/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/board/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/board/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // Board - Course - Modules - Content
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // Board - Course - Modules - Content - Subjects
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // Board - Course - Modules - Content - Semestes
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/board/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Govt Exam
  { path: "/govt", element: <GovtExam /> },
  { path: "/govt/createUniversity", element: <CreateUniversity /> },
  { path: "/govt/editUni/:uniId", element: <EditUniversity /> },
  { path: "/govt/editUni/:uniId/addBanner", element: <AddBanner /> },
  // Govt - Course
  { path: "/govt/:uniId", element: <Courses /> },
  {
    path: "/govt/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/govt/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/govt/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // Govt - Course - Modules
  {
    path: "/govt/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/govt/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/govt/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // Govt - Course - Modules - Content
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // Govt - Course - Modules - Content - Subjects
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // Govt - Course - Modules - Content - Semestes
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/govt/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },

  // Entrance Exam
  { path: "/entrance", element: <EntranceExam /> },
  { path: "/entrance/createUniversity", element: <CreateUniversity /> },
  { path: "/entrance/editUni/:uniId", element: <EditUniversity /> },
  { path: "/entrance/editUni/:uniId/addBanner", element: <AddBanner /> },
  // Entrance - Course
  { path: "/entrance/:uniId", element: <Courses /> },
  {
    path: "/entrance/:uniId/createCourse",
    element: <CreateCourse />,
  },
  {
    path: "/entrance/:uniId/editCourse/:courseId",
    element: <EditCourse />,
  },
  {
    path: "/entrance/:uniId/editCourse/:courseId/addBanner",
    element: <AddCourseBanner />,
  },

  // Entrance - Course - Modules
  {
    path: "/entrance/:uniId/:courseId",
    element: <Modules />,
  },
  {
    path: "/entrance/:uniId/:courseId/createModule",
    element: <CreateModule />,
  },
  {
    path: "/entrance/:uniId/:courseId/editModule/:moduleId",
    element: <EditModule />,
  },

  // Entrance - Course - Modules - Content
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout",
    element: <ManageContent />,
  },
  // import
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/import",
    element: <ImportPage />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/import/:importId/:layout",
    element: <ImportPageContent />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/importFromCourse",
    element: <ImportFromCourse />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/createContent",
    element: <CreateContent />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/:layout/editContent/:layoutId",
    element: <EditContent />,
  },

  // Entrance - Course - Modules - Content - Subjects
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects",
    element: <Subjects />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects/importSubjects",
    element: <ImportSubjects />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects/:subjectId",
    element: <EditSubject />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/subjects/createSubjects",
    element: <CreateSubjects />,
  },
  // Entrance - Course - Modules - Content - Semestes
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/semesters",
    element: <Semesters />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/semesters/:semesterId",
    element: <EditSemester />,
  },
  {
    path: "/entrance/:uniId/:courseId/:moduleId/createContent/semesters/createSemester",
    element: <CreateSemesters />,
  },
  { path: "/homeBanner", element: <HomeBanner /> },
  { path: "/timeBanner", element: <TimeBanner /> },
  { path: "/assets", element: <Assets /> },
  { path: "/blogs", element: <Blogs /> },
  { path: "/blogs/new", element: <NewBlog /> },
  { path: "/blogs/:id", element: <EditBlog /> },
];

const tel_supportRoutes = [
  { path: "/", element: <Home /> },
  { path: "/query", element: <Query /> },
];

const notPrivateRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/unauthorized", element: <Unauthorized /> },
  // { path: "*", element: <NotFound /> },
];

export {
  privateRoutes,
  notPrivateRoutes,
  managerRoutes,
  supportRoutes,
  contentManagerRoutes,
  tel_supportRoutes,
};
