import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import client from "utils/api-client";

const University = () => {
  const { data, isError, error, isLoading } = useQuery({
    queryKey: ["uniUniData"],
    queryFn: () => client(`uni/getAllUniversity?label=UNI`),
  });

  return (
    <>
      <PageLayout
        title="Universities"
        data={`Total Universities: ${data ? data.data.length : ""}`}
        buttonTitle="Create +"
        linkTo="createUniversity"
        breadcrumbs={true}
      >
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}

        <DataTable
          rows={data?.data}
          columns={columns}
          deleteUrl={`uni/delete`}
          queryKey={["uniUniData"]}
          editTo={`editUni`}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default University;

const columns = [
  {
    label: "",
    field: "image",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "SubTitle",
    field: "subTitle",
  },
  {
    label: "Courses",
    field: "name",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
