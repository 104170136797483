import axios from "axios";
import {
  SelectField,
  ImageField,
  CheckBoxField,
  TextField,
  SubmitButton,
} from "components/Fields";
import usePathNames from "hooks/usePathnames";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const Layout2 = () => {
  let navigate = useNavigate();
  const pathnames = usePathNames();
  const { uniId, courseId, moduleId, layout, layoutId } = useParams();
  const [semesters, setSemesters] = useState(null);
  const [subjects, setSubjects] = useState(null);

  const [contentData, setContentData] = useState([
    {
      year: "",
      pdf: null,
      paid: false,
      showAd: false,
      active: false,
      showOnWeb: false,
    },
  ]);

  const [formData, setFormData] = useState({
    semesterId: "",
    subjectId: "",
  });

  const { semesterId, subjectId } = formData;

  useEffect(() => {
    Promise.all([
      client(`module/getSemester/${moduleId}`),
      client(`subject/allSubject/${courseId}`),
      client(`layout/${layout}/singleContent/${layoutId}`),
    ]).then(
      (res) => {
        setSemesters(res[0].semesterDoc);
        setSubjects(res[1].subjectDoc);
        setContentData(res[2].content);
        setFormData({
          semesterId: res[2]?.semester?._id,
          subjectId: res[2]?.subject?._id,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }, [courseId, moduleId, layout, layoutId]);

  function validateImage(i, e) {
    const file = e.target.files[0];

    if (file.type !== "application/pdf") {
      e.target.value = null;
      return alert("this file is not a pdf");
    } else {
      // check img size is less than 10mb
      if (file.size >= 1048576 * 10) {
        return alert("Max file size is 1MB");
      } else {
        const data = new FormData();
        data.append("pdf", file);
        try {
          axios.post(`upload/pdf`, data, {}).then((res) => {
            let newFormValues = [...contentData];
            newFormValues[i][e.target.name] = res.data.id;
            setContentData(newFormValues);
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  const handleNChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChange = (i, e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let newFormValues = [...contentData];
    newFormValues[i][name] = value;
    setContentData(newFormValues);
  };

  let addFormFields = () => {
    setContentData([
      ...contentData,
      {
        year: "",
        pdf: null,
        paid: false,
        showAd: false,
        active: false,
        showOnWeb: false,
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...contentData];
    newFormValues.pop();
    // newFormValues.splice(i, 1);
    setContentData(newFormValues);
  };

  function handleSubmit(e) {
    e.preventDefault();

    let newContent = contentData.map((cont) => {
      console.log(cont);
      if (typeof cont.pdf === "object") {
        cont.pdf = cont?.pdf?._id;
      }
      return {
        ...cont,
      };
    });

    client(`layout/LAYOUT_2/edit`, {
      body: {
        _id: layoutId,
        semester: semesterId,
        subject: subjectId,
        content: newContent,
      },
    })
      .then((res) => {
        navigate(`/${pathnames}/${uniId}/${courseId}/${moduleId}/${layout}`, {
          replace: true,
        });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div>
      <h1 className="text-4xl">Edit Content</h1>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <div>
            <SelectField
              label="Semsters"
              id="semesterId"
              name="semesterId"
              value={semesterId}
              onChange={handleNChange}
            >
              <option value="">------Select Semester------</option>
              {semesters?.map((sem) => (
                <option key={sem._id} value={sem._id}>
                  {sem.name}
                </option>
              ))}
            </SelectField>
            <Link
              to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/semesters`}
              className="text-blue-300"
            >
              Manage Semesters
            </Link>
          </div>
          <div>
            <SelectField
              label="Subjects"
              id="subjectId"
              name="subjectId"
              value={subjectId}
              onChange={handleNChange}
            >
              <option value="">------Select Subject------</option>
              {subjects?.map((sub) => (
                <option key={sub._id} value={sub._id}>
                  {sub.name}
                </option>
              ))}
            </SelectField>
            <Link
              to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/subjects`}
              className="text-blue-300"
            >
              Manage Subjects
            </Link>
          </div>
        </div>

        <div className="space-y-5">
          {contentData &&
            contentData.map((input, idx) => (
              <div
                key={idx}
                className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6 border-b pb-4 border-blue-400"
              >
                <TextField
                  label="Year"
                  id="Year"
                  name="year"
                  value={input.year}
                  onChange={(e) => handleChange(idx, e)}
                />
                <div className="flex">
                  <ImageField
                    label="Upload File (pdf only)"
                    id="file-upload"
                    name="pdf"
                    accept="application/pdf"
                    onChange={(e) => validateImage(idx, e)}
                  />

                  {input.pdf?.content ? (
                    <div>
                      {input.pdf?.content} <br />
                      <button
                        type="button"
                        className="text-blue-400"
                        onClick={() => {
                          let newFormValues = [...contentData];
                          newFormValues[idx]["pdf"] = null;
                          setContentData(newFormValues);
                        }}
                      >
                        remove file
                      </button>
                    </div>
                  ) : null}
                </div>

                <CheckBoxField
                  label="paid"
                  name="paid"
                  checked={input.paid}
                  onChange={(e) => handleChange(idx, e)}
                />
                <CheckBoxField
                  label="view content after ad"
                  name="showAd"
                  checked={input.showAd}
                  onChange={(e) => handleChange(idx, e)}
                />
                <CheckBoxField
                  label="Active On Mobile"
                  name="active"
                  checked={input.active}
                  onChange={(e) => handleChange(idx, e)}
                />
                <CheckBoxField
                  id="showOnWeb"
                  label="Active On Web"
                  name="showOnWeb"
                  checked={input.showOnWeb}
                  onChange={(e) => handleChange(idx, e)}
                />
              </div>
            ))}
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="flex gap-3">
            <div className="button-section">
              <button type="button" onClick={() => addFormFields()}>
                Add
              </button>
            </div>
            <div className="button-section">
              <button type="button" onClick={() => removeFormFields()}>
                Remove
              </button>
            </div>
          </div>
          <SubmitButton>Submit</SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default Layout2;
