import { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Tab } from "@headlessui/react";
import { SearchIcon, XIcon } from "@heroicons/react/outline";
import client from "utils/api-client";
import { CheckCircleIcon } from "@heroicons/react/solid";
import useUploadFile from "hooks/useUploadFile";
import Spinner from "./Spinner";
import useDebounceValue from "hooks/useDebounceValue";
import { useInfiniteQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FileDialog = ({ handleChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");

  let [categories] = useState({
    "My Assets": <AssetsPage setValue={setSelected} />,
    Upload: <UploadImagePage setValue={setSelected} />,
  });

  return (
    <div>
      <div>
        <p className="block mb-2 text-gray-900 dark:text-gray-300">
          Select Image
        </p>
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="text-sm text-grey-500
          mr-5 py-2 px-6
          rounded-full border-0
          font-medium
          bg-blue-50 text-blue-700
          hover:cursor-pointer hover:bg-amber-50
          hover:text-amber-700"
        >
          Upload File
        </button>
      </div>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center relative">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl max-h-[500px] rounded-2xl bg-white dark:bg-slate-900 text-slate-900 dark:text-white text-left align-middle shadow-xl transition-all relative overflow-hidden">
                  <div className="px-5 py-4 h-full">
                    <Dialog.Title
                      as="h3"
                      // className="text-xl font-medium leading-6 mb-2 text-blue-500"
                      className="text-3xl font-medium leading-6 mt-2"
                    >
                      Insert Files
                    </Dialog.Title>

                    <div className="w-full px-2 sm:px-0 mt-3">
                      <Tab.Group>
                        <Tab.List className="flex space-x-7 p-1 sticky top-0">
                          {Object.keys(categories).map((category) => (
                            <Tab
                              key={category}
                              className={({ selected }) =>
                                classNames(
                                  "w-fit whitespace-nowrap px-1 py-2.5 text-sm leading-5",
                                  selected
                                    ? "border-b-2 border-blue-500 font-bold"
                                    : "hover:border-b-2 border-blue-300"
                                )
                              }
                            >
                              {category}
                            </Tab>
                          ))}
                        </Tab.List>
                        <Tab.Panels className="mt-2 text-slate-900 overflow-y-scroll overflow-x-hidden h-[350px]">
                          {Object.values(categories).map(
                            (categoryData, idx) => (
                              <Tab.Panel key={idx}>{categoryData}</Tab.Panel>
                            )
                          )}
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setIsOpen(false);
                      setSelected("");
                    }}
                    className="absolute top-4 right-4 hover:dark:bg-slate-800 hover:outline hover:outline-1 rounded-full p-1 transition-all duration-200"
                  >
                    <XIcon className="w-6 h-6" />
                  </button>
                  <div className="bg-slate-100 dark:bg-slate-800 p-4 w-full h-16 sticky bottom-0">
                    <div className="flex gap-4">
                      <button
                        type="button"
                        disabled={selected === ""}
                        onClick={() => {
                          handleChange(selected);
                          setIsOpen(false);
                        }}
                        className="inline-flex items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 disabled:opacity-40"
                      >
                        Select
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200"
                        onClick={() => {
                          setIsOpen(false);
                          setSelected("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default FileDialog;

function AssetsPage({ setValue }) {
  const [selected, setSelected] = useState("");
  const [query, setQuery] = useState("");
  const debounceValue = useDebounceValue(query);
  const { ref, inView } = useInView();
  const {
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isError,
    error,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["allAssets", { debounceValue }],
    queryFn: ({ pageParam = 1 }) =>
      client(`asset/allAssets?page=${pageParam}&search=${debounceValue}`),
    getNextPageParam: (lastPage) => {
      const currentPage = Number(lastPage.page);
      const totalPages = lastPage.pages;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    staleTime: 1 * 60 * 1000,
    cacheTime: 1 * 60 * 1000,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return (
    <div className="h-full">
      <form className="mt-2 max-w-sm" autoComplete="off" role="search">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <SearchIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="search"
            id="search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-full pl-10 p-2 focus-visible:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </form>
      {isError ? (
        <div className="text-red-400">
          <p>There was an error:</p>
          <pre>{error.message}</pre>
        </div>
      ) : null}

      <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 my-5 h-full">
        {data?.pages.map((page, idx) => (
          <Fragment key={idx}>
            {page.assetsDoc.map((asset) => (
              <div key={asset._id}>
                <button
                  onClick={() => {
                    setValue(asset.path);
                    setSelected(asset.path);
                  }}
                >
                  <div
                    className={clsx(
                      "border-blue-400 rounded-lg relative hover:border-2 hover:border-opacity-50",
                      selected === asset.path
                        ? "border-2 hover:border-opacity-100"
                        : "border-0"
                    )}
                  >
                    <img
                      src={asset.path}
                      alt=""
                      className="w-20 h-auto m-1 rounded-md"
                    />
                    <CheckCircleIcon
                      className={clsx(
                        "w-5 h-5 text-blue-400 absolute -top-3 -right-3",
                        selected === asset.path ? "visible" : "hidden"
                      )}
                    />
                  </div>
                </button>
              </div>
            ))}
          </Fragment>
        ))}
      </div>
      <div ref={ref} className="col-span-full mb-16">
        {isFetchingNextPage || isLoading ? (
          <Spinner />
        ) : hasNextPage ? (
          <span className="bg-blue-600 text-sm px-4 py-1.5 rounded-full text-white">
            load more
          </span>
        ) : null}
      </div>
    </div>
  );
}

function UploadImagePage({ setValue }) {
  const { handleImageUpload, loading, error, inputValue } = useUploadFile();
  const [imageUrl, setImageUrl] = useState(null);

  function handleImageChange(event) {
    handleImageUpload(event).then(
      (data) => {
        console.log(data);
        setImageUrl(data.url);
        setValue(data.url);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  return (
    <div>
      {loading && "loading..."}
      {error && `${error}`}
      <div
        className={clsx(
          "relative mt-1 flex justify-center items-center rounded-md border-2 border-dashed border-slate-300 dark:border-slate-600 px-6 pt-5 pb-6 h-72 w-full",
          imageUrl === null ? "visible" : "hidden"
        )}
      >
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-400">
            <label
              htmlFor="file-upload"
              className="cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500"
            >
              <span>Upload a file</span>
              <input
                id="file-upload"
                name="image"
                type="file"
                accept="image/png, image/jpeg"
                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                value={inputValue}
                onChange={handleImageChange}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-400">PNG, JPG up to 1MB</p>
        </div>
      </div>
      <div
        className={clsx(
          "w-full flex justify-between bg-gray-100 p-2 rounded-lg",
          imageUrl === null ? "hidden" : "visible"
        )}
      >
        <div className="flex gap-3">
          <img src={imageUrl} alt="" className="w-28 h-auto m-1 rounded-md" />
          <p className="text-sm w-2/3">{imageUrl}</p>
        </div>
        <button
          className="mr-3"
          onClick={() => {
            setImageUrl(null);
            setValue("");
          }}
        >
          <XIcon className="w-5 h-5 text-black" />
        </button>
      </div>
    </div>
  );
}
