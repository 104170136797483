import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
// import client from "utils/api-client";
import { ReplyIcon, PaperAirplaneIcon } from "@heroicons/react/outline";

export default function ReplyButton({ rowid, url }) {
  let [isOpen, setIsOpen] = useState(false);

  //   function handleDelete(event) {
  //     const index = event.currentTarget.getAttribute("dataid");
  //     console.log(JSON.stringify({ id: index }));
  //     client(`${url}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ id: index }),
  //     })
  //       .then(() => {
  //         console.log("success");
  //         window.location.reload();
  //         closeModal();
  //       })
  //       .catch((err) => {
  //         console.log("somethin went wrong", err);
  //         closeModal();
  //       });
  //   }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div>
        <button
          type="button"
          onClick={openModal}
          className="text-green-600 p-2 bg-green-200 rounded-full active:brightness-110 hover:brightness-105"
        >
          <ReplyIcon className="w-4 h-4" />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-5" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Reply To User
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      type here to send response to help user
                    </p>
                  </div>

                  <div className="flex flex-col mt-3">
                    <form>
                      {/* <label
                        htmlFor="termsAndConditions"
                        className="text-black"
                      >
                        Reply Text
                      </label> */}
                      <textarea className="w-full h-32 border focus:border-green-100 rounded-lg px-2 py-2 mt-2 bg-transparent text-black" />
                    </form>
                  </div>

                  <div className="mt-4 flex gap-2">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-zinc-200 px-4 py-2 text-sm font-medium text-zinc-600 hover:bg-zinc-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-zinc-500 focus-visible:ring-offset-2"
                      dataid={rowid}
                      //   onClick={handleDelete}
                      onClick={closeModal}
                    >
                      close
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      <div className="flex items-center gap-2">
                        <span>Send</span>
                        <PaperAirplaneIcon className="w-4 h-4 rotate-90" />
                      </div>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
