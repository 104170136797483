import { useParams } from "react-router-dom";
import Spinner from "components/Spinner";
import { useEffect } from "react";
import client from "utils/api-client";
import { useAsync } from "utils/hooks";

const PdfView = () => {
  const { pdfId } = useParams();
  const { data, error, run, isLoading, isError, isSuccess } = useAsync();

  useEffect(() => {
    run(client(`layout/pdf/${pdfId}`));
  }, [pdfId, run]);

  return (
    <div className="h-full">
      {isError && (
        <div className="text-red-400">
          <p>There was an error:</p>
          <span className="text-red-400">{error.message}</span>
        </div>
      )}
      {isLoading ? <Spinner /> : null}
      <div className="relative w-full h-[75vh] p-8">
        <embed
          className="overflow-hidden border-0 self-center absolute top-0 left-0 w-full h-full"
          src={isSuccess ? `${data?.url}#toolbar=0` : null}
          frameBorder="0"
          title="adsf"
        />
      </div>
    </div>
  );
};

export default PdfView;
