// import { decryptlocalStorageData } from "./keyEnccryption";

const localStorageKey = "token";
// const encryptionKey = "s3cret1";

function client(endpoint, { body, ...customConfig } = {}) {
  const token = JSON.parse(window.localStorage.getItem(localStorageKey));
  // const decryptedData = decryptlocalStorageData(token, encryptionKey);
  const headers = {
    "content-type": "application/json",
    "x-access-token": token,
  };
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  return window
    .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        logout();
        window.location.assign("/login");
        return;
      }
      if (response.ok) {
        return await response.json();
      } else {
        const errorMessage = await response.text();
        return Promise.reject(new Error(errorMessage));
      }
    });
}

export default client;

function logout() {
  window.localStorage.removeItem(localStorageKey);
  localStorage.removeItem("roles");
}
