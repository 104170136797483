import { PencilAltIcon } from "@heroicons/react/outline";
import { BanIcon, MinusCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { Link } from "react-router-dom";
import noimage from "assets/no-image.png";
import clsx from "clsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function LinkButton({ to, title, ...props }) {
  return (
    <Link
      to={to}
      className="dark:bg-slate-700 p-3 rounded-md shadow-md"
      {...props}
    >
      {title}
    </Link>
  );
}

export const EditButton = ({ editTo }) => {
  const [id, setId] = useState(null);
  return (
    <Link
      onMouseEnter={(e) => {
        setId(e.target.parentNode.getAttribute("rowid"));
        console.log(e.target.parentNode);
      }}
      to={`/${editTo}/${id}`}
    >
      <div className="text-blue-600 p-2 bg-blue-200 rounded-full active:brightness-110 hover:brightness-105 w-fit">
        <PencilAltIcon className="w-5 h-5" />
      </div>
    </Link>
  );
};

export function BooleanButton({ value }) {
  const statusStyles = {
    true: "bg-green-200 text-green-600",
    false: "bg-red-200 text-red-600",
  };

  const dotStyles = {
    true: "bg-green-600",
    false: "bg-red-600",
  };

  return (
    <span
      className={classNames(
        statusStyles[value],
        "inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium capitalize"
      )}
    >
      <span
        className={classNames(
          dotStyles[value],
          "w-2 h-2 rounded-full bg-black mr-2"
        )}
      ></span>
      {value === false ? "false" : "true"}
    </span>
  );
}

export function ImageCircle({ value }) {
  const [mouseValue, setMouseValues] = useState({
    x: 0,
    y: 0,
  });
  const [mouse, setMouse] = useState(false);

  return (
    <div className="transition-all duration-500 ease-in-out">
      {value !== undefined ? (
        <div
          className="bg-white dark:bg-slate-700 shadow-xl rounded-full w-[52px] h-[52px] overflow-hidden dark:border-slate-700 border-2"
          onMouseOver={(e) => {
            setMouseValues({
              x: 0,
              y: 0,
            });
            setMouse(true);
          }}
          onMouseOut={() => setMouse(false)}
        >
          <img src={value} alt="" className="w-full h-full object-cover" />
        </div>
      ) : (
        <div className="w-[52px] h-[52px]">
          <img src={noimage} alt="" className="w-full h-full object-cover" />
        </div>
      )}
      <div
        className={clsx(
          mouse
            ? `visible fixed translate-x-[${mouseValue.x}px] translate-y-[${mouseValue.y}px]`
            : "hidden"
        )}
      >
        <img
          src={value}
          alt=""
          className="max-w-[200px] h-auto rounded-xl overflow-hidden object-cover"
        />
      </div>
    </div>
  );
}

export function StatusButton({ value }) {
  const statusStyles = {
    true: "bg-green-200 text-green-600",
    false: "bg-red-200 text-red-600",
  };

  const dotStyles = {
    true: "bg-green-600",
    false: "bg-red-600",
  };

  return (
    <div
      className={classNames(
        statusStyles[value],
        "inline-flex items-center px-2.5 py-1 rounded-full cursor-default text-xs font-medium capitalize"
      )}
    >
      <span
        className={classNames(
          dotStyles[value],
          "w-2 h-2 rounded-full bg-black mr-2"
        )}
      ></span>
      {value === false ? "Inactive" : "Active"}
    </div>
  );
}

export function RoleColors({ value }) {
  const statusStyles = {
    ADMIN: "text-[#0073ff]",
    MANAGER: "text-[#8cfbe3]",
    SUPPORT: "text-[#8444ff]",
    CONTENT_MANAGER: "text-[#f57fa7]",
    TEL_SUPPORT: "text-[#cccc66]",
  };
  const dotStyles = {
    ADMIN: "bg-[#0073ff]",
    MANAGER: "bg-[#8cfbe3]",
    SUPPORT: "bg-[#8444ff]",
    CONTENT_MANAGER: "bg-[#f57fa7]",
    TEL_SUPPORT: "bg-[#cccc66]",
  };

  return (
    <div
      className={classNames(
        statusStyles[value],
        "inline-flex items-center px-2.5 py-1 rounded-full cursor-default text-xs font-medium capitalize bg-white dark:bg-slate-800 shadow"
      )}
    >
      <span
        className={classNames(dotStyles[value], "w-2 h-2 rounded-full mr-2")}
      ></span>
      {value === "ADMIN"
        ? "Admin"
        : value === "MANAGER"
        ? "Manager"
        : value === "SUPPORT"
        ? "Support Manager"
        : value === "CONTENT_MANAGER"
        ? "Editor"
        : value === "TEL_SUPPORT"
        ? "Support Partner"
        : ""}
    </div>
  );
}

export function UserBlocked({ value }) {
  const statusStyles = {
    true: "text-red-600",
    false: "text-green-600",
  };

  return (
    <div
      className={classNames(
        statusStyles[value],
        "rounded-full hover:brightness-105 w-fit p-1"
      )}
    >
      {value === false ? (
        <BanIcon className="w-6 h-6" />
      ) : (
        <MinusCircleIcon className="w-6 h-6" />
      )}
    </div>
  );
}
