import PageLayout from "components/PageLayout";
import client from "utils/api-client";
import dummyImg from "assets/background-auth.jpg";
import { TrashIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "components/dataTable/Loading";
import NoDataFound from "components/dataTable/NoDataFound";
import LengthPerPage from "components/dataTable/LengthPerPage";
import NewPaging from "components/dataTable/NewPaging";
import { useState } from "react";
import SearchBar from "components/SearchBar";
import useDebounceValue from "hooks/useDebounceValue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const Assets = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [query, setQuery] = useState("");
  const debounceValue = useDebounceValue(query);

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["allAssets", { page, size, debounceValue }],
    queryFn: () =>
      client(
        `asset/allAssets?page=${page}&size=${size}&search=${debounceValue}`
      ),
    keepPreviousData: true,
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ index }) => {
      client("asset/delete", {
        body: { _id: index },
      });
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ["allAssets", { page, size, debounceValue }],
        });
      }, 100);
      toast("successfully deleted");
    },
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    },
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPage = (e) => {
    setSize(+e.target.value);
  };

  return (
    <PageLayout
      title="All Assets"
      buttonTitle="Add Assets +"
      linkTo="createAsset"
    >
      {isError ? (
        <div className="text-red-400">
          <p>There was an error:</p>
          <pre>{error.message}</pre>
        </div>
      ) : null}
      <NoDataFound length={data?.bannerDoc?.length} />
      <SearchBar query={query} onChange={(e) => setQuery(e.target.value)} />
      <div className="my-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center gap-6 relative">
        <Loading loading={isLoading} />

        {data?.assetsDoc?.map((asset) => (
          <div
            key={asset._id}
            className="border dark:border-slate-700 bottom-2 p-2.5 relative w-fit dark:bg-slate-800 bg-white rounded-2xl shadow-lg h-fit"
          >
            <img
              src={asset?.path}
              alt={dummyImg}
              className="rounded-xl h-48 w-full object-cover"
            />
            <button
              className="absolute -top-3 -right-3 text-red-400"
              onClick={() => {
                mutation.mutate({ index: asset._id });
              }}
            >
              <div className="text-slate-700 dark:text-slate-200 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow border dark:border-slate-600">
                <TrashIcon className="w-4 h-4 text-red-400" />
              </div>
            </button>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-4 md:justify-between md:flex-row">
        <LengthPerPage
          rowsPerpage={data?.assetsDoc?.length}
          usersPerPage={size}
          handleChangeRowsPerPage={handleRowsPerPage}
        />

        <NewPaging
          count={data?.pages}
          currentPage={page}
          HandleChange={handlePageChange}
        />
      </div>
    </PageLayout>
  );
};

export default Assets;
