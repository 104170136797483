import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import { useState } from "react";
import client from "utils/api-client";
import SearchBar from "components/SearchBar";
import useDebounceValue from "hooks/useDebounceValue";
import { useQuery } from "@tanstack/react-query";

const Index = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [query, setQuery] = useState("");
  const debounceValue = useDebounceValue(query);

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["user", { page, size, debounceValue }],
    queryFn: () =>
      client(`user/allUser?page=${page}&size=${size}&search=${debounceValue}`),
    keepPreviousData: true,
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPage = (e) => {
    setSize(+e.target.value);
  };

  return (
    <>
      <PageLayout
        title="All Users"
        data={`Total users: ${data?.total || data?.user?.length}`}
        breadcrumbs={true}
      >
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}

        <SearchBar query={query} onChange={(e) => setQuery(e.target.value)} />

        <DataTable
          columns={columns}
          rows={data?.user}
          paging={true}
          pageCount={data?.pages}
          theCurrentPage={page}
          onPageChange={handlePageChange}
          usersPerPage={size}
          handleChangeRowsPerPage={handleRowsPerPage}
          rowsPerpage={data?.user.length}
          deleteUrl={`user/delete`}
          queryKey={["user", { page, size, debounceValue }]}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default Index;

const columns = [
  // {
  //   label: "Id",
  //   field: "_id",
  // },
  // {
  //   label: "uuid",
  //   field: "uuid",
  // },
  {
    label: "SrNo",
    field: "SrNo",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "Login Method",
    field: "method",
  },
  {
    label: "Device",
    field: "device",
  },

  // {
  //   label: "Notification Token",
  //   field: "notification_token",
  // },
  {
    label: "Blocked",
    field: "block",
  },
  {
    label: "Created At",
    field: "created_at",
  },
  {
    label: "Profile",
    field: "name",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
