import { SubmitButton, TextField } from "components/Fields";
import FileDialog from "components/FileDialog";
import usePathNames from "hooks/usePathnames";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const CreateSubjects = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { courseId, uniId, moduleId } = useParams();
  const pathname = usePathNames();
  const [subjects, setSubjects] = useState([
    {
      name: "",
      image: null,
      course: [courseId],
    },
  ]);

  let handleChange = (i, e) => {
    let newFormValues = [...subjects];
    newFormValues[i][e.target.name] = e.target.value;
    setSubjects(newFormValues);
  };

  let addFormFields = () => {
    setSubjects([
      ...subjects,
      {
        name: "",
        course: [courseId],
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...subjects];
    newFormValues.splice(i, 1);
    setSubjects(newFormValues);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    await client(`subject/create`, { body: subjects })
      .then((res) => {
        setLoading(false);
        navigate(
          `/${pathname}/${uniId}/${courseId}/${moduleId}/createContent/subjects`,
          {
            replace: true,
          }
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div>
      <h1 className="text-4xl">Create Subjects</h1>
      <form className="mt-5" onSubmit={handleSubmit}>
        <div className="space-y-5">
          {subjects.map((input, idx) => (
            <div
              key={idx}
              className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6 border-b pb-4 border-blue-400"
            >
              <TextField
                label="Subject Name"
                id="SubjectName"
                name="name"
                value={input.name || ""}
                onChange={(e) => handleChange(idx, e)}
              />
              <div className="flex justify-between">
                <FileDialog
                  handleChange={(selected) => {
                    let newFormValues = [...subjects];
                    newFormValues[idx]["image"] = selected;
                    setSubjects(newFormValues);
                  }}
                />
                <img src={input?.image} alt="" className="max-w-[100px]" />
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="flex gap-3">
            <div className="button-section">
              <button type="button" onClick={() => addFormFields()}>
                Add
              </button>
            </div>
            <div className="button-section">
              <button type="button" onClick={() => removeFormFields()}>
                Remove
              </button>
            </div>
          </div>
          <SubmitButton disabled={loading}>
            {loading ? <span> Submitting...</span> : <span>Submit</span>}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default CreateSubjects;
