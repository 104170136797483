import clsx from "clsx";
import { SelectField } from "components/Fields";
import PageLayout from "components/PageLayout";
import usePathNames from "hooks/usePathnames";
import useQueryParams from "hooks/useQueryParams";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import client from "utils/api-client";

const ImportFromCourse = () => {
  const { uniId, moduleId, layout } = useParams();
  const pathnames = usePathNames();
  const [allUni, setAllUni] = useState(null);
  const [data, setData] = useState({
    newUniId: "",
    newCourseId: "",
  });
  const [allCourses, setAllCourses] = useState(null);

  const { newUniId, newCourseId } = data;

  let queryParams = useQueryParams();
  const copy = queryParams.get("copy")
    ? `?copy=${queryParams.get("copy")}`
    : ``;

  useEffect(() => {
    Promise.all([
      client(`uni/getAllUniversity?label=ALL`),
      newUniId && client(`course/allCourses/${newUniId}`),
    ]).then(
      (res) => {
        setAllUni(res[0].university);
        newUniId && setAllCourses(res[1]);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [newUniId]);

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  return (
    <PageLayout title="Import From Course">
      <div className="space-y-2">
        <SelectField
          label="Select University"
          id="uni"
          name="newUniId"
          value={newUniId}
          onChange={handleChange}
        >
          <option value="">------Select University------</option>
          {allUni?.map((uni) => {
            return (
              <option key={uni._id} value={uni._id}>
                {uni.name}
              </option>
            );
          })}
        </SelectField>
        <SelectField
          label="Select Course"
          id="course"
          name="newCourseId"
          value={newCourseId}
          onChange={handleChange}
        >
          <option value="">------Select Courses------</option>
          {allCourses?.map((course) => {
            return (
              <option key={course._id} value={course._id}>
                {course.course_name}
              </option>
            );
          })}
        </SelectField>

        <Link
          to={`/${pathnames}/${uniId}/${newCourseId}/${moduleId}/${layout}/import${copy}`}
          className={clsx(
            newCourseId === "" && "pointer-events-none",
            "inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          )}
        >
          Import
        </Link>
      </div>
    </PageLayout>
  );
};

export default ImportFromCourse;
