import PageLayout from "components/PageLayout";
import { useEffect } from "react";
import client from "utils/api-client";
import { useAsync } from "utils/hooks";
import dummyImg from "assets/background-auth.jpg";
import { TrashIcon, PencilAltIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "components/dataTable/Loading";
import NoDataFound from "components/dataTable/NoDataFound";
import { Link } from "react-router-dom";
import { StatusButton } from "components/dataTable/TableButtons";

const HomeBanner = () => {
  const { data, run, isLoading, error, isError } = useAsync();

  useEffect(() => {
    run(client(`banner/allBanner`));
  }, [run]);

  function deleteBanner(_id) {
    client("banner/delete", {
      body: { _id: _id },
    })
      .then(() => {
        console.log("success");
        run(client(`banner/allBanner`));
        toast("successfully deleted");
      })
      .catch((err) => {
        console.log("something went wrong", err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <>
      <PageLayout
        title="All Banners"
        buttonTitle="Create +"
        linkTo="/homeBanner/createHomeBanner"
      >
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}
        <NoDataFound length={data?.bannerDoc.length} />
        <div className="my-12 flex flex-wrap gap-6 justify-start relative">
          <Loading loading={isLoading} />
          {data?.bannerDoc?.map((banner) => (
            <div
              key={banner._id}
              className="border dark:border-slate-700 bottom-2 p-4 relative w-fit dark:bg-slate-800 bg-white rounded-xl shadow-lg h-fit max-w-lg"
            >
              <div>
                <h3 className="uppercase font-semibold text-sm text-gray-400">
                  Title
                </h3>
                <h2 className="font-medium mb-3">{banner.title}</h2>
              </div>
              <div className="mb-3">
                <img
                  src={banner.image_url ? banner.image_url : dummyImg}
                  alt=""
                  className="rounded-2xl h-48 w-full object-cover"
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="uppercase font-semibold text-sm text-gray-400">
                    Type
                  </h3>
                  <p>{banner.banner_type}</p>
                </div>
                <div>
                  <h3 className="uppercase font-semibold text-sm text-gray-400">
                    Label
                  </h3>
                  <p>{banner.label}</p>
                </div>
                {banner.course_id && (
                  <div>
                    <h3 className="uppercase font-semibold text-sm text-gray-400">
                      Course
                    </h3>
                    <p>{banner.course_id.name}</p>
                  </div>
                )}
                {banner.uni_id && (
                  <div>
                    <h3 className="uppercase font-semibold text-sm text-gray-400">
                      University
                    </h3>
                    <p className="text-ellipsis overflow-hidden">
                      {banner.uni_id.name}
                    </p>
                  </div>
                )}
                {/* <div>
                  <StatusButton value={banner.active} />
                </div> */}
                <div>
                  <h3 className="uppercase font-semibold text-sm text-gray-400">
                    Active On Mobile
                  </h3>
                  <div>
                    <StatusButton value={banner.active} />
                  </div>
                </div>
                <div>
                  <h3 className="uppercase font-semibold text-sm text-gray-400">
                    Active On Web
                  </h3>
                  <div>
                    <StatusButton value={banner.showOnWeb} />
                  </div>
                </div>
              </div>
              <button
                className="absolute -top-3 -right-3 text-red-400"
                onClick={() => deleteBanner(banner._id)}
              >
                <div className="text-slate-700 dark:text-slate-200 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow border dark:border-slate-600">
                  <TrashIcon className="w-4 h-4 text-red-400" />
                </div>
              </button>
              <Link
                className="absolute top-8 -right-3"
                to={`/homeBanner/editHomeBanner/${banner._id}`}
              >
                <div className="text-slate-700 dark:text-slate-200 p-2 bg-white dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105 w-fit shadow border dark:border-slate-600">
                  <PencilAltIcon className="w-4 h-4 text-blue-400" />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </PageLayout>
    </>
  );
};

export default HomeBanner;
