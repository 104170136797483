import { GiftIcon, PencilAltIcon } from "@heroicons/react/outline";
import format from "date-fns/format";
import { Link, useLocation, useParams } from "react-router-dom";
import NewPaging from "./NewPaging";
import DeleteButton from "./DeleteButton";
import LengthPerPage from "./LengthPerPage";
import ReplyButton from "./ReplyButton";
import TransactionStatusButton from "./TransactionStatusButton";
import {
  BooleanButton,
  ImageCircle,
  LinkButton,
  RoleColors,
  StatusButton,
  UserBlocked,
} from "./TableButtons";
import NoDataFound from "./NoDataFound";
import Loading from "./Loading";
import useQueryParams from "hooks/useQueryParams";
import AttachmentPopup from "./AttachmentPopup";

function flattenObj(value, currentKey) {
  let result = {};

  Object.keys(value).forEach((key) => {
    const tempKey = currentKey ? `${currentKey}.${key}` : key;

    if (value[key] === null || value[key] === undefined) {
      result[tempKey] = value[key];
    } else {
      if (typeof value[key] !== "object") {
        result[tempKey] = value[key];
      } else {
        result = { ...result, ...flattenObj(value[key], tempKey) };
      }
    }
  });

  return result;
}

const DataTable = ({
  rows,
  columns,
  paging,
  pageCount,
  theCurrentPage,
  onPageChange,
  usersPerPage,
  handleChangeRowsPerPage,
  rowsPerpage,
  deleteUrl,
  queryKey,
  children,
  editTo,
  transactionStatusUrl,
  nextRoute,
  loading,
}) => {
  const { pathname, state } = useLocation();
  const pathnames = pathname.split("/").filter(Boolean)[0];
  const newRors = rows?.map((value) => flattenObj(value));
  const { layout } = useParams();

  let queryParams = useQueryParams();

  const copy = queryParams.get("copy")
    ? `?copy=${queryParams.get("copy")}`
    : ``;

  return (
    <>
      {/* <div className="overflow-auto w-full max-h-[550px] md:max-h-[700px] relative shadow-md rounded-lg my-6"> */}
      <div className="overflow-x-auto overflow-y-hidden w-full shadow-md rounded-lg my-6 relative">
        <table className="w-full table text-sm text-gray-500 dark:text-gray-200 text-left rounded-lg">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-slate-700 dark:text-gray-400 sticky top-0">
            <tr>
              {columns?.map((i) => (
                <th key={i.label} className="py-3 px-6 whitespace-nowrap">
                  {i.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y dark:divide-slate-700">
            {newRors?.map((row, index) => (
              <tr key={index} className="bg-white dark:bg-gray-900">
                {columns.map((column, idx) => {
                  const value = row[column.field];
                  return (
                    <td key={idx} className="py-3 px-6 whitespace-nowrap">
                      {column.label === "Created At" ||
                      column.label === "Duration" ||
                      column.label === "Validity" ||
                      column.label === "Expire" ||
                      column.label === "Active Date" ? (
                        <>
                          {value
                            ? format(
                                new Date(value ? value : null),
                                "dd MMM yyyy hh:mm aaa"
                              )
                            : ""}
                        </>
                      ) : column.label === "Blocked" &&
                        typeof value === "boolean" ? (
                        <UserBlocked value={value} />
                      ) : column.label === "Status" ||
                        column.label === "Mobile Status" ||
                        column.label === "Web Status" ? (
                        <StatusButton value={value ? value : false} />
                      ) : column.label === "Role" ? (
                        <RoleColors value={value} />
                      ) : column.label === "Delete" ? (
                        <DeleteButton
                          url={deleteUrl}
                          queryKey={queryKey}
                          body={
                            layout === "LAYOUT_1"
                              ? { _id: value }
                              : layout === "LAYOUT_2"
                              ? { _id: row._id, content_id: value }
                              : { _id: value }
                          }
                        />
                      ) : column.label === "Transaction Status" ? (
                        <TransactionStatusButton
                          title={value.toLowerCase()}
                          rowid={row._id}
                          url={transactionStatusUrl}
                          queryKey={queryKey}
                        />
                      ) : column.label === "Courses" ? (
                        <LinkButton
                          to={`/${pathnames}/${row._id}`}
                          title="Courses"
                          state={{ name: `${pathnames}/${value}` }}
                        />
                      ) : column.label === "Profile" ||
                        column.label === "Edit" ? (
                        <Link
                          to={editTo ? `${editTo}/${row._id}` : `${row._id}`}
                          state={{ name: `${pathnames}/${value}` }}
                        >
                          <div className="text-blue-600 p-2 bg-blue-200 rounded-full active:brightness-110 hover:brightness-105 w-fit">
                            <PencilAltIcon className="w-5 h-5" />
                          </div>
                        </Link>
                      ) : column.field === "gift" ? (
                        <button className="text-green-600 p-2 bg-green-200 rounded-full active:brightness-110 hover:brightness-105">
                          <GiftIcon className="w-4 h-4" />
                        </button>
                      ) : column.field === "reply" ? (
                        <ReplyButton />
                      ) : column.label === "Modules" ? (
                        <LinkButton
                          to={`${row._id}`}
                          title="Manage Modules"
                          state={{
                            name: `${state?.name}/${value}`,
                          }}
                        />
                      ) : column.label === "Manage Content" ? (
                        <>
                          <LinkButton
                            to={`/${nextRoute}/${row._id}/${row.type}${copy}`}
                            title="Manage Content"
                            state={{ name: `${state?.name}/${value}` }}
                          />
                        </>
                      ) : column.label === "Attachment" ? (
                        <AttachmentPopup value={value} />
                      ) : column.field === "image" ? (
                        <ImageCircle value={value} />
                      ) : column.label === "Job Type" ? (
                        <>
                          {value === "INTERNSHIP" ? (
                            <span className="bg-purple-200 text-purple-600 inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium capitalize">
                              {value}
                            </span>
                          ) : value === "FULL_TIME" ? (
                            <span className="bg-orange-200 text-orange-600 inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium capitalize">
                              {value}
                            </span>
                          ) : value === "CONTRACT" ? (
                            <span className="bg-pink-200 text-pink-600 inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium capitalize">
                              {value}
                            </span>
                          ) : value === "PART_TIME" ? (
                            <span className="bg-orange-200 text-orange-600 inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium capitalize">
                              {value}
                            </span>
                          ) : null}
                        </>
                      ) : column.label === "Module Type" ? (
                        <>
                          {value === "LAYOUT_1" ? (
                            <span className="bg-purple-200 text-purple-600 inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium capitalize">
                              {value}
                            </span>
                          ) : value === "LAYOUT_2" ? (
                            <span className="bg-orange-200 text-orange-600 inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium capitalize">
                              {value}
                            </span>
                          ) : value === "LAYOUT_3" ? (
                            <span className="bg-pink-200 text-pink-600 inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium capitalize">
                              {value}
                            </span>
                          ) : null}
                        </>
                      ) : column.label === "Query" ? (
                        <div className="w-52 break-words">
                          <span className="whitespace-normal">{value}</span>
                        </div>
                      ) : column.label === "File" ? (
                        <>
                          {value ? (
                            <LinkButton to={`pdfView/${value}`} title="View" />
                          ) : (
                            <span className="dark:bg-slate-700 text-orange-500 font-bold p-3 rounded-md shadow-md">
                              no file
                            </span>
                          )}
                        </>
                      ) : typeof value === "boolean" ? (
                        <BooleanButton value={value} />
                      ) : (
                        value
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {children}
        <div className={loading ? "" : null}>
          <Loading loading={loading} />
        </div>
        <NoDataFound length={rows?.length} />
      </div>
      {paging ? (
        <div className="flex flex-col gap-4 md:justify-between md:flex-row">
          <LengthPerPage
            rowsPerpage={rowsPerpage}
            usersPerPage={usersPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <NewPaging
            count={pageCount}
            currentPage={theCurrentPage}
            HandleChange={onPageChange}
          />
        </div>
      ) : null}
    </>
  );
};

export default DataTable;
