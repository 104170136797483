import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import client from "utils/api-client";
import { useAsync } from "utils/hooks";
import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { SelectField, TextField } from "components/Fields";
import { UserCircleIcon } from "@heroicons/react/solid";
import { add, format } from "date-fns";
import { GiftIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";

function BlockUserButton({ value }) {
  const { userId } = useParams();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const statusStyles = {
    true: "bg-red-200 text-red-600",
    false: "bg-green-200 text-green-600",
  };

  async function blockUser() {
    await client(`user/block`, { body: { id: userId, block: !value } }).then(
      (data) => {
        console.log(data);
        // window.location.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  return (
    <button
      onClick={blockUser}
      className={classNames(
        statusStyles[value],
        "inline-flex items-center px-5 py-3 rounded-xl text-xs font-medium capitalize"
      )}
    >
      {value === false ? "Block User" : "UnBlock User"}
    </button>
  );
}

const UserDetail = () => {
  const { userId } = useParams();
  let [isOpen, setIsOpen] = useState(false);
  const [allUni, setAllUni] = useState(null);
  const [allCourses, setAllCourses] = useState(null);
  const [paymentPlans, setpaymentPlans] = useState(null);
  const [giftData, setGiftData] = useState({
    label: "UNI",
    status: "SUCCESS",
    uniId: null,
    course: "",
    plan: "",
    plan_id: "",
    payment_id: "",
    user: userId,
    duration: "12",
    amount: "1299",
    expire: "2026-01-08 00:00:00.000",
  });

  const { label, uniId, course, plan, plan_id, payment_id } = giftData;

  const { data, error, run, isLoading, isError, isSuccess } = useAsync();

  const [userTransactions, setUserTransactions] = useState(null);

  const plans = plan.split("/").filter(Boolean);

  useEffect(() => {
    run(client(`user/userProfile/${userId}`));
    Promise.all([
      client(`uni/getAllUniversity?label=${label}`),
      client(`course/paymentPlans`),
      client(`user/transaction/${userId}`),
    ]).then(
      (res) => {
        setAllUni(res[0].data);
        setpaymentPlans(res[1].pricing);
        setUserTransactions(res[2]);
      },
      (error) => {
        console.log(error);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }, [userId, run, label]);

  useEffect(() => {
    uniId &&
      client(`course/allCourses/${uniId}`).then(
        (res) => {
          setAllCourses(res);
        },
        (error) => {
          console.log(error);
          toast.error(`${error.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
  }, [uniId]);

  function handleChange(e) {
    setGiftData({ ...giftData, [e.target.name]: e.target.value });
  }

  const period = add(new Date(), { months: parseInt(plans[2]) });

  async function handleGiftSubmit() {
    client(`user/giftCourse`, {
      body: {
        status: "SUCCESS",
        course: course,
        plan: plans[0],
        plan_id: plan_id,
        payment_id: payment_id,
        user: userId,
        duration: plans[2],
        amount: plans[1],
        expire: format(period, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      },
    }).then(
      (data) => {
        console.log(data);
        setIsOpen(false);
      },
      (error) => {
        console.log(error);
        setIsOpen(false);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }

  return (
    <>
      <PageLayout
        title="User Profile"
        // description={data?.userDoc.name}
        breadcrumbs={true}
        buttonTwo={
          <>
            <BlockUserButton value={data?.userDoc.block} />
            <button
              type="button"
              onClick={() => setIsOpen(true)}
              className="text-purple-600 p-3 bg-purple-200 rounded-full active:brightness-110 hover:brightness-105"
            >
              <GiftIcon className="w-5 h-5" />
            </button>
          </>
        }
      >
        <div>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={() => setIsOpen(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-slate-900 text-slate-900 dark:text-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-green-300"
                      >
                        Gift Course
                      </Dialog.Title>
                      <div className="mt-2 space-y-2">
                        <SelectField
                          label="Label"
                          id="label"
                          name="label"
                          onChange={handleChange}
                        >
                          <option value="UNI">University</option>
                          <option value="BOARD">Boards</option>
                          <option value="GOVT">Government Exam</option>
                          <option value="ENTRANCE">Entrance</option>
                        </SelectField>

                        <SelectField
                          label="Select University"
                          id="uni"
                          name="uniId"
                          onChange={handleChange}
                        >
                          <option value="">
                            ------Select University------
                          </option>
                          {allUni?.map((uni) => {
                            return (
                              <option key={uni._id} value={uni._id}>
                                {uni.name}
                              </option>
                            );
                          })}
                        </SelectField>

                        <SelectField
                          label="Select Course"
                          id="course"
                          name="course"
                          value={course}
                          onChange={handleChange}
                        >
                          <option value="">------Select Courses------</option>
                          {allCourses?.map((course) => {
                            return (
                              <option key={course._id} value={course._id}>
                                {course.course_name}
                              </option>
                            );
                          })}
                        </SelectField>

                        <SelectField
                          label="Payment Plan Type"
                          id="PaymentPlanSelected"
                          name="plan_id"
                          value={plan_id}
                          onChange={handleChange}
                        >
                          <option value="">---Select Payment Plan---</option>
                          {paymentPlans?.map((plan) => (
                            <option key={plan._id} value={plan._id}>
                              {plan.planName}
                            </option>
                          ))}
                        </SelectField>

                        <SelectField
                          label="Plan"
                          id="PaymentPlanSelected"
                          name="plan"
                          // value={plan}
                          onChange={handleChange}
                        >
                          <option value={plan}>---Select Plan---</option>
                          {paymentPlans &&
                            plan_id &&
                            paymentPlans
                              .find((item) => item._id === plan_id)
                              ?.plan?.map((plan) => (
                                <option
                                  key={plan._id}
                                  value={`${plan._id}/${plan.amount}/${plan.duration}`}
                                >
                                  {plan.name}, amount:{plan.amount}, duration:
                                  {plan.duration}
                                </option>
                              ))}
                        </SelectField>

                        <TextField
                          label="Payment Id"
                          id="payment_id"
                          name="payment_id"
                          value={payment_id}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={handleGiftSubmit}
                        >
                          Gift
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>

        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}

        <div className="mt-6">
          {isSuccess ? (
            data?.userDoc ? (
              <>
                <div
                  // className="bg-white dark:bg-slate-800 shadow-xl p-6 text-left rounded-3xl max-w-2xl w-fit"
                  className="border dark:border-slate-700 bottom-2 p-4 pr-7 max-w-2xl dark:bg-slate-800 bg-white rounded-xl shadow-lg mx-auto"
                >
                  <div>
                    {data?.userDoc?.profile?.includes("upload") ? (
                      <UserCircleIcon className="w-20 h-20 mx-auto dark:text-slate-500" />
                    ) : (
                      <img
                        src={data?.userDoc?.profile}
                        alt=""
                        className="w-20 h-20 bg-slate-500 rounded-full mx-auto"
                      />
                    )}
                    <h3 className="text-center text-3xl mt-3">
                      {data?.userDoc.name}
                    </h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 my-4">
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          UUID
                        </h3>
                        <p className="truncate">{data?.userDoc.uuid}</p>
                      </div>
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          ID
                        </h3>
                        <p className="truncate">{data?.userDoc._id}</p>
                      </div>
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          Email
                        </h3>
                        <p className="truncate">{data?.userDoc.email}</p>
                      </div>
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          Method
                        </h3>
                        <p>{data?.userDoc.method}</p>
                      </div>
                      <div>
                        <h3 className="uppercase font-semibold text-sm text-gray-400">
                          Created At
                        </h3>
                        {data?.userDoc.created_at ? (
                          <p>
                            {format(
                              new Date(data?.userDoc.created_at),
                              "dd MMM yyyy hh:mm aaa"
                            )}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <p>No data found</p>
            )
          ) : null}
          <div>
            <h1 className="text-4xl mt-6">All Transations</h1>
            <DataTable
              columns={columns}
              rows={userTransactions}
              loading={isLoading}
            />
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default UserDetail;

const columns = [
  {
    label: "Id",
    field: "_id",
  },
  {
    label: "Course Id",
    field: "course",
  },
  {
    label: "Transaction Status",
    field: "status",
  },
  {
    label: "User's Id",
    field: "user",
  },

  // {
  //   label: "Period",
  //   field: "period",
  // },
  {
    label: "Amount",
    field: "amount",
  },
  {
    label: "Payment Id",
    field: "payment_id",
  },
  {
    label: "Created At",
    field: "created",
  },
];
