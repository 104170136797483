import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import PageLayout from "components/PageLayout";
import client from "utils/api-client";

const Board = () => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["boardUniData"],
    queryFn: () => client(`uni/getAllUniversity?label=BOARD`),
  });

  return (
    <>
      <PageLayout
        title="Board"
        data={`Total Board Universities: ${data ? data.data.length : ""}`}
        buttonTitle="Create +"
        linkTo="createUniversity"
        breadcrumbs={true}
      >
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}

        <DataTable
          rows={data?.data}
          columns={columns}
          deleteUrl={`uni/delete`}
          queryKey={["boardUniData"]}
          editTo={`editUni`}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default Board;

const columns = [
  {
    label: "",
    field: "image",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "SubTitle",
    field: "subTitle",
  },
  {
    label: "Courses",
    field: "name",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
