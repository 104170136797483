import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
import axios from "axios";
import {
  SelectField,
  ImageField,
  CheckBoxField,
  TextField,
  SubmitButton,
} from "components/Fields";
import usePathNames from "hooks/usePathnames";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const Layout3 = () => {
  const { uniId, courseId, moduleId, layout, layoutId } = useParams();
  let navigate = useNavigate();
  const pathnames = usePathNames();
  const [semesters, setSemesters] = useState(null);
  const [subjects, setSubjects] = useState(null);
  const [layoutData, setLayoutData] = useState();
  const [semSubData, setSemSubData] = useState({
    semesterId: "",
    subjectId: "",
  });

  useEffect(() => {
    Promise.all([
      client(`layout/${layout}/singleContent/${layoutId}`),
      client(`module/getSemester/${moduleId}`),
      client(`subject/allSubject/${courseId}`),
    ]).then(
      (res) => {
        setLayoutData(res[0].unit);
        setSemesters(res[1].semesterDoc);
        setSubjects(res[2].subjectDoc);
        setSemSubData({
          semesterId: res[0]?.semester?._id,
          subjectId: res[0]?.subject?._id,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }, [courseId, moduleId, layout, layoutId]);

  const { semesterId, subjectId } = semSubData;

  const handleSemSubChange = (e) => {
    setSemSubData({ ...semSubData, [e.target.name]: e.target.value });
  };

  let addUnit = () => {
    setLayoutData([
      ...layoutData,
      {
        unitName: "",
        content: [
          {
            year: "",
            pdf: null,
            paid: false,
            showAd: false,
            active: false,
            showOnWeb: false,
          },
        ],
      },
    ]);
  };

  let removeUnit = (i) => {
    let newFormValues = [...layoutData];
    newFormValues.pop();
    // newFormValues.splice(i, 1);
    setLayoutData(newFormValues);
  };

  function handleUnitnameChange(e, idx) {
    let newUnitValues = [...layoutData];
    newUnitValues[idx][e.target.name] = e.target.value;
    setLayoutData(newUnitValues);
  }

  let addContent = (idx) => {
    const newState = [...layoutData];
    newState[idx].content = [
      ...newState[idx].content,
      {
        year: "",
        pdf: null,
        paid: false,
        showAd: false,
        active: false,
        showOnWeb: false,
      },
    ];
    setLayoutData(newState);
  };

  let removeContent = (idx, i) => {
    let newFormValues = [...layoutData];
    newFormValues[idx].content.pop();
    // newFormValues[idx].content.splice(i, 1);
    setLayoutData(newFormValues);
  };

  function handleChangeInContent(e, idx, inIdx) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let newFormValues = [...layoutData];
    newFormValues[idx].content[inIdx][name] = value;
    setLayoutData(newFormValues);
  }

  function setPdfId(e, idx, inIdx, pdfId) {
    const newFmValues = [...layoutData];
    newFmValues[idx].content[inIdx][e.target.name] = pdfId;
    setLayoutData(newFmValues);
  }

  function handleUploadImage(e, idx, inIdx) {
    const file = e.target.files[0];

    if (file.type !== "application/pdf") {
      e.target.value = null;
      return alert("this file is not a pdf");
    } else {
      // check img size is less than 10mb
      if (file.size >= 1048576 * 10) {
        return alert("Max file size is 1MB");
      } else {
        const data = new FormData();
        data.append("pdf", file);
        try {
          axios.post(`upload/pdf`, data, {}).then((res) => {
            const pdfId = res.data.id;
            setPdfId(e, idx, inIdx, pdfId);
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    delete layoutData?.semester;
    delete layoutData?.subject;
    delete layoutData?.id;

    let newUnit = layoutData.map((lay) => {
      delete lay?.semester;
      delete lay?.subject;
      delete lay?._id;

      lay.content.map((laay) => {
        if (laay._id) {
          delete laay?._id;
        }
        if (typeof laay.pdf === "object") {
          laay.pdf = laay.pdf?._id;
        }
        return {
          ...laay,
        };
      });
      return {
        ...lay,
      };
    });

    console.log(newUnit);

    client(`layout/LAYOUT_3/edit`, {
      body: {
        semester: semesterId,
        subject: subjectId,
        id: layoutId,
        unit: newUnit,
      },
    })
      .then((res) => {
        navigate(`/${pathnames}/${uniId}/${courseId}/${moduleId}/${layout}`, {
          replace: true,
        });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div>
      <h1 className="text-4xl">Edit Content</h1>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <div>
            <SelectField
              label="Semsters"
              id="semesterId"
              name="semesterId"
              value={semesterId}
              onChange={handleSemSubChange}
            >
              <option value="">------Select Semester------</option>
              {semesters?.map((sem) => (
                <option key={sem._id} value={sem._id}>
                  {sem.name}
                </option>
              ))}
            </SelectField>
            <Link
              to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/semesters`}
              className="text-blue-300"
            >
              Manage Semesters
            </Link>
          </div>
          <div>
            <SelectField
              label="Subjects"
              id="subjectId"
              name="subjectId"
              value={subjectId}
              onChange={handleSemSubChange}
            >
              <option value="">------Select Subject------</option>
              {subjects?.map((sub) => (
                <option key={sub._id} value={sub._id}>
                  {sub.name}
                </option>
              ))}
            </SelectField>
            <Link
              to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/subjects`}
              className="text-blue-300"
            >
              Manage Subjects
            </Link>
          </div>
        </div>

        <div className="space-y-5 2xl:space-y-0 2xl:grid 2xl:grid-cols-2 2xl:gap-5 2xl:content-start">
          {layoutData &&
            layoutData.map((unitData, idx) => (
              <div
                key={idx}
                className="bg-white dark:bg-slate-800 shadow-lg rounded-lg p-6"
              >
                <div className="flex w-full items-center gap-4">
                  <TextField
                    label="Unit Name"
                    id="unitName"
                    name="unitName"
                    className="w-full"
                    value={unitData.unitName}
                    onChange={(e) => handleUnitnameChange(e, idx)}
                  />
                  <div className="flex gap-3 mt-6 items-center">
                    <div>
                      <button
                        className="text-gray-600 dark:text-white p-2.5 bg-gray-200 dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105"
                        type="button"
                        onClick={() => addContent(idx)}
                      >
                        <PlusIcon className="w-4 h-4" />
                      </button>
                    </div>
                    <h5>Content</h5>
                    <div>
                      <button
                        className="text-gray-600 dark:text-white p-2.5 bg-gray-200 dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105"
                        type="button"
                        onClick={() => removeContent(idx)}
                      >
                        <MinusIcon className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="divide-y divide-blue-500">
                  {unitData.content.map((contentData, inIdx) => (
                    <div
                      key={inIdx}
                      className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6 pt-6"
                      // className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6 border-b pb-4 border-blue-600"
                    >
                      <TextField
                        label="Year"
                        id="Year"
                        name="year"
                        value={contentData.year}
                        onChange={(e) => handleChangeInContent(e, idx, inIdx)}
                      />
                      <div className="flex">
                        <ImageField
                          label="Upload File (pdf only)"
                          id="file-upload"
                          name="pdf"
                          accept="application/pdf"
                          onChange={(e) => handleUploadImage(e, idx, inIdx)}
                        />
                        {contentData.pdf?.content ? (
                          <div>
                            {contentData.pdf?.content} <br />
                            <button
                              type="button"
                              className="text-blue-400"
                              onClick={() => {
                                const newFmValues = [...layoutData];
                                newFmValues[idx].content[inIdx]["pdf"] = null;
                                setLayoutData(newFmValues);
                              }}
                            >
                              remove file
                            </button>
                          </div>
                        ) : null}
                      </div>

                      <CheckBoxField
                        label="paid"
                        id="Paid"
                        name="paid"
                        checked={contentData.paid}
                        onChange={(e) => handleChangeInContent(e, idx, inIdx)}
                      />
                      <CheckBoxField
                        label="View Content After Ad"
                        id="showAfterAd"
                        name="showAd"
                        checked={contentData.showAd}
                        onChange={(e) => handleChangeInContent(e, idx, inIdx)}
                      />
                      <CheckBoxField
                        label="Active On Mobile"
                        name="active"
                        id="Active"
                        checked={contentData.active}
                        onChange={(e) => handleChangeInContent(e, idx, inIdx)}
                      />

                      <CheckBoxField
                        id="showOnWeb"
                        label="Active On Web"
                        name="showOnWeb"
                        checked={contentData?.showOnWeb}
                        onChange={(e) => handleChangeInContent(e, idx, inIdx)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="flex gap-3 items-center">
            <div>
              <button
                className="text-gray-600 dark:text-white p-2.5 bg-gray-200 dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105"
                type="button"
                onClick={() => addUnit()}
              >
                <PlusIcon className="w-5 h-5" />
              </button>
            </div>
            <h5 className="text-lg">Unit</h5>
            <div>
              <button
                className="text-gray-600 dark:text-white p-2.5 bg-gray-200 dark:bg-slate-700 rounded-full active:brightness-110 hover:brightness-105"
                type="button"
                onClick={() => removeUnit()}
              >
                <MinusIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
          <SubmitButton>Submit</SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default Layout3;
