import {
  FormGrid,
  TextField,
  DateTimeField,
  CheckBoxField,
  SubmitButton,
} from "components/Fields";
import PageLayout from "components/PageLayout";
import Spinner from "components/Spinner";
// import Spinner from "components/Spinner";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const CreateTimeBanner = () => {
  let navigate = useNavigate();
  const [timerData, setTimerData] = useState({
    timer_type: "",
    text: "",
    end_date: "",
    show: false,
  });
  const [loading, setLoading] = useState(false);
  const { timer_type, text, end_date, show } = timerData;

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setTimerData({ ...timerData, [name]: value });
  };

  function handleSubmit(e) {
    e.preventDefault();

    console.log(timerData);
    setLoading(true);
    client(`timer/create`, { body: timerData }).then(
      (data) => {
        setLoading(false);
        navigate("/timeBanner");
      },
      (err) => {
        setLoading(false);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }

  return (
    <PageLayout title="Create Time Bannner">
      <form onSubmit={handleSubmit}>
        <FormGrid>
          <TextField
            label="Timer Type"
            id="timeType"
            name="timer_type"
            value={timer_type}
            onChange={handleChange}
          />
          <TextField
            label="Timer Text"
            id="timerText"
            name="text"
            value={text}
            onChange={handleChange}
          />

          <DateTimeField
            label="End Date"
            id="endDate"
            name="end_date"
            value={end_date}
            onChange={handleChange}
          />

          <CheckBoxField
            label="Show"
            id="show"
            name="show"
            checked={show}
            onChange={handleChange}
          />
        </FormGrid>
        <SubmitButton disabled={loading}>
          {loading ? <Spinner /> : "Submit"}
        </SubmitButton>
      </form>
    </PageLayout>
  );
};

export default CreateTimeBanner;
