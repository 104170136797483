import axios from "axios";
import {
  SelectField,
  ImageField,
  CheckBoxField,
  SubmitButton,
} from "components/Fields";
import usePathNames from "hooks/usePathnames";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const Layout1 = () => {
  const navigate = useNavigate();
  const pathnames = usePathNames();
  const { uniId, courseId, moduleId, layout, layoutId } = useParams();
  const [semesters, setSemesters] = useState(null);
  const [subjects, setSubjects] = useState(null);

  const [contentData, setContentData] = useState();

  // const { semesterId, subjectId, subject, paid, showAd, active } = contentData;

  useEffect(() => {
    Promise.all([
      client(`module/getSemester/${moduleId}`),
      client(`subject/allSubject/${courseId}`),
      client(`layout/${layout}/singleContent/${layoutId}`),
    ]).then(
      (res) => {
        setSemesters(res[0].semesterDoc);
        setSubjects(res[1].subjectDoc);
        setContentData(res[2]);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [courseId, moduleId, layout, layoutId]);

  console.log(contentData);

  function handleSubmit(e) {
    e.preventDefault();

    delete contentData.__v;
    delete contentData.module;

    if (typeof contentData.pdf === "object") {
      contentData.pdf = contentData.pdf?._id;
    }
    if (typeof contentData.semester === "object") {
      contentData.semester = contentData.semester?._id;
    }
    if (typeof contentData.subject === "object") {
      contentData.subject = contentData.subject?._id;
    }

    client(`layout/LAYOUT_1/edit`, {
      body: contentData,
    })
      .then((res) => {
        console.log(res);
        navigate(`/${pathnames}/${uniId}/${courseId}/${moduleId}/${layout}`, {
          replace: true,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  function validateImage(e) {
    const file = e.target.files[0];

    if (file.type !== "application/pdf") {
      e.target.value = null;
      return alert("this file is not a pdf");
    } else {
      // check img size is less than 10mb
      if (file.size >= 1048576 * 10) {
        return alert("Max file size is 1MB");
      } else {
        const data = new FormData();
        data.append("pdf", file);
        try {
          axios.post(`upload/pdf`, data, {}).then((res) => {
            setContentData({
              ...contentData,
              pdf: res.data.id,
            });
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setContentData({ ...contentData, [name]: value });
  };

  return (
    <div>
      <h1 className="text-4xl">Edit Content</h1>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <div>
            <SelectField
              label="Semsters"
              name="semester"
              value={contentData?.semester?._id}
              onChange={handleChange}
            >
              <option value="">------Select Semester------</option>
              {semesters?.map((sem) => (
                <option key={sem._id} value={sem._id}>
                  {sem.name}
                </option>
              ))}
            </SelectField>
            <Link
              to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/semesters`}
              className="text-blue-300"
            >
              Manage Semesters
            </Link>
          </div>
          <div>
            <SelectField
              label="Subjects"
              name="subject"
              value={contentData?.subject?._id}
              onChange={handleChange}
            >
              <option value="">------Select Subject------</option>
              {subjects?.map((sub) => (
                <option key={sub._id} value={sub._id}>
                  {sub.name}
                </option>
              ))}
            </SelectField>
            <Link
              to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/subjects`}
              className="text-blue-300"
            >
              Manage Subjects
            </Link>
          </div>

          <div className="flex">
            <ImageField
              label="Upload File (pdf only)"
              id="file-upload"
              name="pdf"
              accept="application/pdf"
              onChange={validateImage}
            />
            {contentData?.pdf?.content}
            {/* {contentData?.pdf?.content ? (
              <div>
                {contentData.pdf?.content} <br />
                <button
                  type="button"
                  className="text-blue-400"
                  onClick={() => {
                    setContentData({ ...contentData, pdf: null });
                  }}
                >
                  remove file
                </button>
              </div>
            ) : null} */}
          </div>

          <CheckBoxField
            label="paid"
            name="paid"
            checked={contentData?.paid}
            onChange={handleChange}
          />
          <CheckBoxField
            label="view content after ad"
            name="showAd"
            checked={contentData?.showAd}
            onChange={handleChange}
          />
          <CheckBoxField
            label="Active On Mobile"
            name="active"
            checked={contentData?.active}
            onChange={handleChange}
          />
          <CheckBoxField
            id="showOnWeb"
            label="Active On Web"
            name="showOnWeb"
            checked={contentData?.showOnWeb}
            onChange={handleChange}
          />
        </div>
        <SubmitButton>Submit</SubmitButton>
      </form>
    </div>
  );
};

export default Layout1;
