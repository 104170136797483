import { useQuery } from "@tanstack/react-query";
import React from "react";
import client from "utils/api-client";
import { useParams } from "react-router-dom";
import PageLayout from "components/PageLayout";
import DataTable from "components/dataTable/DataTable";
import usePathNames from "hooks/usePathnames";

const ImportPage = () => {
  const { courseId, layout, uniId, moduleId } = useParams();
  const pathnames = usePathNames();

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["importPage", { courseId, layout }],
    queryFn: () => client(`layout/layout/${courseId}?layout_type=${layout}`),
  });

  return (
    <>
      <PageLayout title="Import">
        {isError ? (
          <div className="text-red-400">
            <p>There was an error:</p>
            <pre>{error.message}</pre>
          </div>
        ) : null}
        <DataTable
          columns={columns}
          rows={data?.module}
          nextRoute={`${pathnames}/${uniId}/${courseId}/${moduleId}/import`}
          loading={isLoading}
        />
      </PageLayout>
    </>
  );
};

export default ImportPage;

const columns = [
  {
    label: "Module Icon",
    field: "image",
  },
  {
    label: "Module Name",
    field: "name",
  },
  {
    label: "Module Type",
    field: "type",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Manage Content",
    field: "",
  },
];
