import { useQuery } from "@tanstack/react-query";
import DataTable from "components/dataTable/DataTable";
import usePathNames from "hooks/usePathnames";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import client from "utils/api-client";

const Semesters = () => {
  const { uniId, courseId, moduleId } = useParams();
  const pathnames = usePathNames();

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["allSemester"],
    queryFn: () => client(`module/getSemester/${moduleId}`),
  });

  return (
    <>
      <div className="mb-6 flex flex-col gap-4 md:flex-row md:justify-between md:items-end">
        <div>
          <h1 className="text-4xl">All Semester</h1>
        </div>

        <Link
          to={`/${pathnames}/${uniId}/${courseId}/${moduleId}/createContent/semesters/createSemester`}
          className="border px-6 py-2 rounded-lg w-fit"
        >
          Create Semesters +
        </Link>
      </div>

      {isError ? (
        <div className="text-red-400">
          <p>There was an error:</p>
          <pre>{error.message}</pre>
        </div>
      ) : null}

      <DataTable
        rows={data?.semesterDoc}
        columns={columns}
        deleteUrl={`module/semester/delete`}
        queryKey={["allSemester"]}
        editTo={``}
        loading={isLoading}
      />
    </>
  );
};

export default Semesters;

const columns = [
  {
    label: "Semester Name",
    field: "name",
  },
  {
    label: "Mobile Status",
    field: "active",
  },
  {
    label: "Web Status",
    field: "showOnWeb",
  },
  {
    label: "Edit",
    field: "edit",
  },
  {
    label: "Delete",
    field: "_id",
  },
];
