import client from "utils/api-client";
import { useEffect, useState } from "react";
import PageLayout from "components/PageLayout";
import { SelectField, SubmitButton, TextField } from "components/Fields";
import { toast } from "react-toastify";
import FileDialog from "components/FileDialog";
import { useNavigate } from "react-router-dom";

const CreateHomeBanner = () => {
  const [allUni, setAllUni] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allCourses, setAllCourses] = useState(null);
  let navigate = useNavigate();

  const [bannerData, setBannerData] = useState({
    image_url: "",
    url: "",
    title: "",
    label: "UNI_HOME",
    uniLabel: "UNI",
    uni_id: "",
    course_id: "",
    urlTitle: "",
    banner_type: "No Click",
  });

  const {
    url,
    title,
    uni_id,
    course_id,
    urlTitle,
    banner_type,
    label,
    uniLabel,
    image_url,
  } = bannerData;

  const handleChange = (e) => {
    setBannerData({ ...bannerData, [e.target.name]: e.target.value });
  };

  async function submitBannerForm(e) {
    e.preventDefault();

    setLoading(true);

    let data = {};

    if (banner_type === "Inside Course") {
      data = {
        image_url,
        banner_type,
        title,
        label,
        uni_id,
        course_id,
      };
    } else if (banner_type === "Inside University") {
      data = {
        image_url,
        banner_type,
        title,
        label,
        uni_id,
      };
    } else if (banner_type === "URL") {
      data = {
        image_url,
        banner_type,
        title,
        label,
        urlTitle,
        url,
      };
    } else {
      data = {
        image_url,
        banner_type,
        title,
        label,
      };
    }

    client(`banner/create`, {
      body: data,
    })
      .then(() => {
        setLoading(false);
        navigate("/homeBanner");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  useEffect(() => {
    Promise.all([
      client(`uni/getAllUniversity?label=${uniLabel}`),
      uni_id && client(`course/allCourses/${uni_id}`),
    ]).then(
      (res) => {
        setAllUni(res[0].data);
        uni_id && setAllCourses(res[1]);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [uni_id, uniLabel]);

  return (
    <>
      <PageLayout title="Create New Banner">
        <form
          onSubmit={submitBannerForm}
          autoComplete="off"
          encType="multipart/form-data"
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
            <TextField
              id="title"
              label="Title"
              name="title"
              value={title}
              onChange={handleChange}
              required
            />

            <div className="flex">
              <FileDialog
                handleChange={(selected) => {
                  setBannerData({
                    ...bannerData,
                    image_url: selected,
                  });
                }}
              />
              <img src={image_url} alt="" className="max-w-[100px]" />
            </div>

            <SelectField
              label="Label"
              id="label"
              name="label"
              value={label}
              onChange={handleChange}
            >
              <option value="">-----Select Label-----</option>
              <option value="UNI_HOME">University Home</option>
              <option value="CBSE_HOME">CBSE Home</option>
              <option value="GOVT_HOME">Govt. Home</option>
              <option value="ENTRANCE_HOME">Entrance Home</option>
            </SelectField>

            <SelectField
              label="Banner Type"
              id="bannerType"
              name="banner_type"
              value={banner_type}
              onChange={handleChange}
            >
              <option value="No Click">No Click</option>
              <option value="URL">URL</option>
              <option value="Inside University">Inside University</option>
              <option value="Inside Course">Inside Course</option>
            </SelectField>

            {banner_type === "URL" && (
              <TextField
                id="urlTitle"
                label="Url Title"
                name="urlTitle"
                value={urlTitle}
                onChange={handleChange}
              />
            )}
            {banner_type === "URL" && (
              <TextField
                label="Url"
                name="url"
                id="url"
                value={url}
                onChange={handleChange}
              />
            )}

            {(banner_type === "Inside University" ||
              banner_type === "Inside Course") && (
              <SelectField
                label="University Label"
                id="uniLabel"
                name="uniLabel"
                onChange={handleChange}
              >
                <option value="UNI">University</option>
                <option value="BOARD">Boards</option>
                <option value="GOVT">Government Exam</option>
                <option value="ENTRANCE">Entrance</option>
              </SelectField>
            )}

            {(banner_type === "Inside University" ||
              banner_type === "Inside Course") && (
              <SelectField
                label="Select University"
                id="uni"
                name="uni_id"
                value={uni_id}
                onChange={handleChange}
              >
                <option value="">-----Select University-----</option>
                {allUni?.map((uni) => (
                  <option key={uni._id} value={uni._id}>
                    {uni.name}
                  </option>
                ))}
              </SelectField>
            )}

            {banner_type === "Inside Course" && (
              <>
                <SelectField
                  label="Select Course"
                  id="course"
                  name="course_id"
                  value={course_id}
                  onChange={handleChange}
                >
                  <option value="">-----Select Course-----</option>
                  {allCourses?.map((course) => (
                    <option key={course._id} value={course._id}>
                      {course.course_name}
                    </option>
                  ))}
                </SelectField>
              </>
            )}
          </div>
          <SubmitButton
            type="submit"
            disabled={loading}
            className="disabled:opacity-25"
          >
            Submit
          </SubmitButton>
        </form>
      </PageLayout>
    </>
  );
};

export default CreateHomeBanner;
