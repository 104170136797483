import { useEffect } from "react";
import client from "utils/api-client";
import { CheckBoxField, SubmitButton, TextField } from "components/Fields";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import FileDialog from "components/FileDialog";
import { toast } from "react-toastify";
import usePathNames from "hooks/usePathnames";

const EditModule = () => {
  const { moduleId, uniId, courseId } = useParams();
  const navigate = useNavigate();
  const [moduleData, setModuleData] = useState();
  const pathname = usePathNames();

  useEffect(() => {
    client(`module/getModule/${moduleId}`).then(
      (data) => {
        setModuleData(data.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [moduleId]);

  function handleEditModule(e) {
    e.preventDefault();

    client(`module/edit`, { body: moduleData }).then(
      (data) => {
        navigate(`/${pathname}/${uniId}/${courseId}`);
      },
      (error) => {
        console.log(error);
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setModuleData({ ...moduleData, [name]: value });
  };

  return (
    <div>
      <h1 className="text-4xl">Edit Module</h1>
      <form onSubmit={handleEditModule}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-6">
          <TextField
            label="Module Name"
            id="Name"
            name="name"
            // defaultValue={data?.name}
            value={moduleData?.name}
            onChange={handleChange}
            required
          />

          <div className="flex">
            <FileDialog
              handleChange={(selected) => {
                setModuleData({ ...moduleData, image: selected });
              }}
            />
            {/* {loading && "loading..."}
            {error && `${error}`} */}
            <img src={moduleData?.image} alt="" className="max-w-[100px]" />
          </div>

          <CheckBoxField
            label="Active On Mobile"
            id="Active"
            name="active"
            checked={moduleData?.active}
            onChange={handleChange}
            // defaultChecked={data?.active}
          />

          <CheckBoxField
            id="showOnWeb"
            label="Active On Web"
            name="showOnWeb"
            checked={moduleData?.showOnWeb}
            onChange={handleChange}
          />
        </div>
        <SubmitButton>Submit</SubmitButton>
      </form>
    </div>
  );
};

export default EditModule;
