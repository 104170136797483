import axios from "axios";
import NotFound from "pages/404";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Routes, Route } from "react-router-dom";
import {
  privateRoutes,
  notPrivateRoutes,
  supportRoutes,
  contentManagerRoutes,
  tel_supportRoutes,
  managerRoutes,
} from "routes/allRoutes";
import PrivateRoute from "routes/PrivateRoute";

const localStorageKey = "token";
const token = JSON.parse(window.localStorage.getItem(localStorageKey));

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/`;
axios.defaults.headers = {
  "x-access-token": token,
};

const ROLES = {
  Admin: "ADMIN",
  Manager: "MANAGER",
  Support: "SUPPORT",
  Content_Manager: "CONTENT_MANAGER",
  Tel_Support: "TEL_SUPPORT",
};

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>EasyPaper Dashboard</title>
      </Helmet>

      <Routes>
        <Route
          element={
            <PrivateRoute
              allowedRoles={[
                ROLES.Admin,
                ROLES.Manager,
                ROLES.Support,
                ROLES.Tel_Support,
              ]}
            />
          }
        >
          {tel_supportRoutes.map((route, idx) => {
            return (
              <Route
                key={idx}
                path={route.path}
                element={route.element}
                exact
              />
            );
          })}
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.Admin, ROLES.Manager, ROLES.Support]}
            />
          }
        >
          {supportRoutes.map((route, idx) => {
            return (
              <Route
                key={idx}
                path={route.path}
                element={route.element}
                exact
              />
            );
          })}
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[ROLES.Admin, ROLES.Manager, ROLES.Content_Manager]}
            />
          }
        >
          {contentManagerRoutes.map((route, idx) => {
            return (
              <Route
                key={idx}
                path={route.path}
                element={route.element}
                exact
              />
            );
          })}
        </Route>

        <Route
          element={<PrivateRoute allowedRoles={[ROLES.Admin, ROLES.Manager]} />}
        >
          {managerRoutes.map((route, idx) => {
            return (
              <Route
                key={idx}
                path={route.path}
                element={route.element}
                exact
              />
            );
          })}
        </Route>

        <Route element={<PrivateRoute allowedRoles={[ROLES.Admin]} />}>
          {privateRoutes.map((route, idx) => {
            return (
              <Route
                key={idx}
                path={route.path}
                element={route.element}
                exact
              />
            );
          })}
        </Route>

        {notPrivateRoutes.map((route, idx) => (
          <Route key={idx} element={route.element} path={route.path} exact />
        ))}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
