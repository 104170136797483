import {
  FormGrid,
  TextField,
  DateTimeField,
  CheckBoxField,
  SubmitButton,
} from "components/Fields";
import PageLayout from "components/PageLayout";
import Spinner from "components/Spinner";
import { format } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import client from "utils/api-client";

const EditTimeBanner = () => {
  let navigate = useNavigate();
  const { timeBannerId } = useParams();
  const [timerData, setTimerData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    client(`timer/getTimerDetails/${timeBannerId}`).then(
      (data) => {
        setTimerData(data?.timerDoc);
      },
      (err) => {
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }, [timeBannerId]);

  const handleChange = (e) => {
    const target = e.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "datetime-local"
        ? new Date(target.value).toISOString()
        : target.value;
    const name = target.name;

    console.log(value);

    setTimerData({ ...timerData, [name]: value });
  };

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);
    client(`timer/edit`, { body: timerData }).then(
      (data) => {
        console.log(data);
        setLoading(false);
        navigate("/timeBanner");
      },
      (err) => {
        setLoading(false);
        console.log(err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  }

  return (
    <PageLayout title="Edit Time Bannner">
      <form onSubmit={handleSubmit}>
        <FormGrid>
          <TextField
            label="Timer Type"
            id="timeType"
            name="timer_type"
            value={timerData?.timer_type}
            onChange={handleChange}
          />
          <TextField
            label="Timer Text"
            id="timerText"
            name="text"
            value={timerData?.text}
            onChange={handleChange}
          />

          <DateTimeField
            label="End Date"
            id="endDate"
            name="end_date"
            value={
              timerData?.end_date &&
              format(new Date(timerData?.end_date), "yyyy-MM-dd'T'hh:mm")
            }
            onChange={handleChange}
          />

          <CheckBoxField
            label="Show"
            id="show"
            name="show"
            checked={timerData?.show}
            onChange={handleChange}
          />

          <CheckBoxField
            id="showOnWeb"
            label="Active On Web"
            name="showOnWeb"
            checked={timerData?.showOnWeb}
            onChange={handleChange}
          />
        </FormGrid>
        <SubmitButton disabled={loading}>
          {loading ? <Spinner /> : "Submit"}
        </SubmitButton>
      </form>
    </PageLayout>
  );
};

export default EditTimeBanner;
