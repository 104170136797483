import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import Loading from "components/dataTable/Loading";
import NoDataFound from "components/dataTable/NoDataFound";
import React from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import client from "utils/api-client";
import { useAsync } from "utils/hooks";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import SearchBar from "components/SearchBar";
import LengthPerPage from "components/dataTable/LengthPerPage";
import NewPaging from "components/dataTable/NewPaging";
import useDebounceValue from "hooks/useDebounceValue";
import PageLayout from "components/PageLayout";
import usePathNames from "hooks/usePathnames";

const ImportSubjects = () => {
  const { uniId, courseId, moduleId } = useParams();
  let navigate = useNavigate();
  const pathname = usePathNames();
  const { data, error, run, isLoading, isError } = useAsync();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [query, setQuery] = useState("");
  const debounceValue = useDebounceValue(query);

  useEffect(() => {
    run(
      client(
        `subject/allSubject?page=${page}&size=${size}&search=${debounceValue}`
      )
    );
  }, [run, courseId, page, size, debounceValue]);

  const [selectedPeople, setSelectedPeople] = useState([]);

  function handleSubmitBanner() {
    client(`subject/import`, {
      body: { courseId: courseId, subjects: selectedPeople },
    })
      .then((res) => {
        console.log(res);
        navigate(
          `/${pathname}/${uniId}/${courseId}/${moduleId}/createContent/subjects`,
          {
            replace: true,
          }
        );

        toast("successfully added subjects");
        setSelectedPeople([]);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPage = (e) => {
    setSize(+e.target.value);
  };

  return (
    <PageLayout
      title="Import Subjects"
      description={`total subjects: ${data?.total}`}
    >
      {isError ? (
        <div className="text-red-400">
          <p>There was an error:</p>
          <pre>{error.message}</pre>
        </div>
      ) : null}
      <SearchBar query={query} onChange={(e) => setQuery(e.target.value)} />
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-xl">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="text-gray-700 text-xs uppercase bg-gray-50 dark:bg-slate-700 dark:text-gray-400 sticky top-0">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    ></th>
                    <th scope="col" className="py-3.5 pr-3 text-left">
                      Icon
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      Edit
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {data?.subjectDoc?.map((subject) => (
                    <tr
                      key={subject._id}
                      className={
                        selectedPeople.includes(subject)
                          ? "bg-white dark:bg-gray-900"
                          : undefined
                      }
                    >
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8 whitespace-nowrap">
                        {selectedPeople.includes(subject) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                          value={subject._id}
                          checked={selectedPeople.includes(subject._id)}
                          onChange={(e) =>
                            setSelectedPeople(
                              e.target.checked
                                ? [...selectedPeople, subject._id]
                                : selectedPeople.filter(
                                    (p) => p !== subject._id
                                  )
                            )
                          }
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <div className="bg-white dark:bg-slate-700 shadow-xl rounded-full w-[50px] h-[50px] overflow-hidden dark:border-slate-700 border-4">
                          <img
                            src={subject.image}
                            alt=""
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        {subject.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <Link
                          to={`/university/${uniId}/${courseId}/${moduleId}/createContent/subjects/${subject._id}`}
                        >
                          <div className="text-blue-600 p-2 bg-blue-200 rounded-full active:brightness-110 hover:brightness-105 w-fit">
                            <PencilAltIcon className="w-5 h-5" />
                          </div>
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <button
                          type="button"
                          // onClick={openModal}
                          className="text-red-600 p-2 bg-red-200 rounded-full active:brightness-110 hover:brightness-105"
                        >
                          <TrashIcon className="w-4 h-4" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Loading loading={isLoading} />
              <NoDataFound length={data?.subjectDoc?.length} />
            </div>
          </div>
        </div>
      </div>
      {selectedPeople.length > 0 && (
        <div className="mt-8 -mx-4 sm:-mx-0 lg:-mx-0">
          <button
            type="button"
            onClick={handleSubmitBanner}
            disabled={selectedPeople.length === 0}
            className="rounded-md bg-blue-600 px-6 py-2 text-white shadow-sm hover:bg-blue-700"
          >
            Add Subjects
          </button>
        </div>
      )}
      <div className="flex flex-col gap-4 md:justify-between md:flex-row">
        <LengthPerPage
          rowsPerpage={data?.subjectDoc?.length}
          usersPerPage={size}
          handleChangeRowsPerPage={handleRowsPerPage}
        />

        <NewPaging
          count={data?.pages}
          currentPage={page}
          HandleChange={handlePageChange}
        />
      </div>
    </PageLayout>
  );
};

export default ImportSubjects;
